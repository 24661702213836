<template>
    <v-data-table
        :headers="headers"
        :items="items"
        item-key="name"
        class="elevation-1 mt-5"
        dense
    >
        <template v-slot:item="{item}">
            <tr style="cursor: pointer;" @click.stop="$emit('openPersonalGoal', item)">
                <td class="tabled">
                    <small style="font-weight: 700; font-size: 0.8rem;">{{ item.goal_title }}</small><br/>
                    <v-chip small label class="px-0 pr-2 pl-2">
                        <small style="font-weight: 700; font-size: 0.8rem;">{{ item.workspace_name ?? "no workspace" }}</small>
                    </v-chip>
                </td>
                <td class="tabled">
                    <small style="font-weight: 700; font-size: 0.8rem;">{{ $date(item.target_date).format('MMM D YYYY') }} {{ $date(item.target_date).fromNow() }}</small>
                </td>
                <td class="tabled">
                    <!-- <small style="font-weight: 700; font-size: 0.8rem;">{{ item.progress ?? "no progress" }}</small><br/> -->
                    <v-chip small label class="px-0 pr-2">
                        <v-icon :style="{ color: item.status?.color || 'black' }">mdi-circle-medium</v-icon>
                        {{ item.status?.name ?? "no status" }}
                    </v-chip>
                </td>
                <td class="tabled">
                    <AuthorProfile
                        :item="item"
                        :users="get_user"
                    />
                </td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>

    /**vuex */
    import { mapGetters } from 'vuex';

    /**component */
    import AuthorProfile from './AuthorProfile.vue';
    
    export default{
        props: {
            headers: Array,
            items: Array
        },
        components: {
            AuthorProfile
        },
        data: () => ({
        }),
        computed: {
            ...mapGetters({
                get_user: 'auth/get_user'
            })
        },
        methods: {
        }
    }
</script>

<style lang="scss" scoped>
.tabled {
    height: 100px !important;
}
</style>