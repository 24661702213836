<template>
    <v-container fluid class="px-0">
        <v-list flat>
            <v-list-item three-line>
                <v-list-item-avatar>
                    <v-icon style="background-color: gainsboro !important; color: black;">mdi-bell</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        <strong style="font-size: 1rem;">Inbox</strong>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <v-tabs
                            v-model="tab"
                            show-arrows
                            class="px-0 mx-0"
                            ref="tabs"
                        >
                            <v-tab
                                v-for="(item, index) in tab_items"
                                :key="index"
                                class="pa-0 mx-0 text-none"
                                dense
                                style="letter-spacing: 0.5px;"
                            >
                                <small style="font-weight: 900; font-size: 0.8rem;">
                                    {{ item.tab }}
                                    <v-badge
                                        v-if="item.tab === 'Activity' && get_unread_tasks > 0"
                                        :content="get_unread_tasks"
                                        color="red"
                                        class="ml-2"
                                        bordered
                                    ></v-badge>
                                </small>
                            </v-tab>
                        </v-tabs>
                        <v-divider/>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <NotificationComponent/>
            </v-tab-item>
            <v-tab-item>
                <v-row class="mt-3">
                    <v-col cols="4">
                        <UserList :users="users" :user_messages="user_messages" @userSelected="selectUser"></UserList>
                    </v-col>
                    <v-col cols="8">
                        <ChatWindow v-if="selected_user" :selected_user="selected_user" :color="color" 
                            :messages="messages" :loading="chat_loading" :chat_refreshing="chat_refreshing"
                            :unread_count="unread_count" @getMessages="refreshMessages" 
                            @pushMessage="pushMessage">
                        </ChatWindow>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import UserList from '../modules/UserList.vue'
import ChatWindow from '../modules/ChatWindow.vue'
import NotificationComponent from '../notification/NotificationComponent.vue'
export default {
    name: 'MessageComponent',
    components: {
        UserList,
        ChatWindow,
        NotificationComponent
    },
    data: () => ({
        loading: false,
        chat_loading: false,
        chat_refreshing: false,
        api_url: null,
        is_company_selected: false,
        users: [],
        user_messages: [],
        selected_user: null,
        color: null,
        messages: [],
        tab: null,
        tab_items: [
            { tab: 'Activity'},
            { tab: 'Messages' },
        ]
    }),
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    async mounted() {
        document.title = "DG Tasks - Inbox";
        this.loading = true
        this.checkSelectedCompany()
        this.getUserMessages()
        this.getUnreadMessages()
        await this.$echo.private('private-message.' + this.get_user.id)
            .listen('MessageSent', () => {
                this.refreshMessages()
            })
    },
    computed: {
        ...mapGetters({
            get_user:               'auth/get_user',
            get_selected_company:   'companies/get_selected_company',
            get_companies:          'companies/get_companies',
            get_unread_tasks:       'tasks/get_unread_tasks'
        }),
    },
    methods: {
        checkSelectedCompany() {
            if (this.get_selected_company.id) {
                this.is_company_selected = true
            }
        },
        async getUserMessages() {
            await this.$axios.get('api/users/get_user_messages')
                .then(({ data }) => {
                    if (data.response) {
                        this.users = data.users
                        this.user_messages = data.user_messages
                        this.loading = false
                    }
                })
        },
        selectUser(user, color) {
            this.selected_user = user
            this.color = color
            this.chat_loading = true
            this.unread_count = user.unread_count
            user.unread_count = 0
            this.getMessages()
        },
        refreshMessages() {
            this.getMessages()
            this.getUserMessages()
        },
        async getMessages() {
            if (this.selected_user == null)
                return
            this.chat_refreshing = true
            let payload = {
                sender_id: this.get_user.id,
                receiver_id: this.selected_user.id,
            }
            this.$axios.get('api/messages/get_messages_between', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.messages = data.data
                        this.$store.dispatch('messages/set_unread_count', data.unread.length)
                        this.chat_loading = false
                        this.chat_refreshing = false
                    }
                })
        },
        async getUnreadMessages() {
            let payload = {
                receiver_id: this.get_user.id,
                is_read: false,
            }
            this.$axios.get('api/messages/get_messages', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('messages/set_unread_count', data.data.length)
                    }
                })
        },
        pushMessage(message) {
            this.messages.push(message)
            this.unread_count = 0
        },
    },
}
</script>

<style lang="scss" scoped>
.v-tabs {
    width: 100% !important;
}
</style>