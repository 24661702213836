// import apiClient from "@/api/apiClient"

/**store */
import store from "@/plugins/vuex"

export default {
    async assignTaskDetails(payload){
        try{
            await store.commit('userActivity/set_details', payload);
        }catch(error){
            console.log('error placing task of a user - service layer', error)
        }
    }
}