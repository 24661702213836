<template>
    <v-container fluid>
        <v-row>
            <v-col
                cols="12"
                style="height: 95vh; overflow-y: auto;"
            >
                <v-card 
                    v-for="(item, index) in get_notification" 
                    :key="item.id || index" 
                    elevation="0" 
                    outlined 
                    class="mb-4 pa-3"
                    @click.stop="openTaskDetails(item.task); markAsReadFn(item.id); parentTask(item);"
                    :style="{ 
                        border: item.is_read === 1 ? '1px solid grey' : '2px solid blue', 
                        borderRadius: '8px' 
                    }"
                >
                    <v-card-title>
                        <v-icon 
                            small 
                            class="mr-2"
                            :style="{color: item.is_read == 1 ? 'grey' : 'blue'}"
                        >mdi-circle-medium</v-icon>
                        <small>{{ item.task?.company?.name ?? "no company" }}</small>
                        <v-spacer/>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col
                                cols="6"
                            >
                                <label
                                    style="font-weight: 700;"
                                >
                                    <v-icon small>mdi-check</v-icon>
                                    {{ item.task?.name ?? "no task" }}
                                </label>
                            </v-col>
                            <v-col
                                cols="6"
                                class="text-right"
                            >
                                <small>{{ $date(item.created_at).format('MMMM DD, YYYY') }}</small>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text>
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-avatar color="primary" size="56" class="white--text">
                                    {{ item.sender?.name[0] ?? "No name" }}
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-row>
                                    <v-col
                                        cols="6"
                                    >
                                        <v-list-item-title>
                                            {{ item.sender?.name ?? "no name" }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle style="white-space: normal; word-wrap: break-word; overflow-wrap: break-word;">
                                            <label>{{ item.content || "no content" }}</label><br/>
                                            <small
                                                style="font-size: 14px; color: grey; font-weight: 400;"
                                            >
                                                {{ $date(item.created_at).fromNow() }}
                                            </small>
                                        </v-list-item-subtitle>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-text>
                </v-card>
            </v-col>
            <TaskNavigationComponent 
                :task_details_dialog="task_details_dialog"
                :can_close_task_details_dialog="can_close_task_details_dialog" 
                :users="get_users"
                @updateTask="updateTask"
                @closeTaskDetailsDialog="closeTaskDetailsDialog"
            />
        </v-row>
    </v-container>
</template>

<script>

    /**vuex getters */
    import { mapGetters } from 'vuex'

    /**service */
    import dashboardService from '@/service/dashboard.service';

    /**component */
    import TaskNavigationComponent from '../modules/TaskNavigationComponent.vue';

    export default {
        name: "NotificationComponent",
        components: {
            TaskNavigationComponent
        },
        data: () => ({
            task_details_dialog: false,
            can_close_task_details_dialog: false,
            original_selected_task: {},
        }),
        created(){

        },
        mountes(){

        },
        computed: {
            ...mapGetters({
                get_notification:   'companies/get_notification',
                get_users:          'tasks/get_users',
                get_selected_task:  'tasks/get_selected_task',
            })
        },
        methods:{
            openTaskDetails(item){
                dashboardService.task(item)
                this.task_details_dialog = true
                this.can_close_task_details_dialog = true
            },
            parentTask(parent){
                dashboardService.parentTaskData(parent)
            },
            async updateTask(item){
                let payload = {
                    company_id: item.company_id,
                    assignee_id: item.assignee_id,
                    project_id: item.project_id,
                    name: item.name,
                    status_id: item.status_id,
                    description: item.description,
                    priority_id: item.priority_id,
                    due_date: item.due_date,
                    allocated_time: item.allocated_time,
                }
                await this.$axios.post(`api/tasks/update_task/${item.id}`, payload)
                    .then(({ data }) => {
                        if (data.response) {
                            this.$toast.success(data.message)
                        }
                        else {
                            this.$toast.error(data.message)
                        }
                    })
            },
            closeTaskDetailsDialog(){
                dashboardService.task({})
                this.can_close_task_details_dialog = false
                this.task_details_dialog = false
                let selected_task = {
                    name: this.get_selected_task.name,
                    company_id: this.get_selected_task.company_id,
                    project_id: this.get_selected_task.project_id,
                    priority_id: this.get_selected_task.priority_id,
                    status_id: this.get_selected_task.status_id,
                    due_date: this.get_selected_task.due_date,
                    // allocated_time: this.get_selected_task.allocated_time,
                }
                let original_task = {
                    name: this.original_selected_task.name,
                    company_id: this.original_selected_task.company_id,
                    project_id: this.original_selected_task.project_id,
                    priority_id: this.original_selected_task.priority_id,
                    status_id: this.original_selected_task.status_id,
                    due_date: this.original_selected_task.due_date,
                    // allocated_time: this.original_selected_task.allocated_time,
                }
                if (JSON.stringify(selected_task) != JSON.stringify(original_task)) {
                    this.updateTask(this.get_selected_task)
                }
                this.$store.dispatch('tasks/set_selected_task', {})
                this.original_selected_task = {}
            },
            async markAsReadFn(record_id){
                try{
                    await dashboardService.markAsRead(record_id)
                }catch(error){
                    console.log("error on marking notification item as read - component", error)
                }
            },
        }
    }
</script>