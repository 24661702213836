export default {
    namespaced: true,
    state: {
        goal_privacy: [],
        personal_goals: [],
        view_personal_goal: {},
        view_personal_goal_boolean: false,
        goal_statuses_open: [],
        goal_statuses_close: [],
        view_goal_status_log: {},
        grouped_goals: []
    },
    mutations: {
        setGoalPrivacy(state, payload){
            state.goal_privacy = [...payload]
        },
        setPersonalGoals(state, payload){
            state.personal_goals = [...payload]
        },
        setViewPersonalGoal(state, payload){
            state.view_personal_goal = payload
        },
        setViewPersonalGoalBoolean(state, payload){
            state.view_personal_goal_boolean = payload
        },
        setGoalStatusesOpen(state, payload){
            state.goal_statuses_open = [...payload]
        },
        setGoalStatusesClose(state, payload){
            state.goal_statuses_close = [...payload]
        },
        setViewGoalStatusLog(state, payload){
            state.view_goal_status_log = payload
        },
        setGroupedGoals(state, payload){
            state.grouped_goals = payload
        }
    },
    getters: {
        getGoalPrivacy: state => state.goal_privacy,
        getPersonalGoals: state => state.personal_goals,
        getViewPersonalGoal: state => state.view_personal_goal,
        getViewPersonalGoalBoolean: state => state.view_personal_goal_boolean,
        getGoalStatusesOpen: state => state.goal_statuses_open,
        getGoalStatusesClose: state => state.goal_statuses_close,
        getViewGoalStatusLog: state => state.view_goal_status_log,
        getGroupedGoals: state => state.grouped_goals
    },
    actions: {
        setGoalPrivacy({commit}, payload){
            commit('setGoalPrivacy', payload)
        },
        setPersonalGoals({commit}, payload){
            commit('setPersonalGoals', payload)
        },
        setViewPersonalGoal({commit}, payload){
            commit("setViewPersonalGoal", payload)
        },
        setViewPersonalGoalBoolean({commit}, payload){
            commit("setViewPersonalGoalBoolean", payload)
        },
        setGoalStatusesOpen({commit}, payload){
            commit("setGoalStatusesOpen", payload)
        },
        setGoalStatusesClose({commit}, payload){
            commit("setGoalStatusesClose", payload)
        },
        setViewGoalStatusLog({commit}, payload){
            commit("setViewGoalStatusLog", payload)
        },
        setGroupedGoals({commit}, payload){
            commit("setGroupedGoals", payload)
        }
    }
}