<template>
    <v-container fluid class="d-flex justify-center">
        <v-card
            :style="{ width: cardWidth }"
            elevation="0"
            class="mt-10"
            v-if="!requestLeaveState"
        >
            <v-card-title>
                <span style="font-size: 1.5rem; font-weight: 600;">Leave Management</span>
                <v-spacer></v-spacer>
                <v-btn outlined class="text-none" @click.stop="requestALeave" :disabled="(leavesCredits.length > 0 ? false : true) || (leavesCredits.leave_count === leavesCredits.used_leaves)">
                    <span style="font-weight: 500; font-size: 0.8rem;">Request a leave</span>
                </v-btn>
            </v-card-title>
            <v-card-subtitle>
                <span style="font-size: 0.8rem; font-weight: 500;">Request and track your time off</span>
            </v-card-subtitle>
            <v-divider/>
            <v-card-text>
                <v-row>
                    <v-col
                        cols="12"
                        v-for="(leave, leaveIndex) in leavesCredits"
                        :key="leaveIndex"
                    >
                        <v-card elevation="0" outlined>
                            <v-card-title>
                                <v-icon>
                                    {{ leave.icon }}
                                </v-icon>
                                <span class="ml-2" style="font-size: 1rem; font-weight: 600;">{{ leave.leave_type?.name ?? "no name" }}</span>
                                <v-spacer></v-spacer>
                                <v-chip
                                    class="pa-0 pl-2 pr-2"
                                    outlined
                                    small
                                >
                                    <span style="font-weight: 700; font-size: 0.8rem;">{{ leave.used_leaves }} / {{ leave.leave_count }} Used</span>
                                </v-chip>
                            </v-card-title>
                            <v-card-subtitle>
                                <v-progress-linear
                                    color="blue"
                                    rounded
                                    :value="(leave.used_leaves / leave.leave_count) * 100"
                                    height="12"
                                    class="mt-2"
                                ></v-progress-linear>
                                <span style="font-weight: 600; font-size: 0.8rem;">{{ leave.leave_count - leave.used_leaves }} {{ leave.leave_count - leave.used_leaves > 0 ? 'days' : 'day' }} remaining</span>
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
                <v-row>
                    <v-col
                        md="6"
                        lg="6"
                        sm="12"
                    >
                        <v-card elevation="0">
                            <v-card-title>
                                <span style="font-weight: 600; font-size: 1rem;">Upcoming Time Off</span>
                                <v-spacer></v-spacer>
                                <v-chip
                                    small
                                    class="pa-0 pl-2 pr-2"
                                    outlined
                                >
                                    <span style="font-weight: 600; font-size: 0.8rem;">Scheduled</span>
                                </v-chip>
                            </v-card-title>
                            <v-card-text>
                                <v-card 
                                    outlined 
                                    rounded
                                    v-for="(upcoming, upcomingIndex) in getLeaveRequestsOnly"
                                    :key="upcomingIndex"
                                >
                                    <v-card-title>
                                        <span style="font-weight: 600; font-size: .9rem;">{{ upcoming.leave_type?.name || "no leave type" }}</span>
                                        <v-spacer></v-spacer>
                                        <v-chip
                                            class="pa-0 pr-2 pl-2"
                                            outlined
                                            small
                                        >
                                            <span style="font-weight: 600; font-size: .8rem;">{{ upcoming.status?.name || "no status type" }}</span>
                                        </v-chip>
                                    </v-card-title>
                                    <v-card-subtitle>
                                        <span style="font-weight: 500; font-size: .8rem;">{{ $date(upcoming.from).format('MMM D YYYY') }} - {{ $date(upcoming.to).format('MMM D YYYY') }}</span>
                                    </v-card-subtitle>
                                    <v-card-text>
                                        Days: <span style="font-weight: 800;">{{ upcoming.count }}</span>
                                    </v-card-text>
                                </v-card>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col
                        md="6"
                        lg="6"
                        sm="12"
                    >
                        <v-card elevation="0">
                            <v-card-title>
                                <span style="font-weight: 600; font-size: 1rem;">Reacent History</span>
                                <v-spacer></v-spacer>
                                <v-chip
                                    small
                                    class="pa-0 pl-2 pr-2"
                                    outlined
                                >
                                    <span style="font-weight: 600; font-size: 0.8rem;">Past 6 months</span>
                                </v-chip>
                            </v-card-title>
                            <v-card-text>
                                <v-card elevation="0">
                                    <v-list
                                        v-for="(history, historyIndex) in getHistoryRequest"
                                        :key="historyIndex"
                                        class="mb-2"
                                        two-line
                                    >
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <span style="font-weight: 600; font-size: .8rem;">{{ history.leave_type?.name ?? "no leave type" }}</span>
                                                    <spacer/>
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <span style="font-weight: 400; font-size: .8rem;">{{ $date(history.from).format('MMM D YYYY') }} - {{ $date(history.to).format('MMM D YYYY') }}</span>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-row>
                                                    <span style="font-weight: 600; font-size: .8rem;">{{ history.count }} {{ history.count > 1 ? 'days' : 'day' }}</span>
                                                    <v-chip
                                                        small
                                                        class="pa-0 pr-2 pl-2 ml-2"
                                                        outlined
                                                    >
                                                        Used
                                                    </v-chip>
                                                </v-row>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-divider/>
                                    </v-list>
                                </v-card>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card
            v-else
            :style="{ width: cardWidth }"
            elevation="0"
            class="mt-10"
        >
            <v-card-title>
                <span style="font-size: 1.5rem; font-weight: 600;">Leave Management</span>
                <v-spacer></v-spacer>
                <v-btn outlined class="text-none" @click.stop="cancelLeaveRequest">
                    <span style="font-weight: 500; font-size: 0.8rem;">Back</span>
                </v-btn>
            </v-card-title>
            <v-card-subtitle>
                <span style="font-size: 0.8rem; font-weight: 500;">Request and track your time off</span>
            </v-card-subtitle>
            <v-divider/>
            <v-card-text>
                <v-row no-gutters>
                    <v-col
                        cols="12"
                    >
                        <v-combobox
                            v-model="leave_request.leave_type_id"
                            :items="leaveType"
                            item-id="id"
                            item-text="name"
                            label="Request Type"
                            outlined
                            dense
                        >
                        </v-combobox>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <v-combobox
                            v-model="leave_request.approver_id"
                            :items="getApprovers"
                            item-id="id"
                            item-text="name"
                            label="Approver"
                            outlined
                            dense
                        >
                        </v-combobox>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <!-- <v-text-field
                            outlined
                            dense
                            label="How long"
                            type="number"
                            v-model="leave_request.count"
                        >
                        </v-text-field> -->
                        <!-- <v-date-picker
                            v-model="leave_request.dates"
                            range
                        ></v-date-picker> -->
                        <v-menu
                            v-model="dateRange"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="formattedDates"
                                label="Select your desired dates"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                dense
                            ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="leave_request.dates"
                                range
                                @input="menu2 = false"
                                :min="$date().format('YYYY-MM-DD')" 
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed outlined class="pl-5 pr-5 text-none" @click.stop="requestALeaveFn">Send</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
/**vuex */
import { mapGetters } from "vuex"

/**service */
import leaveService from "@/service/leave.service";

/**dayjs */
import dayjs from "dayjs";

export default {
  data() {
    return {
      cardWidth: "50%",
      requestLeaveState: false,
      leave_request: {
        leave_type_id:  null,
        approver_id:    null,
        count:          null,
        dates:          [],
        from:           null,
        to:             null
      },
      dateRange: false,
    };
  },
  async mounted() {
    await leaveService.getLeaves()
    await leaveService.getLeaveCredits()
    await leaveService.getLeaveTypes()
    this.updateCardWidth();
    window.addEventListener("resize", this.updateCardWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateCardWidth);
  },
  computed:{
    ...mapGetters({
        user:               'auth/get_user',
        leaves:             'leave/getLeaves',
        leavesCredits:      'leave/getLeaveCredits',
        approvers:          'tasks/get_users',
        leaveType:          'leave/getLeaveTypes'
    }),
    getApprovers() {
        const approvers = this.approvers.filter(q => [1, 2].includes(q.role_id));
        return approvers.filter(q => q.id !== this.user.id)
    },
    getLeaveRequestsOnly(){
        return this.leaves.filter(q => [1, 2, 3].includes(q.leave_status_id));
    },
    getHistoryRequest(){
        return this.leaves.filter(q => [2, 3].includes(q.leave_status_id)).slice(0, 3);
    },
    formattedDates() {
      if (this.leave_request.dates.length === 2) {
        return `${this.leave_request.dates[0]} - ${this.leave_request.dates[1]}`;
      }
      return "";
    }
  },
  watch: {
    "leave_request.dates": {
      handler(newDates) {
        if (newDates.length === 2) {
            const startDate = dayjs(newDates[0]).format("YYYY-MM-DD");
            const endDate = dayjs(newDates[1]).format("YYYY-MM-DD");

            this.leave_request.from = startDate;
            this.leave_request.to = endDate;
            this.leave_request.count = dayjs(endDate).diff(dayjs(startDate), "day") + 1; // Including the last day
        } else {
            this.leave_request.from = null;
            this.leave_request.to = null;
            this.leave_request.count = null;
        }
      },
      deep: true
    }
  },
  methods: {
    updateCardWidth() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 600) {
          this.cardWidth = "90%";
      } else if (screenWidth < 960) {
          this.cardWidth = "70%";
      } else if (screenWidth < 1264) {
          this.cardWidth = "60%";
      } else {
          this.cardWidth = "50%";
      }
    },
    requestALeave(){
      this.requestLeaveState = true;
    },
    cancelLeaveRequest(){
      this.requestLeaveState = false;
      this.leave_request = { leave_type_id: null, approver_id: null, count: null, dates: [] };
    },
    async requestALeaveFn(){
      if (!this.leave_request.leave_type_id || !this.leave_request.approver_id || !this.leave_request.count) {
        this.$toast.error("Please fill out the form");
        return;
      }
      try {
        const tp = {
          leave_type_id:    this.leave_request.leave_type_id.id,
          approver_id:      this.leave_request.approver_id.id,
          count:            this.leave_request.count,
          from:             this.leave_request.from,
          to:               this.leave_request.to
        };
        const response = await leaveService.requestALeave(tp);
        const { data } = response;
        if (data) {
          this.$toast.success("Requesting a leave, successful!");
          await leaveService.getLeaves()
          this.cancelLeaveRequest();
        }
      } catch (error) {
        this.$toast.error("An error occurred while requesting leave.");
      }
    }
  }
};
</script>