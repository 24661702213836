export default {
    namespaced: true,
    state: {
        leaves: [],
        leave_credits: [],
        leave_status: [],
        leave_types: [],
        to_approve_leave_request: []
    },
    mutations: {
        SET_LEAVES(state, payload){
            state.leaves = [...payload]
        },
        SET_LEAVE_CREDITS(state, payload){
            state.leave_credits = [...payload]
        },
        SET_LEAVE_STATUS(state, payload){
            state.leave_status = [...payload]
        },
        SET_LEAVE_TYPES(state, payload){
            state.leave_types = [...payload]
        },
        SET_TO_APPROVE_LEAVE_REQUEST(state, payload){
            state.to_approve_leave_request = [...payload]
        }
    },
    getters: {
        getLeaves: state => state.leaves,
        getLeaveCredits: state => state.leave_credits,
        getLeaveStatus: state => state.leave_status,
        getLeaveTypes: state => state.leave_types,
        getToApproveLeaveRequest: state => state.to_approve_leave_request
    },
    actions: {
        setLeaves({commit}, payload){
            commit('SET_LEAVES', payload)
        },
        setLeaveStatus({commit}, payload){
            commit('SET_LEAVE_STATUS', payload)
        },
        setLeaveTypes({commit}, payload){
            commit('SET_LEAVE_TYPES', payload)
        },
        setLeaveCredits({commit}, payload){
            commit('SET_LEAVE_CREDITS', payload)
        },
        setToApproveLeaveRequest({commit}, payload){
            commit('SET_TO_APPROVE_LEAVE_REQUEST', payload)
        }
    }
}