<template>
    <v-container fluid class="d-flex justify-center">
        <v-card
            :style="{ width: cardWidth }"
            elevation="0"
            class="mt-10"
        >
            <v-card-title>
                <span style="font-size: 1.5rem; font-weight: 600;">Leave Approval</span>
            </v-card-title>
            <v-card-subtitle>
                <span style="font-size: 0.8rem; font-weight: 500;">Approve or reject employee's leave requests</span>
            </v-card-subtitle>
            <v-divider/>
            <v-card-text>
                <v-card elevation="0">
                    <v-card-title>
                        <span style="font-weight: 600; font-size: 1rem;">Leave Requests</span>
                        <v-spacer></v-spacer>
                        <v-chip
                            small
                            class="pa-0 pl-2 pr-2"
                            outlined
                        >
                            <span style="font-weight: 600; font-size: 0.8rem;">Requested</span>
                        </v-chip>
                    </v-card-title>
                    <v-card-text>
                        <v-card 
                            outlined 
                            rounded
                            v-for="(upcoming, upcomingIndex) in leaveRequests"
                            :key="upcomingIndex"
                        >
                            <v-card-title>
                                <span style="font-weight: 600; font-size: .9rem;">Type: {{ upcoming.leave_type?.name || "no leave type" }}</span>
                                <v-spacer></v-spacer>
                                <v-menu
                                    open-on-hover
                                    offset-y
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip
                                            v-bind="attrs"
                                            v-on="on"
                                            small
                                            label
                                            style="font-weight: 700; font-size: 0.7rem;"
                                        >
                                            {{ upcoming.status.name }}
                                        </v-chip>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(item, index) in leaveStatus"
                                            :key="index"
                                        >
                                            <v-list-item-title style="cursor: pointer;" @click.stop="updateStatus(item, upcoming)">
                                                <span style="font-weight: 700; font-size: 0.8rem;">{{ item.name }}</span>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                    </v-menu>
                            </v-card-title>
                            <v-card-subtitle>
                                <span style="font-weight: 500; font-size: .9rem;">Requested by: {{ upcoming.user?.name }}</span>
                                <span style="font-weight: 500; font-size: .8rem; display: block;">{{ $date(upcoming.from).format('MMM D YYYY') }} - {{ $date(upcoming.to).format('MMM D YYYY') }}</span>
                            </v-card-subtitle>
                            <v-card-text>
                                Days: <span style="font-weight: 800;">{{ upcoming.count }}</span>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

/**vuex */
import { mapGetters } from "vuex"

/**service */
import leaveService from "@/service/leave.service";

export default{
    data(){
        return {
            cardWidth: "50%",
        }
    },
    async mounted(){
        this.updateCardWidth();
        await leaveService.getLeaveStatus()
    },
    computed: {
        ...mapGetters({
            toApproveRequest:       'leave/getToApproveLeaveRequest',
            leaveStatus:            'leave/getLeaveStatus'
        }),
        leaveRequests(){
            return this.toApproveRequest.filter(q => [1].includes(q.leave_status_id))
        }
    },
    methods: {
        updateCardWidth() {
            const screenWidth = window.innerWidth;
            if (screenWidth < 600) {
                this.cardWidth = "90%";
            } else if (screenWidth < 960) {
                this.cardWidth = "70%";
            } else if (screenWidth < 1264) {
                this.cardWidth = "60%";
            } else {
                this.cardWidth = "50%";
            }
        },
        async updateStatus(btn, item){
            try{
                const tp = {
                    leave_status_id:        btn.id,
                    is_updated:             1,
                    user_id:                item.user?.id,
                    count:                  item.count
                }
                await leaveService.updateRequest(item.id, tp)
                await leaveService.getToApproveLeaveRequest()
            }catch(error){
                console.log('error on updating request status - component level', error)
            }
        }
    }
}
</script>