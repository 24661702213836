import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import AuthState from '../components/login/states/auth.js'
import CompaniesState from '../components/dashboard/states/companies.js'
import TasksState from '../components/dashboard/states/tasks.js'
import MeetingsState from '../components/meetings/states/meetings.js'
import ProjectsState from '../components/projects/states/projects.js'
import MessagesState from '../components/messages/states/messages.js'
import UserActivity from "@/components/user_activities/states"
import UsersState from "@/components/users/states"
import DocumentState from "@/components/documentsop/state"
import GoalState from "@/components/goals/state"
import TimeTrackState from "@/components/timetrack/state"
import LeaveStore from "@/components/profile/states"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth: AuthState,
        companies: CompaniesState,
        tasks: TasksState,
        meetings: MeetingsState,
        projects: ProjectsState,
        messages: MessagesState,
        userActivity: UserActivity,
        users: UsersState,
        document: DocumentState,
        goal: GoalState,
        timetrack: TimeTrackState,
        leave: LeaveStore
    },
    plugins: [
        createPersistedState({
          paths: ['auth', 'companies', 'goal', 'tasks'],
        }),
    ],
});