/**api */
import apiClient from "@/api/apiClient";

/**store */
import store from "@/plugins/vuex"

export default {
    async getStatus(){
        try{
            const response = await apiClient.get('/api/misc/get_status')
            const { data } = response
            store.commit('tasks/set_statuses', data)
        }catch(error){
            console.log('error on fetching status - service layer', error)
        }
    },

    async getPriorities(){
        try{
            const response = await apiClient.get('/api/misc/get_prority')
            const { data } = response
            store.commit('tasks/set_priorities', data)
        }catch(error){
            console.log('error on fetching priorities - service layer', error)
        }
    },

    /**
     * add description to a task
     * @params
     * data?: object
     * - record_id?: int
     * - description>L string
     * 
     * @returns
     * mixed
     */
    async addDescription(data){
        try{
            await apiClient.post('/api/misc/add_description', data)
            return true;
        }catch(error){
            console.log("error on adding description - service layer", error)
        }
    }
}