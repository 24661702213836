<template>
    <v-container fluid class="px-0">
        <div v-show="!getViewPersonalGoalBoolean">
            <v-list flat>
                <v-list-item three-line>
                    <v-list-item-avatar>
                        <v-icon style="background-color: gainsboro !important; color: black;">mdi-target</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            <strong style="font-size: 1rem;">My Workspace</strong>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <v-tabs
                                v-model="tab"
                                show-arrows
                                class="mr-2 px-0"
                            >
                                <v-tab
                                    v-for="(item, index) in tabs"
                                    :key="item.id || index"
                                    class="text-none mr-2"
                                    style="letter-spacing: 0.50px;"
                                >
                                    <small style="font-weight: 900; font-size: 0.8rem;">{{ item.tab }}</small>
                                </v-tab>
                            </v-tabs>
                            <v-divider/>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-tabs-items
                v-model="tab"
            >
                <v-tab-item>
                    <!-- company goals -->
                    <v-container fluid class="pt-0">
                        <v-btn
                            color="primary"
                            dark
                            small
                            class="text-none"
                            @click.stop="addPersonalGoals(1)"
                        >
                            <v-icon small class="mr-1">mdi-plus</v-icon>
                            Create goal
                        </v-btn>
                    </v-container>
                    <v-divider/>
                    <v-text-field
                        v-model="searchQuery"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        :style="cardWidth"
                        class="mx-auto d-block"
                        outlined
                        dense
                    ></v-text-field>
                    <v-container fluid class="pt-0">
                        <PersonalGoalTable
                            :headers="my_goal_header"
                            :items="compangGoals"
                            @openPersonalGoal="openPersonalGoal"
                        />
                    </v-container>
                </v-tab-item>
                <v-tab-item>
                    <!-- team goals -->
                    <v-container fluid class="pt-0">
                        <v-btn
                            color="primary"
                            dark
                            small
                            class="text-none"
                            @click.stop="addPersonalGoals(1)"
                        >
                            <v-icon small class="mr-1">mdi-plus</v-icon>
                            Create goal
                        </v-btn>
                    </v-container>
                    <v-divider/>
                    <div class="d-flex justify-center align-center">
                        <div class="text-left mt-10" style="width: 50vw;">
                            <small class="mb-5" style="font-weight: 700; font-size: 1.5rem;">Goals</small>
                            <v-text-field
                                v-model="searchQuery"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                outlined
                                dense
                            ></v-text-field>
                            <TeamGoalProgress
                                :getPersonalGoals="allGoals"
                                @openPersonalGoal="openPersonalGoal"
                            />
                        </div>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <!-- my goals -->
                    <v-container fluid class="pt-0">
                        <v-btn
                            color="primary"
                            dark
                            small
                            class="text-none"
                            @click.stop="addPersonalGoals(2)"
                        >
                            <v-icon small class="mr-1">mdi-plus</v-icon>
                            Create goal
                        </v-btn>
                    </v-container>
                    <v-divider/>
                    <v-text-field
                        v-model="searchQuery"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        :style="cardWidth"
                        class="mx-auto d-block"
                        outlined
                        dense
                    ></v-text-field>
                    <v-container fluid class="pt-0">
                        <PersonalGoalTable
                            :headers="my_goal_header"
                            :items="personalGoals"
                            @openPersonalGoal="openPersonalGoal"
                        />
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <div
            v-if="getViewPersonalGoalBoolean"
        >
            <ViewPersonalGoal
                :getViewPersonalGoal="getViewPersonalGoal"
                :getGoalStatusesOpen="getGoalStatusesOpen"
                :getGoalStatusesClose="getGoalStatusesClose"
            />
            <v-divider/>
            <PersonalGoalCard
                :getViewPersonalGoal="getViewPersonalGoal"
                :getGoalStatusesOpen="getGoalStatusesOpen"
                :getGoalStatusesClose="getGoalStatusesClose"
                :get_user="get_user"
            />
        </div>

        <!-- create personal goal -->
        <v-row
            justify="center"
        >
            <v-dialog
                v-model="personalGoalModal"
                persistent
                :max-width="goalof == 1 ? '40vw' : '25vw'"
            >
                <v-card>
                    <v-card-title>
                        <small style="font-weight: 700; font-size: 1rem;" v-if="goalof == 1">
                            Add company goal
                        </small>
                        <small style="font-weight: 700; font-size: 1rem;" v-if="goalof == 2">
                            Create a new goal
                        </small>
                        <v-spacer/>
                        <v-icon
                            @click="closeAddPersonalGoalModal"
                        >mdi-close</v-icon>
                    </v-card-title>
                    <v-divider/>
                    <v-card-text
                        class="mt-5"
                    >
                        <v-row>
                            <v-col
                                :cols="goalof == 1 ? '4' : '0'"
                            >
                                <v-img
                                    src="https://img.freepik.com/free-vector/staff-management-perspective-definition-target-orientation-teamwork-organization-business-coach-company-executive-personnel-cartoon-characters_335657-2967.jpg?t=st=1742191726~exp=1742195326~hmac=801ae86efe9e787bd528d5d58275e90dfd88ed22998fe868d42ae9de8dc47863&w=740"
                                    height="100%"
                                    cover
                                    v-if="goalof == 1"
                                />
                            </v-col>
                            <v-col
                                :cols="goalof == 1 ? '8' : '12'"
                            >
                            <small style="font-weight: 500;">Goal Title *</small>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="c.goal_title"
                                ></v-text-field>

                                <small style="font-weight: 500;">Goal owner</small>
                                <v-select
                                    v-model="c.goal_owner"
                                    :items="get_users"
                                    item-text="name"
                                    item-value="id"
                                    dense
                                    outlined
                                >
                                    <template v-slot:selection="{ item }">
                                        <span style="font-size: 12px;">
                                            <v-avatar color="accent" size="24" class="mr-2">
                                                {{ item.name[0] }}
                                            </v-avatar>
                                            {{ item.name }}
                                        </span>
                                    </template>

                                    <template v-slot:item="{ item, on, attrs }">
                                        <v-list-item
                                            v-bind="attrs"
                                            v-on="on"
                                            two-lines
                                        >
                                            <v-list-item-avatar>
                                                <v-avatar color="accent" size="24" class="mr-2">
                                                    <small>{{ item.name[0] }}</small>
                                                </v-avatar>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>

                                </v-select>
                                
                                <small style="font-weight: 500;">Members *</small>
                                <v-select
                                    :items="get_users"
                                    item-text="name"
                                    item-value="id"
                                    dense
                                    outlined
                                    multiple
                                    v-model="c.members"
                                    chips
                                >
                                    <template v-slot:item="{ item, on, attrs }">
                                        <v-list-item
                                            v-bind="attrs"
                                            v-on="on"
                                            two-lines
                                        >
                                            <v-list-item-avatar>
                                                <v-avatar color="accent" size="24" class="mr-2">
                                                    <small>{{ item.name[0] }}</small>
                                                </v-avatar>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>

                                    <template v-slot:selection="{ item }">
                                        <v-chip small class="ma-1">
                                            <v-avatar left>
                                                <v-avatar color="accent" size="24" class="mr-1">
                                                    <small>{{ item.name[0] }}</small>
                                                </v-avatar>
                                            </v-avatar>
                                            {{ item.name }}
                                        </v-chip>
                                    </template>
                                </v-select>

                                <small style="font-weight: 500;">Privacy</small>
                                <v-select
                                    :items="getGoalPrivacy"
                                    item-text="name"
                                    item-value="id"
                                    dense
                                    outlined
                                    v-model="c.goal_privacy_id"
                                >
                                    <template v-slot:item="{ item, on, attrs }">
                                        <v-list-item
                                            v-bind="attrs"
                                            v-on="on"
                                            two-lines
                                        >
                                            <v-list-item-avatar>
                                                <v-icon>{{ item.icon }}</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.sub_content }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:selection="{ item }">
                                        <v-list-item two-lines>
                                            <v-list-item-avatar>
                                                <v-icon>{{ item.icon }}</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>
                                <small style="font-weight: 400;"><v-icon small class="mr-1">mdi-lightbulb-on</v-icon>DG Tip: You can edit these details and progress settings after creating this goal</small>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider/>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="no-text" @click="closeAddPersonalGoalModal" outlined small>Cancel</v-btn>
                        <v-btn 
                            class="no-text" 
                            @click="addPersonalGoal" 
                            :outlined="!isComplete"
                            :disabled="!isComplete"
                            :color="!isComplete ? undefined : 'primary'"
                            small
                        >Save goal</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <!-- view status logs -->
        <ViewStatusLogs
            :getViewPersonalGoal="getViewPersonalGoal"
            :view_status_logs_model="view_status_logs_model"
            :getGoalStatusesOpen="getGoalStatusesOpen"
            :getGoalStatusesClose="getGoalStatusesClose"
            @update:view_status_logs_model="view_status_logs_model = $event"
        />
    </v-container>
</template>

<script>

    /**vuex */
    import { mapGetters } from 'vuex';

    /**service */
    import goalService from '@/service/goal.service';
    import userService from '@/service/user.service';

    /**component */
    import PersonalGoalTable from './component/PersonalGoalTable.vue';
    import PersonalGoalCard from './component/PersonalGoalCard.vue';
    import ViewStatusLogs from './component/ViewStatusLogs.vue'
    import ViewPersonalGoal from './component/ViewPersonalGoal.vue';
    import TeamGoalProgress from "./component/TeamGoalProgress.vue"

    export default{
        name: "GoalsComponent",
        components: {
            PersonalGoalTable,
            PersonalGoalCard,
            ViewStatusLogs,
            ViewPersonalGoal,
            TeamGoalProgress
        },
        data: () => ({
            tab: 0,
            tabs: [
                { tab: 'Company goals'},
                { tab: 'Team goals' },
                { tab: 'My goals' }
            ],
            personalGoalModal: false,
            c: {
                goal_title: null,
                goal_privacy_id: null,
                members: [],
                goal_owner: null,
            },
            isComplete: false,
            my_goal_header: [
                {text: "Name", width: '70%'},
                {text: "Time Period", width: '10%'},
                {text: "Progress", width: '10%'},
                {text: "Owner", width: '10%'}
            ],
            hover: false,
            view_status_logs_model: false,
            goalof: 0,
            searchQuery: ''
        }),
        async mounted() {
            document.title = "DG Tasks - Goals";
            await goalService.getGoalPrivacy()
            await userService.getUsers()
            await goalService.getPersonalGoals()
            await goalService.getGoalStatuses()
        },
        computed: {
            ...mapGetters({
                getGoalPrivacy:             'goal/getGoalPrivacy',
                get_user:                   'auth/get_user',
                get_users:                  'users/get_users',
                getPersonalGoals:           'goal/getPersonalGoals',
                getViewPersonalGoal:        'goal/getViewPersonalGoal',
                getViewPersonalGoalBoolean: 'goal/getViewPersonalGoalBoolean',
                getGoalStatusesOpen:        'goal/getGoalStatusesOpen',
                getGoalStatusesClose:       'goal/getGoalStatusesClose'
            }),
            cardWidth() {
                let width;
                // console.log(this.$vuetify.breakpoint)
                if (this.$vuetify.breakpoint.xs) width = '65vw';
                else if (this.$vuetify.breakpoint.sm) width = '65vw';
                else if (this.$vuetify.breakpoint.lgOnly) width = '78vw';
                else if (this.$vuetify.breakpoint.xlOnly) width = "87vw";
                else width = '87vw';
                return { width };
            },
            personalGoals(){
                return this.getPersonalGoals
                    .filter(q => q.goalof.id == 2)
                    .filter(q => q.goal_title.toLowerCase().includes(this.searchQuery.toLowerCase()));
            },
            compangGoals(){
                return this.getPersonalGoals
                    .filter(q => q.goalof.id == 1)
                    .filter(q => q.goal_title.toLowerCase().includes(this.searchQuery.toLowerCase()));
            },
            allGoals(){
                return this.getPersonalGoals.filter(q => q.goal_title.toLowerCase().includes(this.searchQuery.toLowerCase()));
            }
        },
        methods: {
            addPersonalGoals(goalof){
                this.goalof = goalof
                this.personalGoalModal = true
            },
            closeAddPersonalGoalModal(){
                this.personalGoalModal = false
                this.c = {
                    goal_title: null,
                    goal_privacy_id: null,
                    members: [],
                    goal_owner: null
                }
            },
            async addPersonalGoal(){
                try{
                    this.c.goal_of_id = this.goalof
                    await goalService.addPersonalGoal(this.c)
                    this.closeAddPersonalGoalModal()
                    await goalService.getPersonalGoals()
                }catch(error){
                    console.log("adding perosnal goal error - component layer", error)
                }
            },
            openPersonalGoal(item){
                goalService.viewPersonalGoal(item)
            }
        },
        watch: {
            c: {
                deep: true,
                handler(newVal) {
                    this.isComplete =
                        !!newVal.goal_title &&
                        !!newVal.goal_privacy_id &&
                        newVal.members.length > 0 &&
                        !!newVal.goal_owner;
                }
            },
            get_user: {
                handler(user){
                    if (user && user.id) {
                        this.c.goal_owner = user.id;
                    }
                },
                immediate: true
            }
        }
    }
</script>

<style scoped lang="scss">

</style>