<template>
    <v-container>
        <v-card elevation="0" outlined>
            <v-card-title>
                Users
                <v-spacer></v-spacer>
                <v-btn @click="openAddUserDialog()" color="primary">
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    Add User
                </v-btn>
            </v-card-title>
            <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
            <v-card-text v-else>
                <!-- search -->

                <v-text-field
                    dense
                    placeholder="search here..."
                    outlined
                    prepend-inner-icon="mdi-magnify"
                    v-model="searchQuery"
                ></v-text-field>

                <v-virtual-scroll
                    :items="filteredUsers"
                    :item-height="80"
                    :height="`calc(90vh - 100px)`"
                >
                    <template v-slot:default="{ item }">
                        <v-list-item :key="item.id" class="custom-list-item">
                            <v-list-item-avatar class="fixed-avatar">
                                <v-avatar color="primary" size="48" class="white--text">
                                    {{ item?.name[0] }}
                                </v-avatar>
                            </v-list-item-avatar>

                            <v-list-item-content class="fixed-content">
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-list-item-title class="text-body-1 font-weight-medium">
                                            {{ item?.name ?? "No Name" }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-caption">
                                            {{ item.role?.name ?? "No Role" }}<br/>
                                            {{ item?.email ?? "No Email" }}
                                        </v-list-item-subtitle>
                                    </v-col>

                                    <v-col cols="12" sm="6" class="d-flex align-center flex-wrap">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-chip 
                                                    class="ma-2" 
                                                    label 
                                                    small
                                                    v-bind="attrs" 
                                                    v-on="on"
                                                >
                                                    {{ item.access[0] ? item.access[0].company.name : 'None' }}
                                                </v-chip>
                                                <v-chip
                                                    label
                                                    small
                                                    v-bind="attrs" 
                                                    v-on="on"
                                                    v-if="item.access.length > 1"
                                                >
                                                    + {{ item.access?.length - 1 }}
                                                </v-chip>
                                            </template>
                                            <div>
                                                <span v-if="item.access.length">
                                                    <div v-for="(a, index) in item.access" :key="index">
                                                        {{ a.company?.name ?? "No Company" }}
                                                    </div>
                                                </span>
                                                <span v-else>None</span>
                                            </div>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>

                            <!-- Action with Responsive Layout -->
                            <v-list-item-action class="fixed-action">
                                <v-list-item-content>
                                    <div class="d-flex flex-column flex-sm-row">
                                        <v-btn icon @click="openUpdateUserDialog(item)" class="ma-1">
                                            <v-icon small>mdi-pen</v-icon>
                                        </v-btn>
                                        <v-btn icon @click="openDeleteUserDialog(item)" class="ma-1">
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </div>
                                </v-list-item-content>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </v-virtual-scroll>
            </v-card-text>
        </v-card>
        <v-row justify="center">
            <v-dialog v-model="add_user_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Add User</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="new_user.name" label="Name *" outlined dense></v-text-field>
                        <v-text-field v-model="new_user.email" label="Email *" outlined dense></v-text-field>
                        <v-text-field v-model="new_user.password" label="Password *" outlined dense></v-text-field>
                        <v-select v-model="new_user.role_id" label="Role *" :items="get_roles" item-text="name"
                            item-value="id" outlined dense></v-select>
                        <v-select v-if="new_user.role_id > 1" v-model="new_user.access" label="Company Access"
                            item-text="name" item-value="id" multiple :items="get_companies" outlined dense></v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeAddUserDialog()" text>Cancel</v-btn>
                        <v-btn @click="addUser()" color="primary">Add</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="update_user_dialog" max-width="60vw" persistent>
                <v-card
                    style="
                        overflow: hidden !important;
                    "
                >
                    <v-card-title>
                        <small>Update User Info</small>
                        <v-spacer></v-spacer>
                        <v-icon style="cursor: pointer;" @click.stop="update_user_dialog = false">mdi-close</v-icon>
                    </v-card-title>
                    <!-- :cols="[1, 2].includes(get_user.role_id) ? 6 : 12" -->
                    <v-row class="full-height">
                        <v-col
                            cols="6"
                            style="
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                            "
                        >
                            <v-card-title>
                                <span style="font-weight: 700; font-size: 1rem; margin-bottom: 10px;">User Information</span>
                            </v-card-title>
                            <v-card-text>
                                <v-text-field v-model="user_to_update.name" label="Name *" outlined dense></v-text-field>
                                <v-text-field v-model="user_to_update.email" label="Email *" outlined dense></v-text-field>
                                <v-text-field v-model="user_to_update.password" label="Password *" outlined
                                    dense></v-text-field>
                                <v-select v-model="user_to_update.role_id" label="Role *" :items="get_roles" item-text="name"
                                    item-value="id" outlined dense></v-select>
                                <v-select v-model="user_to_update.access" label="Company Access" item-text="name"
                                    item-value="id" multiple :items="get_companies" outlined dense></v-select>
                            </v-card-text>
                            
                            <v-divider/>

                            <v-card-title>
                                <span style="font-weight: 700; font-size: 1rem; margin-bottom: 10px;">Assign Leave credits</span>
                            </v-card-title>
                            <v-card-text>
                                <!-- LC-001: Leave Credit -->
                                <div v-if="user_to_update?.leave !== null" 
                                    style="display: flex; justify-content: center; align-items: center;">
                                    <span style="font-weight: 500; font-size: 0.8rem;">User's Leave credit: {{ user_to_update?.leave?.leave_count }}</span>
                                </div>
                                <v-combobox
                                    dense
                                    outlined
                                    :items="requestTypes"
                                    item-value="id"
                                    item-text="name"
                                    v-model="leave_type"
                                    :disabled="user_to_update?.leave == null ?  false : true"
                                    label="Request Type"
                                ></v-combobox>
                                <v-text-field
                                    label="Leave credits"
                                    outlined
                                    dense
                                    v-model="leave_credits"
                                    :disabled="user_to_update?.leave == null ?  false : true"
                                ></v-text-field>
                                <!--./LC-001: Leave Credit-->
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="closeUpdateUserDialog()" text>Cancel</v-btn>
                                <v-btn @click="updateUser()" color="primary">Update</v-btn>
                            </v-card-actions>
                        </v-col>
                        <v-col
                            cols="6"
                        >
                            <v-card-text>
                                <v-card
                                    max-width="100vw;"
                                >
                                    <div v-if="user_to_update && user_to_update.notes && user_to_update.notes.length === 0"  
                                        style="display: flex; justify-content: center; align-items: center; height: 100%;">
                                        <label style="color: grey; font-size: 24px;">
                                            Click add note to get started...
                                        </label>
                                    </div>
                                    <v-list 
                                        flat
                                        :style="{
                                            height: wysiwyg_show ? '150px' : '300px',
                                            overflowY: 'auto',
                                            width: '100%',
                                            float: 'left',
                                            marginRight: '10px'
                                        }"
                                    >
                                        <v-list-item-group
                                            color="indigo"
                                        >
                                            <v-list-item
                                                v-for="(item, i) in user_to_update.notes"
                                                :key="i"
                                            >
                                                <v-list-item-avatar class="fixed-avatar">
                                                    <v-avatar color="primary" size="56" class="white--text">
                                                        {{ item.note_by?.name[0] ?? "NN" }}
                                                    </v-avatar>
                                                </v-list-item-avatar>

                                                <v-list-item-content>
                                                    <v-row>
                                                        <v-col
                                                            cols="6"
                                                        >
                                                            <v-list-item-title>
                                                                Note By: {{ item.note_by?.name ?? "no name" }}
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>
                                                                {{ $date(item.created_at).format('MMMM DD, YYYY') }}<br />
                                                                {{ $date(item.created_at).format('h:mm A') }}
                                                            </v-list-item-subtitle>
                                                        </v-col>
                                                        <v-col
                                                            cols="6"
                                                        >
                                                            <v-list-item-content v-html="item.notes"/>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-card>
                            </v-card-text>
                            <div
                                style="position: absolute; bottom: 0; width: 29vw;"
                            >
                                <v-card-text>
                                    <wysiwyg v-if="wysiwyg_show" v-model="notes" @keyup.enter.stop="addNote"/>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn @click.stop="wysiwyg_show ? addNote() : (wysiwyg_show = true)" color="primary">
                                        add note
                                    </v-btn>
                                </v-card-actions>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="create_access_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Add Access</v-card-title>
                    <v-card-text>
                        <v-select v-model="access_to_create" label="Company Access" item-text="name" item-value="id"
                            multiple :items="get_companies" outlined dense></v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeCreateAccessDialog()" text>Cancel</v-btn>
                        <v-btn @click="createAccess()" color="primary">Create</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="update_access_dialog" max-width="600" persistent>
                <v-card v-if="selected_access">
                    <v-card-title>Update Access to {{ selected_access.company.name }}</v-card-title>
                    <v-card-text>
                        <v-select v-model="selected_access.allow_suppliers" label="Allow Access to Suppliers Page"
                            :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]" item-text="name"
                            item-value="value" outlined dense></v-select>
                        <v-select v-model="selected_access.allow_packages" label="Allow Access to Packages Page"
                            :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]" item-text="name"
                            item-value="value" outlined dense></v-select>
                        <v-select v-model="selected_access.allow_reports" label="Allow Access to Reports Page"
                            :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]" item-text="name"
                            item-value="value" outlined dense></v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeUpdateAccessDialog()" text>Cancel</v-btn>
                        <v-btn @click="updateAccess()" color="primary">Update</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="delete_access_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Are you sure you want to remove access to this company?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeDeleteAccessDialog()" text>Cancel</v-btn>
                        <v-btn @click="deleteAccess()" color="primary">Delete</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="delete_user_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Are you sure you want to delete this user?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeDeleteUserDialog()" text>Cancel</v-btn>
                        <v-btn @click="deleteUser()" color="primary">Delete</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

/**service layer */
import userService from '@/service/user.service'
import leaveService from '@/service/leave.service' //LC-001: Leave Credit

export default {
    components: {
    },
    props: [
    ],
    data() {
        return {
            loading: false,
            headers: [
                { text: 'Name', width: '15%' },
                { text: 'Email Address', width: '25%' },
                { text: 'Role', width: '10%' },
                { text: 'Created At', width: '10%' },
                { text: 'Company Access', width: '30%' },
                { text: '', width: '1%' }
            ],
            add_user_dialog: false,
            new_user: {
                name: null,
                email: null,
                password: null,
                role_id: null,
                access: [],
            },
            update_user_dialog: false,
            user_to_update: {
                id: null,
                name: null,
                email: null,
                password: null,
                role_id: null,
                access: [],
                notes: [],
                leave: null
            },
            create_access_dialog: false,
            selectable_companies: [],
            selected_user: null,
            access_to_create: null,
            delete_access_dialog: false,
            access_to_delete: null,
            delete_user_dialog: false,
            selected_access: null,
            update_access_dialog: false,
            /**notes */
            notes: null,
            wysiwyg_show: false,
            /**laeve */
            leave_credits: 0,
            leave_type: null
        }
    },
    created() {
    },
    async mounted() {
        document.title = "DG Tasks - Users";
        this.getUsers()
        await leaveService.getLeaveTypes()
    },
    computed: {
        ...mapGetters({
            get_roles:              'auth/get_roles',
            get_companies:          'companies/get_companies',
            get_selected_company:   'companies/get_selected_company',
            users:                  "users/get_users",
            get_user:               'auth/get_user',
            laeveTypes:             "leave/getLeaveTypes"
        }),
        searchQuery: {
            get() {
                return this.$store.state.users.searchQuery || '';
            },
            set(value) {
                this.$store.commit("users/set_search_query", value);
            }
        },
        filteredUsers() {
            return this.users.filter(user =>
                user.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                user.email.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                (user.role?.name ?? "").toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        },
        requestTypes(){
            return this.laeveTypes.filter(q => {
                return q.id == 1
            })
        }
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async getUsers() {
            this.loading = true
            await userService.getUsers()
            this.loading = false
        },
        /**add note */
        async addNote(){
            try{
                const tp = {
                    user_id:    this.user_to_update.id,
                    note_by:    this.get_user.id,
                    notes:      this.notes
                }
                const response = await userService.addNoteUser(tp)
                const data = response.find(q => {
                    return q.id === this.user_to_update.id
                })
                this.notes = null
                this.user_to_update.notes = data.get_note
                this.wysiwyg_show = false
            }catch(error){
                console.log('error on adding note - component level', error)
            }
        },
        openUpdateAccessDialog(access) {
            this.selected_access = access
            this.update_access_dialog = true
        },
        closeUpdateAccessDialog() {
            this.selected_access = null
            this.update_access_dialog = false
        },
        async updateAccess() {
            let payload = {
                id: this.selected_access.id,
                allow_suppliers: this.selected_access.allow_suppliers,
                allow_packages: this.selected_access.allow_packages,
                allow_reports: this.selected_access.allow_reports,
            }
            await this.$axios.post('api/users/update_user_access', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeUpdateAccessDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openAddUserDialog() {
            this.add_user_dialog = true
        },
        closeAddUserDialog() {
            this.add_user_dialog = false
            this.new_user = {
                name: null,
                email: null,
                password: null,
                role_id: null,
                access: [],
            }
        },
        async addUser() {
            let payload = {
                ...this.new_user,
                company_id: this.get_selected_company.id
            }
            await this.$axios.post('api/users/create_user', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeAddUserDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openUpdateUserDialog(item) {
            this.update_user_dialog = true
            this.user_to_update.id = item.id
            this.user_to_update.name = item.name
            this.user_to_update.email = item.email
            this.user_to_update.password = item.password
            this.user_to_update.role_id = item.role_id
            this.user_to_update.notes = item.get_note
            this.user_to_update.leave = item.get_leaves
            item.access.forEach(access => {
                this.user_to_update.access.push(access.company_id)
            })
        },
        closeUpdateUserDialog() {
            this.update_user_dialog = false
            this.user_to_update = {
                id: null,
                name: null,
                email: null,
                password: null,
                role_id: null,
                access: [],
            }
        },
        async updateUser() {
            await this.$axios.post(`api/users/update_user/${this.user_to_update.id}`, this.user_to_update)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })

            /** LC-001: Leave Credit */
            if(this.leave_credits != 0 && Object.keys(this.leave_type).length > 0){
                const tp = {
                    leave_type_id:      this.leave_type.id,
                    user_id:            this.user_to_update.id,
                    leave_count:        this.leave_credits
                }
                await leaveService.addLeaveCredits(tp)
                this.closeUpdateUserDialog()
            }else{
                this.closeUpdateUserDialog()
            }
        },
        openDeleteUserDialog(item) {
            this.selected_user = item
            this.delete_user_dialog = true
        },
        closeDeleteUserDialog() {
            this.delete_user_dialog = false
        },
        async deleteUser() {
            await this.$axios.delete(`api/users/delete_user/${this.selected_user.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteUserDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openCreateAccessDialog(item) {
            this.selected_user = item
            this.create_access_dialog = true
            this.selectable_companies = Object.assign([], this.get_companies)
            if (item.access) {
                item.access.forEach(access => {
                    this.get_companies.forEach((company, index) => {
                        if (access.company_id == company.id) {
                            this.selectable_companies.splice(index, 1)
                        }
                    })
                })
            }
        },
        closeCreateAccessDialog() {
            this.create_access_dialog = false
            this.selected_user = null
            this.selectable_companies = []
            this.access_to_create = null
        },
        async createAccess() {
            let payload = {
                access: this.access_to_create,
                user_id: this.selected_user.id,
            }
            await this.$axios.post('api/users/create_user_access', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateAccessDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openDeleteAccessDialog(access) {
            this.delete_access_dialog = true
            this.access_to_delete = access
        },
        closeDeleteAccessDialog() {
            this.delete_access_dialog = false
            this.access_to_delete = null
        },
        async deleteAccess() {
            await this.$axios.delete(`api/users/delete_user_access/${this.access_to_delete.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteAccessDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        }
    },
    watch: {

    }

}
</script>

<style scoped lang="scss">
.access-label {
    color: #669E53;
    background-color: #E6F3E2;
    font-size: 12px;
    border: solid 1px #669E53;
    border-radius: 4px;
    padding: 3px 5px;
    margin-right: 5px;
    margin-bottom: 3px;
}

.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: white;
    color: black;
    text-align: left;
    padding: 5px 0;
    border-radius: 6px;
    max-height: 200px;
    overflow-y: scroll;

    /* Position the tooltip text - see examples below! */
    position: fixed;
    z-index: 10;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

:deep(::-webkit-scrollbar) {
    width: 10px;
}

:deep(::-webkit-scrollbar-thumb) {
    border-radius: 2px;
    /* background-color: rgba(0,0,0,.5); */
    background-color: #a5a5a5;
    color: #a5a5a5;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

/* Scrollbar for WebKit browsers (Chrome, Edge, Safari) */
.v-virtual-scroll::-webkit-scrollbar {
  width: 8px;
}

.v-virtual-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.v-virtual-scroll::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

/* Firefox scrollbar */
.v-virtual-scroll {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1);
}

/* Ensure the list items don’t overflow */
.v-list-item {
  overflow-y: auto !important;
  max-height: 350px; /* Adjust based on need */
}

</style>