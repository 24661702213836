<template>
    <v-card elevation="0">
        <v-treeview
            :items="tasks"
            item-key="status_id"
            transition
        >
            <template v-slot:label="{ item }">
                <div @click="toggleItem(item.status_id ? item.status_id : item.company_id)" class="cursor-pointer tree-item">
                    <v-badge
                        bordered
                        overlap
                        :content="item.tasks.length"
                        offset-x="-2"
                        color="blue"
                    >
                        <small style="font-weight: 700; font-size: 0.9rem;">{{ item.name }}</small>
                    </v-badge>
                </div>
                <v-data-table
                    v-if="open.includes(item.status_id) && item.tasks.length"
                    :items="item.tasks"
                    :headers="header"
                    :items-per-page="-1"
                    :class="{ 'overflow-y-auto': isScreen1368 }"
                    hide-default-footer
                    disable-pagination
                    class="mt-3 tree-table"
                    style="max-height: 300px; overflow-y: auto;" 
                >
                    <!-- Custom Header for Allocated Time -->
                    <template v-slot:[`header.allocated_time`]="{ header }">
                        <span style="font-weight: bold;">{{ header.text }}</span><br/>
                        <v-chip
                            label
                            small
                            class="text-caption"
                            style="cursor: pointer; font-weight: 700;"
                        ><v-icon small class="mr-1">mdi-clock-time-two</v-icon>{{ totalAllocatedTime }}</v-chip>
                    </template>

                    <template v-slot:[`item.creator`]="{ item }">
                        <small style="font-weight: 900; font-size: 0.8rem;">{{ item.creator?.name ?? "No Name" }}</small>
                    </template>

                    <template v-slot:[`item.name`]="{ item }">
                        <small style="font-weight: 900; font-size: 0.8rem;" class="text-wrap">{{ item.name ?? "No task name" }}</small>
                    </template>

                    <template v-slot:[`item.company`]="{ item }">
                        <v-chip
                            label
                            small
                            class="text-caption"
                            style="cursor: pointer; font-weight: 700;"
                        >
                            {{ item.company?.name ?? "No company" }}
                        </v-chip>
                    </template>

                    <template v-slot:[`item.allocated_time`]="{item}">
                        <v-edit-dialog
                            :return-value.sync="item.allocated_time"
                            persistent
                            @save="save(item)"
                            light
                            large
                        >
                            <small style="font-weight: 900; font-size: 0.8rem;">{{ item.allocated_time }}</small>
                            <template v-slot:input>
                                <small style="font-weight: 700;">
                                    Update Allocated Time
                                </small>
                                <v-text-field
                                    v-model="allocated_time"
                                    :placeholder="item.allocated_time"
                                    label="Add allocated time"
                                    single-line
                                    autofocus
                                    @input="formatTimeManualAllocated"
                                    @keypress="allowOnlyNumbersAllocated"
                                ></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>

                    <template v-slot:[`item.actual_time`]="{ item }">
                        <v-edit-dialog
                            :return-value.sync="item.allocated_time"
                            light
                            :ref="'editDialog' + item.id"
                        >
                            <small style="font-weight: 900; font-size: 0.8rem;">
                                {{ elapsedTimes[item.task_timer?.id] ?? "start time" }}
                            </small>
                            <small style="display: block;">spent time: {{ computeTaskTime(item.formatted_total_time) }}</small>
                            <template v-slot:input>
                                <div style="padding: 15px">
                                    <small style="font-weight: 700; font-size: 0.9rem;">
                                        Current sessions
                                    </small>
                                    <v-btn-toggle
                                        v-if="item.task_timer == null"
                                        style="display: block;"
                                    >
                                        <v-btn
                                            class="text-none mt-2"
                                            style="display: block;"
                                            @click.stop="startTimer(item)"
                                            depressed
                                            small
                                        >
                                            <v-icon class="mb-2" :style="{ color: item.status.color }" small>mdi-timer-play</v-icon>
                                            start timer
                                        </v-btn>
                                        <v-btn
                                            class="text-none mt-2"
                                            style="display: block;"
                                            depressed
                                            @click.stop="addManualTimer(item)"
                                            small
                                        >
                                            <v-icon class="mb-2" :style="{ color: item.status.color }" small>mdi-timer-play</v-icon>
                                            add manual time
                                        </v-btn>
                                    </v-btn-toggle>
                                    <v-btn-toggle
                                        v-else
                                        style="display: block;"
                                    >
                                        <v-btn
                                            depressed
                                            class="text-none mt-2"
                                            style="display: block;"
                                            @click.stop="stopTimer(item.task_timer)"
                                            small
                                        >
                                            <v-icon class="mb-2" :style="{ color: item.status.color }" small>mdi-timer-play</v-icon>
                                            stop timer
                                        </v-btn>
                                    </v-btn-toggle>
                                    <div
                                        v-if="manual_timer_boolean"
                                        class="mt-2"
                                    >
                                        <small>Add manual time</small>
                                        <v-text-field
                                            outlined
                                            placeholder="00:00:00"
                                            dense
                                            type="text"
                                            @input="formatTimeManual"
                                            @keypress="allowOnlyNumbers"
                                            v-model="manual_time"
                                            @keyup.enter="addManualTimeFn(item)"
                                            suffix="Press enter to save"
                                        >
                                        </v-text-field>
                                    </div>
                                </div>
                            </template>
                        </v-edit-dialog>
                    </template>
                </v-data-table>
            </template>
        </v-treeview>
    </v-card>
</template>

<script>
/**services */
import TimeTrackService from '@/service/TimeTrack.service';

export default {
    name: 'TreeViewTable',
    props: {
        tasks: {
            type: Array,
            required: true
        },
        getStatuses: {
            type: Array,
            required: true
        },
        header: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        open: [],
        allocated_time: "",
        elapsedTimes: {},
        intervalId: null,
        manual_timer_boolean: false,
        manual_time: ""
    }),
    mounted() {
        this.startAutoUpdate();
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
    computed: {
        totalAllocatedTime() {
            if (!this.open.length) return "00:00:00";
            const lastStatusId = this.open[this.open.length - 1];
            const group = this.tasks.find(t => t.status_id === lastStatusId);
            return group ? group.total_allocated_time : "00:00:00";
        },
        isScreen1368() {
            return window.innerWidth === 1368;
        }
    },
    methods: {
        toggleItem(status_id) {
            this.open = this.open[0] === status_id ? [] : [status_id]; 
        },
        async save(record) {
            const formattedTime = this.appendSeconds(this.allocated_time);
            try {
                await TimeTrackService.updateAllocatedTime({ allocated_time: formattedTime }, record.id);
            } catch (error) {
                console.error("Error saving allocated time - component level", error);
            }
        },
        appendSeconds(timeString) {
            return timeString.includes(":") && timeString.split(":").length === 2 
                ? `${timeString}:00` 
                : timeString;
        },
        async startTimer(data) {
            event.stopPropagation();
            this.$nextTick(() => {
                const dialogRef = this.$refs["editDialog" + data.id];
                if (dialogRef) {
                    dialogRef.isActive = false;
                }
            });
            try {
                await TimeTrackService.startTimer({ task_id: data.id });
            } catch (error) {
                console.error('Error starting task timer', error);
            }
        },
        startAutoUpdate() {
            clearInterval(this.intervalId);
            this.intervalId = setInterval(() => {
                this.tasks.forEach(group => {
                    group.tasks.forEach(task => {
                        if (task.task_timer?.created_at) {
                            const createdAt = new Date(task.task_timer.created_at).getTime();
                            const diff = Math.floor((Date.now() - createdAt) / 1000);
                            this.$set(this.elapsedTimes, task.task_timer.id, this.formatTime(diff));
                        }
                    });
                });
            }, 1000); // Update every second
        },
        formatTime(seconds) {
            const hours = String(Math.floor(seconds / 3600)).padStart(2, "0");
            const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
            const secs = String(seconds % 60).padStart(2, "0");
            return `${hours}:${minutes}:${secs}`;
        },
        async stopTimer(item){
            const time = this.elapsedTimes[item.id]
            try{
                const tp = {
                    is_active: 0,
                    time:   time
                }
                await TimeTrackService.stopTimer(item.id, tp)
            }catch(error){
                console.log("error on stoping timer - component layer", error)
            }
        },
        addManualTimer(item){
            TimeTrackService.toAddManualTime(item)
            this.manual_timer_boolean = true
        },
        formatTimeManual(){
            let time = this.manual_time.replace(/\D/g, "");

            if (time.length > 6) {
                time = time.substring(0, 6);
            }

            this.manual_time = `${time.substring(0, 2)}:${time.substring(2, 4)}:${time.substring(4, 6)}`;
        },
        allowOnlyNumbers(event) {
            if (!/^\d$/.test(event.key)) {
                event.preventDefault();
            }

            if (this.manual_time.replace(/\D/g, "").length >= 6) {
                event.preventDefault();
            }

            // if (this.allocated_time.replace(/\D/g, "").length >= 6) {
            //     event.preventDefault();
            // }
        },
        formatTimeManualAllocated(){
            console.log('went here')
            let time = this.allocated_time.replace(/\D/g, "");

            if (time.length > 6) {
                time = time.substring(0, 6);
            }

            this.allocated_time = `${time.substring(0, 2)}:${time.substring(2, 4)}:${time.substring(4, 6)}`;
        },
        allowOnlyNumbersAllocated(event) {
            if (!/^\d$/.test(event.key)) {
                event.preventDefault();
            }

            if (this.allocated_time.replace(/\D/g, "").length >= 6) {
                event.preventDefault();
            }
        },
        async addManualTimeFn(parent){
            // console.log(parent)
            try{
                const tp = {
                    "is_active":            0,
                    "time":          this.manual_time,
                    "task_id":              parent.id
                }
                await TimeTrackService.manualTimeInsert(tp)
                this.manual_time = ""
            }catch(error){
                console.log("error on adding manual time - component layer", error)
            }
        },
        computeTaskTime(time) {
            const hours = Math.floor(time / 10000)
            const minutes = Math.floor((time % 10000) / 100)
            const seconds = Math.floor((time % 10000) % 100)   
            return `${String(hours).padStart(2, 0)}:${String(minutes).padStart(2, 0)}:${String(seconds).padStart(2, 0)}`
        }
    },
    watch: {
        tasks: {
            handler() {
                this.startAutoUpdate();
            },
            deep: true,
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
    cursor: pointer;
}
.tree-item {
    margin-bottom: 24px; 
    padding: 10px;
    border-radius: 8px;
    background-color: #f9f9f9; 
}

.tree-table {
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
    border-radius: 6px;
    background: white;
    margin-bottom: 30px;
}

.tree-table .v-data-table__wrapper td {
    height: 300px !important;
}
.text-wrap {
    white-space: normal !important;
    word-break: break-word;
}
</style>