<template>
    <div>
        <v-row justify="center" class="mt-10">
            <v-card
                :max-width="cardWidth"
                elevation="0"
            >
                <v-row>
                    <v-col
                        cols="8"
                    >
                        <v-card-title 
                            @mouseover="callTitleWysiwyg(true)"
                            @mouseleave="callTitleWysiwyg(false); saveTitleChanges()" 
                            :style="{ border: is_hover_title ? '' : '' }"
                        >
                            <label v-show="!is_hover_title">{{ getViewPersonalGoal.goal_title ?? "no title" }}</label>
                            <v-text-field v-show="is_hover_title" :placeholder="getViewPersonalGoal.goal_title" v-model="new_title" outlined></v-text-field>
                        </v-card-title>
                        <v-card-subtitle class="mt-1">
                            <label style="font-size: 1rem;">What's the status?</label><br/>
                            <!-- <v-chip
                                v-for="(item, index) in getGoalStatusesOpen"
                                :key="item.id || index"
                                class="mr-2"
                                outlined
                                label
                                style="cursor: pointer; color: black;"
                                dark
                                small
                                v-show="getViewPersonalGoal.status == null"
                            >
                                <v-avatar left>
                                    <v-icon :style="{ color: item.color }">mdi-circle-small</v-icon>
                                </v-avatar>
                                {{ item.name }}
                            </v-chip> -->
                            <v-chip 
                                small 
                                label 
                                class="px-0 pr-2 pl-2"
                            >
                                <v-icon :style="{ color: getViewPersonalGoal?.status?.color || 'black' }">mdi-circle-medium</v-icon>{{ getViewPersonalGoal.status?.name ?? "no name" }}
                            </v-chip>
                        </v-card-subtitle>
                        <v-card-text style="border-radius: 8px; border: 0.5px solid #999; padding: 25px; cursor: pointer;" @click.stop="viewStatusLogs">
                            <small style="font-size: 1rem; font-weight: 700;">Status update <v-icon>mdi-circle-small</v-icon> {{ $date(new Date()).format('MMM D') }}</small>
                            <small style="display: block; font-size: 0.9rem; font-weight: 700;">Summary</small>
                            <v-list flat style="width: 100%;">
                                <v-list-item
                                    v-if="getViewPersonalGoal.status_logs.length > 0"
                                >
                                    <v-list-item-avatar>
                                        <v-avatar color="blue" size="36">
                                            <label style="font-size: 0.9rem;">{{ getViewPersonalGoal.status_logs[0]?.user?.name[0] ?? "N" }}</label>
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ getViewPersonalGoal.status_logs[0]?.user?.name ?? "No name" }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ $date(getViewPersonalGoal.status_logs[0]?.created_at).fromNow() }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-row>
                                            <v-btn icon>
                                                <v-icon small color="grey lighten-1">mdi-thumb-up-outline</v-icon>
                                            </v-btn>
                                            <v-btn icon>
                                                <v-icon small color="grey lighten-1">mdi-share-outline</v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                        <v-card-text>
                            <v-row>
                                <v-col
                                    cols="6"
                                    class="text-center"
                                    v-if="getViewPersonalGoal.status_logs.length > 0"
                                >
                                    <v-card style="border-radius: 8px; padding: 25px; width: 14vw; border: 0.5px solid #999;" elevation="0" @click.stop="menu = true">
                                        <v-card-title class="justify-center">
                                            <small style="font-size: 0.9 rem; font-weight: 300;">Deadline</small>
                                        </v-card-title>
                                        <v-card-subtitle>
                                            <label style="font-weight: 900; font-size: 1.3rem;">
                                                {{ $date(getViewPersonalGoal.target_date).format('MMM D YYYY') ?? "set a date" }}
                                            </label>
                                            <small style="display: block; font-size: 0.8rem; font-weight: 700; position: relative; top: 5px">{{ $date(getViewPersonalGoal.target_date).fromNow() }}</small>
                                        </v-card-subtitle>
                                    </v-card>
                                </v-col>
                                <v-col 
                                    v-else 
                                    cols="6" 
                                    class="text-center"
                                >
                                    <v-card style="border-radius: 8px; padding: 25px; width: 14vw; border: 0.5px solid #999;" elevation="0" @click.stop="menu = true">
                                        <v-card-title class="justify-center">
                                            <small style="font-size: 0.9 rem; font-weight: 300;">Deadline</small>
                                        </v-card-title>
                                        <v-card-subtitle>
                                            <label style="font-weight: 900; font-size: 1.3rem;">
                                                Set a deadline
                                            </label>
                                        </v-card-subtitle>
                                    </v-card>
                                </v-col>
                                <v-col
                                    cols="6"
                                    class="text-center"
                                    v-if="getViewPersonalGoal.status_logs.length > 0"
                                >
                                    <v-card style="border-radius: 8px; padding: 25px; width: 14vw; border: 0.5px solid #999;" elevation="0" @click.stop="view_status_logs_model = true">
                                        <v-card-title class="justify-center">
                                            <small style="font-size: 0.8rem; font-weight: 300;">Latest status</small>
                                        </v-card-title>
                                        <v-card-subtitle>
                                            <label style="font-weight: 900; font-size: 1.3rem;">
                                                <v-icon :style="{color: getViewPersonalGoal.status_logs[0].status.color}">mdi-circle</v-icon>
                                                {{ getViewPersonalGoal.status_logs[0].status.name }}
                                            </label>
                                            <small style="display: block; font-size: 0.8rem; font-weight: 700; position: relative; top: 5px">Updated at {{ $date(getViewPersonalGoal.status_logs[0]?.created_at).fromNow() }}</small>
                                        </v-card-subtitle>
                                    </v-card>
                                </v-col>
                                <v-col 
                                    v-else 
                                    cols="6" 
                                    class="text-center"
                                >
                                    <v-card style="border-radius: 8px; padding: 25px; width: 14vw; border: 0.5px solid #999;" elevation="0" @click.stop="view_status_logs_model = true">
                                        <v-card-title class="justify-center">
                                            <small style="font-size: 0.9 rem; font-weight: 300;">Latest status</small>
                                        </v-card-title>
                                        <v-card-subtitle>
                                            <label style="font-weight: 900; font-size: 1.3rem;">
                                                Set a status
                                            </label>
                                        </v-card-subtitle>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text>
                            <small style="font-size: 1rem;">Description</small>
                        </v-card-text>
                        <v-card-text
                            @mouseover="callDescriptonWysiwyg(true)"
                            @mouseleave="callDescriptonWysiwyg(false); updateDescription();"
                            :style="{ height: is_hover_description ? '150px' : '150px', top: is_hover_description ? '-15px' : '-25px'}"
                            style="position: relative; top: -25px;"
                        >
                            <label v-show="!is_hover_description" v-html="getViewPersonalGoal?.description || 'Set your description here'"/>
                            <wysiwyg v-show="is_hover_description" v-model="description"/>
                        </v-card-text>
                        <v-card-text style="border-radius: 8px; color: black; padding: 25px; position: relative; top: 50px;">
                            <v-list flat style="color: black; width: 100%;">
                                <v-list-item
                                    v-for="(comment, commentIndex) in getViewPersonalGoal.logs"
                                    :key="comment.id || commentIndex"
                                >
                                    <v-list-item-avatar>
                                        <v-avatar color="blue" size="36">
                                            <label style="font-size: 0.9rem;" class="white--text">{{ comment.user.name[0] ?? "N" }}</label>
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-if="commentIndex == 0"><small style="font-weight: 700; font-size: 1rem;">{{ comment.user.name }}</small> created this goal <v-icon style="color: black;">mdi-circle-small</v-icon> <small style="font-size: 0.7rem;">{{ $date(comment.created_at).fromNow() }}</small></v-list-item-title>
                                        <v-list-item-subtitle v-if="commentIndex == 0">{{ $date(comment.created_at).fromNow() }}</v-list-item-subtitle>

                                        <v-list-item-title v-if="commentIndex != 0"><small style="font-weight: 700; font-size: 1rem;">{{ comment.user.name }}</small><v-icon style="color: black;">mdi-circle-small</v-icon><small style="font-size: 0.7rem;">{{ $date(comment.created_at).fromNow() }}</small></v-list-item-title>
                                        <v-list-item-subtitle v-html="comment.comment" v-if="commentIndex != 0"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider style="background: white;" class="mt-5 mb-5" inset/>
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-avatar color="blue" size="36">
                                            <label style="font-size: 0.9rem;" class="white--text">{{ get_user.name[0] ?? "N" }}</label>
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title 
                                            class="ml-2"
                                            @mouseleave="addComment(); showCommentWysiwyg = false"
                                            v-if="showCommentWysiwyg"
                                        >
                                            <wysiwyg v-model="comment"/>
                                        </v-list-item-title>
                                        <v-list-item-title 
                                            class="ml-2"
                                            @mouseover="showCommentWysiwyg = true"
                                            v-if="!showCommentWysiwyg"
                                            style="position: relative; top: 10px;"
                                        >
                                            <v-text-field outlined placeholder="type your comment here.."></v-text-field>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-col>
                    <v-divider vertical style="border-width: 1px; background-color: #f9f9f9;"/>
                    <v-col
                        cols="4"
                    >
                        <v-card-text>
                            <label style="font-weight: 700;">About this goal</label>
                        </v-card-text>
                        <v-card-text class="ma-0 mx-0">
                            <small style="font-weight: 400; font-size: 0.9rem">Goal owner</small><br/>
                            <v-avatar color="blue" size="24">
                                <label style="font-size: 0.9rem; font-weight: 700;" class="white--text">{{ getViewPersonalGoal.owner?.name[0] ?? "N"}}</label>
                            </v-avatar>
                            <small style="font-size: 0.9rem; font-weight: 700;" class="ml-2">{{ getViewPersonalGoal.owner?.name ?? "no name" }}</small>
                        </v-card-text>
                        <v-card-text class="ma-0 mx-0" style="position: relative; top: -15px;">
                            <small style="font-weight: 400; font-size: 0.9rem">Members</small><br/>
                            <div
                                v-for="(member, memberIndex) in getViewPersonalGoal.members"
                                :key="member.id || memberIndex"
                                class="mt-1"
                            >
                                <v-avatar color="blue" size="24">
                                    <label style="font-size: 0.9rem; font-weight: 700;" class="white--text">{{ member.user?.name[0] ?? "N"}}</label>
                                </v-avatar>
                                <small style="font-size: 0.9rem; font-weight: 700;" class="ml-2">{{ member.user?.name ?? "no name" }}</small>
                            </div>
                        </v-card-text>
                        <v-divider style="border-width: 1px; background-color: f9f9f9;"/>
                        <v-card-text class="ma-0 mx-0">
                            <small style="font-weight: 400; font-size: 0.9rem">Time Period</small><br/>
                            <v-chip small label style="font-weight: 500; position: relative; top: -5px">{{ $date(getViewPersonalGoal.target_date).format('MMM D YYYY') }}</v-chip>
                        </v-card-text>
                        <v-card-text class="ma-0 mx-0" style="position: relative; top: -25px;">
                            <small style="font-weight: 400; font-size: 0.9rem">Custom due date</small>
                            <v-menu
                                v-if="date_update == null"
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                style="display: block;"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-model="date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    >mdi-calendar</v-icon>
                                </template>
                                    <v-date-picker
                                    v-model="date"
                                    no-title
                                    scrollable
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click.stop="addDate(date); $refs.menu.save(date)"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                            <small 
                                style="display: block; font-weight: 600;" 
                                v-if="date_update !== null"
                            >{{ $date(date_update).format('MMM D') }}</small>
                        </v-card-text>
                        <v-divider style="border-width: 1px; background-color: f9f9f9; position: relative; top: -20px"/>
                        <v-card-text style="position: relative; top: -20px;">
                            <small style="font-weight: 400; font-size: 0.9rem">Parent goals</small>
                            <small style="font-weight: 400; font-size: 0.8rem; display: block;"><v-icon small class="mr-1">mdi-plus</v-icon>Connect a parent goal</small>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>
        <!-- view status logs -->
        <ViewStatusLogs
            :getViewPersonalGoal="getViewPersonalGoal"
            :view_status_logs_model="view_status_logs_model"
            :getGoalStatusesOpen="getGoalStatusesOpen"
            :getGoalStatusesClose="getGoalStatusesClose"
            @update:view_status_logs_model="view_status_logs_model = $event"
        />
    </div>
</template>

<script>

    /**service */
    import goalService from '@/service/goal.service'

    /**component */
    import ViewStatusLogs from './ViewStatusLogs.vue'

    export default {
        props: {
            getViewPersonalGoal: Object,
            getGoalStatusesOpen: Array,
            getGoalStatusesClose: Array,
            get_user: Object
        },
        components: {
            ViewStatusLogs
        },
        computed: {
            cardWidth() {
                if (this.$vuetify.breakpoint.xs) return '90vw';
                if (this.$vuetify.breakpoint.sm) return '70vw';
                if (this.$vuetify.breakpoint.md) return '60vw';
                return '50vw';
            },
        },
        data: () => ({
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            date_update: null,
            is_hover_title: false,
            new_title: null,
            is_hover_description: false,
            description: null,
            comment: null,
            showCommentWysiwyg: false,
            view_status_logs_model: false
        }),
        methods: {
            async addDate(data){
                if(data == "") return;
                this.date_update = data
                try{
                    const tp = {
                        target_date: this.date_update
                    }
                    await goalService.updateGoal(this.getViewPersonalGoal.id, tp)
                }catch(error){
                    console.log('error on update due date - component', error)
                }
            },
            callTitleWysiwyg(state){
                this.is_hover_title = state
            },
            async saveTitleChanges(){
                try{
                    const tp = {
                        goal_title: this.new_title ?? this.getViewPersonalGoal.goal_title
                    }
                    await goalService.updateGoal(this.getViewPersonalGoal.id, tp)
                }catch(error){
                    console.log('error on update goal - component', error)
                }
            },
            callDescriptonWysiwyg(state){
                this.is_hover_description = state
            },
            async updateDescription(){
                try{
                    const tp = {
                        description: this.description ?? this.getViewPersonalGoal.description
                    }
                    await goalService.updateGoal(this.getViewPersonalGoal.id, tp)
                    this.description = null
                }catch(error){
                    console.log('error on update description - component', error)
                }
            },
            async addComment(){
                if(this.comment == null) return
                try{
                    const tp = {
                        goal_id:    this.getViewPersonalGoal.id,
                        comment:    this.comment
                    }
                    await goalService.addComment(tp)
                    this.comment = null
                    this.showCommentWysiwyg = false
                }catch(error){
                    console.log("adding comment error component level", error)
                }
            },
            viewStatusLogs(){
                this.view_status_logs_model = true
            }
        }
    }
</script>

<style lang="scss" scope>
.comment-wysiwyg[contenteditable="true"]:empty:before {
    content: attr(placeholder);
    color: white !important;
    font-style: italic;
}
.custom-white-border ::v-deep(.v-input__control) {
  border-color: white !important;
}

.custom-white-border ::v-deep(.v-input__control:hover) {
  border-color: white !important;
}

.custom-white-border ::v-deep(.v-input__control:focus-within) {
  border-color: white !important;
}
</style>