export default {
    namespaced: true,
    state: {
        details: {}
    },
    mutations: {
        set_details(state, payload){
            state.details = payload
        }
    },
    getters: {
        get_details: state => state.details
    },
    actions: {
        set_details({commit}, payload){
            commit('set_details', payload)
        }
    }
}