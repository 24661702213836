export default {
    namespaced: true,
    state: {
        folders: [],
        loading: true,
        editPage: {}
    },
    mutations: {
        set_folder(state, payload){
            state.folders = [...payload]
        },
        set_loading(state, payload){
            state.loading = payload
        },
        set_edit_page(state, payload){
            state.editPage = payload
        }
    },
    getters: {
        get_folders: state => state.folders,
        get_loading: state => state.loading,
        get_edit_page: state => state.editPage
    },
    actions: {
        set_folder({commit}, payload){
            commit('set_folder', payload)
        },
        set_loading({commit}, payload){
            commit('set_loading', payload)
        },
        set_edit_page({commit}, payload){
            commit('set_edit_page', payload)
        }
    }
}