<template>
    <div style="max-height: 75vh; overflow-y: auto;">
        <v-card
            elevation="0"
            v-for="(goal, goalIndex) in getPersonalGoals"
            :key="goal.id || goalIndex"
            class="goal-card mb-4 mt-2"
            @click.stop="$emit('openPersonalGoal', goal)"
        >
            <v-list flat two-line>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="d-flex justify-space-between align-center">
                            <v-badge
                                :content="goal.goalof?.name || 'N/A'"
                                color="blue"
                                offset-x="-1"
                            >
                                <span style="font-weight: 700; font-size: 0.9rem;">{{ goal.goal_title ?? "no title" }}</span>
                            </v-badge>
                            <span class="text-caption text-grey-darken-1">
                                <v-chip small label class="px-0 pr-2 pl-2" style="cursor: pointer; background-color: transparent;">
                                    <v-icon :style="{ color: goal?.status?.color || 'black' }">mdi-circle-medium</v-icon>
                                    {{ goal?.status?.name }}
                                </v-chip>
                                <v-icon style="color: black;">mdi-circle-small</v-icon>
                                <small class="text-caption text-grey-darken-1" style="font-weight: 700; font-size: 0.9rem;">{{ getRandomValue(goal.id) }} %</small>
                                <v-icon style="color: black;" small>mdi-lightning-bolt</v-icon>
                            </span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="mt-2 mb-2">
                            <v-progress-linear :value="getRandomValue(goal.id)" rounded height="7"></v-progress-linear>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-right">
                            <v-chip small label class="px-0 pr-2 pl-2 text-caption" style="cursor: pointer; background-color: transparent; font-weight: 400;">
                                {{ $date(goal.target_date).format('MMM D YYYY') ?? "no deadline" }}
                            </v-chip>
                            <AuthorProfile
                                :item="goal"
                                :users="get_user"
                            />
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </div>
</template>

<script>

    /**vuex */
    import { mapGetters } from 'vuex';

    /**component */
    import AuthorProfile from './AuthorProfile.vue';

    export default{
        props:{
            getPersonalGoals: {
                type: Array,
                required: true,
            },
        },
        data: () => ({
        }),
        components: {
            AuthorProfile
        },
        computed: {
            ...mapGetters({
                get_user: 'auth/get_user'
            })
        },
        methods: {
            getRandomValue() {
                return Math.floor(Math.random() * 101);
            }
        }
    }
</script>

<style scoped>
.goal-card {
    transition: all 0.3s ease-in-out;
    border: 1px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
}

.goal-card:hover {
    border-color: black !important;
}
</style>