<template>
    <v-container>
        <v-card elevation="0" outlined>
            <v-card-title>
                User Activities
                <v-spacer></v-spacer>
                <v-btn @click="getUserActivity()" color="primary">
                    <v-icon class="mr-2">mdi-restart</v-icon>
                    Refresh
                </v-btn>
            </v-card-title>
            <v-skeleton-loader type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions" v-if="loading"></v-skeleton-loader>
            <v-card-text v-else>
                <v-virtual-scroll
                    :items="user_activities"
                    :item-height="100"
                    :height="`calc(90vh - 100px)`"
                >
                    <template v-slot:default="{ item }">
                        <v-list-item :key="item.id" class="custom-list-item">
                            <v-list-item-avatar class="fixed-avatar">
                                <v-avatar color="primary" size="56" class="white--text">
                                    {{ item.user.name[0] }}
                                </v-avatar>
                            </v-list-item-avatar>

                            <v-list-item-content class="fixed-content">
                                <v-row>
                                    <v-col cols="6">
                                        <v-list-item-title>
                                            <small style="font-weight: 700; font-size: 1rem;">{{ item.user.name }}</small>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ $date(item.created_at).format('MMMM DD, YYYY') }}<br />
                                            {{ $date(item.created_at).format('h:mm A') }}
                                        </v-list-item-subtitle>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-list-item-content>
                                            <strong><v-chip>{{ item.company?.name ?? "No Company" }}</v-chip></strong>
                                        </v-list-item-content>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>

                            <!-- Action with Fixed Width -->
                            <v-list-item-action class="fixed-action">
                                <v-list-item-content>
                                    <strong style="font-style: oblique;">
                                        {{ item.message ?? "No Action" }}<br />
                                        {{ item.task?.name ?? "No task" }}
                                    </strong>
                                </v-list-item-content>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </v-virtual-scroll>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

/**service */
//import userActivityService from '@/service/UserActivity.service'
import userActivityService from '@/service/UserActivity.service';

export default {
    components: {
    },
    props: [
    ],
    data() {
        return {
            loading: false,
            headers: [
                { text: 'Timestamp', width: '130px', sortable: false },
                { text: 'User', width: '150px', sortable: false },
                { text: 'Description', width: '200px', sortable: false },
                { text: 'Details', sortable: false },
            ],
            user_activities: [],
            sort_by: 'id',
            sort_desc: true,
        }
    },
    async mounted() {
        document.title = "DG Tasks - User Activities";
        this.getUserActivity()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'companies/get_selected_company',
            get_details: 'userActivity/get_details'
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async getUserActivity() {
            this.loading = true
            this.$axios.get('api/user_activities/get_user_activities')
                .then(({ data }) => {
                    if (data.response) {
                        this.user_activities = data.data
                        this.loading = false
                    }
                })
        },
        async showDetails(item){
            await userActivityService.assignTaskDetails(item)
        }
    },
    watch: {
        
    }

}
</script>

<style scoped lang="scss">
.access-label {
    color: #669E53;
    background-color: #E6F3E2;
    font-size: 12px;
    border: solid 1px #669E53;
    border-radius: 4px;
    padding: 3px 5px;
    margin-right: 5px;
}
/* Scrollbar for WebKit browsers (Chrome, Edge, Safari) */
.v-virtual-scroll::-webkit-scrollbar {
  width: 8px;
}

.v-virtual-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.v-virtual-scroll::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

/* Firefox scrollbar */
.v-virtual-scroll {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1);
}

/* Ensure the list items don’t overflow */
.v-list-item {
  overflow-y: auto !important;
  max-height: 350px; /* Adjust based on need */
}

.custom-list-item {
  min-height: 80px; /* Ensure uniform height */
  display: flex;
  align-items: center;
}

.fixed-avatar {
  width: 70px; /* Fixed width for avatar */
  min-width: 70px; /* Prevent shrinking */
}

.fixed-content {
  width: 50vw;
}

.fixed-action {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  text-align: right;
}

</style>