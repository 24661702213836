import apiClient from "@/api/apiClient";

/**state management */
import store from "@/plugins/vuex"

export default {
    async getGoalPrivacy(){
        try{
            const response = await apiClient.get('api/goals/privacy')
            const { data } = response
            store.commit('goal/setGoalPrivacy', data)
        }catch(error){
            console.log('error on fetching goal privacy - service layer', error)
        }
    },

    /**
     * add personal goal
     * @params
     * - data?: object
     */
    async addPersonalGoal(item) {
        try{
            await apiClient.post('api/goals/personal', item) 
        }catch(error){
            console.log("adding perosnal goal error - service layer", error)
        }
    },

    /**
     * get personal goals
     * @params
     * none
     */
    async getPersonalGoals(){
        try{
            const response = await apiClient.get('api/goals')
            const { data } = response
            store.commit('goal/setPersonalGoals', data)

            // grouped goals
            const groupedGoals = data.reduce((acc, goal) => {
                const privacyName = goal.privacy.name;
        
                if (!acc[privacyName]) {
                    acc[privacyName] = [];
                }
        
                acc[privacyName].push(goal);
                return acc;
            }, {});

            const groupedGoalsArray = Object.keys(groupedGoals).map((key, index) => ({
                id: index + 1,
                name: key,
                items: groupedGoals[key]
            }));

            console.log(groupedGoalsArray)

            store.commit("goal/setGroupedGoals", groupedGoalsArray)

        }catch(error){
            console.log('error on fetching personal goals - service layer', error)
        }
    },

    /**
     * view personal goal
     * @params
     * item?: object
     */
    viewPersonalGoal(item){
        try{
            store.commit('goal/setViewPersonalGoal', item)
            store.commit('goal/setViewPersonalGoalBoolean', true)
        }catch(error){
            console.log('error on putting view personal goal - service layer', error)
        }
    },

    /**
     * unmount personal goal data
     * @params
     * none
     */
    unmountPersonalGoal(){
        try{
            store.commit('goal/setViewPersonalGoal', {})
            store.commit('goal/setViewPersonalGoalBoolean', false)
        }catch(error){
            console.log('error on unmounting view personal goal - service layer', error)
        }
    },

    /**
     * get goal statues
     * @params
     * none
     */
    async getGoalStatuses(){
        try{
            const response = await apiClient.get('api/goals/status')
            const { data } = response
            store.commit('goal/setGoalStatusesOpen', data.open)
            store.commit('goal/setGoalStatusesClose', data.close)
        }catch(error){
            console.log("error on fetching goal statuses - service layer", error)
        }
    },

    /**
     * update goals
     * @params
     * record_id?: number,
     * item?: object
     */
    async updateGoal(record_id, item){
        try{
            const response = await apiClient.patch(`api/goals/${record_id}`, item)
            const { data } = response
            store.commit('goal/setViewPersonalGoal', data)
            this.getPersonalGoals()
        }catch(error){
            console.log('error on update goal - service layer', error)
        }
    },

    /**
     * add status
     * @params
     * item?: object
     */
    async addStatus(item) {
        try{
            const response = await apiClient.post('api/goals/status', item)
            const { data } = response
            store.commit('goal/setViewPersonalGoal', data)
            this.getPersonalGoals()
        }catch(error){
            console.log("error on adding status - service layer", error)
        }
    },

    /**
     * add comment
     * @params
     * item: Object
     */
    async addComment(item){
        try{
            const response = await apiClient.post('api/goals/comment', item)
            const { data } = response
            store.commit('goal/setViewPersonalGoal', data)
            this.getPersonalGoals()
        }catch(error){
            console.log("error on adding comment - service layer", error)
        }
    },

    /**
     * to view status logs
     * @params
     * item?: object
     */
    toViewStatusLog(item){
        store.commit("goal/setViewGoalStatusLog", item)
    },

    /**
     * add status log comment
     * @params
     * item?: object
     */
    async addStatusLogComment(item){
        try{
            const response = await apiClient.post('api/goals/statusLog', item)
            const {data} = response
            store.commit("goal/setViewGoalStatusLog", data)
            this.getPersonalGoals()
        }catch(error){
            console.log("error on adding status log comment - service layer", error)
        }
    }
}