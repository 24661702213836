export default {
    namespaced: true,
    state: {
        tasks: [],
        assigned_tasks: [],
        selected_task: {},
        priorities: [],
        statuses: [],
        selected_priorities: [1, 2, 3, 4, null],
        selected_statuses: [1, 2, 3, 5, 6, null],
        users: [],
        unreadTasks: 0,
        parentTask: {},
        personalTasks: [],
        assignedTasks: [],
        userId: {}
    },
    mutations: {
        set_tasks(state, payload) {
            state.tasks = [...payload]
        },
        set_assigned_tasks(state, payload) {
            state.assigned_tasks = [...payload]
        },
        set_selected_task(state, payload) {
            state.selected_task = payload
        },
        set_priorities(state, payload) {
            state.priorities = [...payload]
        },
        set_statuses(state, payload) {
            state.statuses = [...payload]
        },
        set_selected_priorities(state, payload) {
            state.selected_priorities = [...payload]
        },
        set_selected_statuses(state, payload) {
            state.selected_statuses = [...payload]
        },
        set_users(state, payload) {
            state.users = [...payload]
        },
        set_unread_tasks(state, payload){
            state.unreadTasks = payload
        },
        SET_PARENT_TASK(state, payload){
            state.parentTask = payload
        },
        SET_PERSONAL_TASKS(state, payload){
            state.personalTasks = [...payload]
        },
        SET_ASSIGNED_TASKS(state, payload){
            state.assignedTasks = [...payload]
        },
        SET_USER_ID(state, payload){
            state.userId = payload
        }
    },
    getters: {
        get_tasks: state => state.tasks,
        get_assigned_tasks: state => state.assigned_tasks,
        get_selected_task: state => state.selected_task,
        get_priorities: state => state.priorities,
        get_statuses: state => state.statuses,
        get_selected_priorities: state => state.selected_priorities,
        get_selected_statuses: state => state.selected_statuses,
        get_users: state => state.users,
        get_unread_tasks: state => state.unreadTasks,
        getParentTask: state => state.parentTask,
        getPersonalTasks: state => state.personalTasks,
        getAssignedTasks: state => state.assignedTasks,
        getUserId: state => state.userId
    },
    actions: {
        set_tasks({ commit }, payload) {
            commit('set_tasks', payload)
        },
        set_assigned_tasks({ commit }, payload) {
            commit('set_assigned_tasks', payload)
        },
        set_selected_task({ commit }, payload) {
            commit('set_selected_task', payload)
        },
        set_priorities({ commit }, payload) {
            commit('set_priorities', payload)
        },
        set_statuses({ commit }, payload) {
            commit('set_statuses', payload)
        },
        set_selected_priorities({ commit }, payload) {
            commit('set_selected_priorities', payload)
        },
        set_selected_statuses({ commit }, payload) {
            commit('set_selected_statuses', payload)
        },
        set_users({ commit }, payload) {
            commit('set_users', payload)
        },
        set_unread_tasks({commit}, payload){
            commit('set_unread_tasks', payload)
        },
        setParentTask({commit}, payload){
            commit("SET_PARENT_TASK", payload)
        },
        setPersonalTasks({commit}, payload){
            commit("SET_PERSONAL_TASKS", payload)
        },
        setAssignedTasks({commit}, payload){
            commit("SET_ASSIGNED_TASKS", payload)
        },
        setUserId({commit}, payload){
            commit("SET_USER_ID", payload)
        }
    }
}