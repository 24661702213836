export default {
    namespaced: true,
    state: {
        personal_tasks: [],
        assigned_tasks: [],
        to_add_manual_time: {}
    },
    mutations: {
        SET_PERSONAL_TASKS(state, payload){
            state.personal_tasks = [...payload]
        },
        SET_ASSIGNED_TASKS(state, payload){
            state.assigned_tasks = [...payload]
        },
        SET_TO_ADD_MANUAL_TIME(state, payload){
            state.to_add_manual_time = payload
        }
    },
    getters: {
        getPersonalTasks: state => state.personal_tasks,
        getAssignedTasks: state => state.assigned_tasks,
        getToAddManualTime: state => state.to_add_manual_time
    },
    actions: {
        setPersonalTasks({commit}, payload){
            commit("SET_PERSONAL_TASKS", payload)
        },
        setAssignedTasks({commit}, payload){
            commit("SET_ASSIGNED_TASKS", payload)
        },
        setToAddManualTime({commit}, payload){
            commit("SET_TO_ADD_MANUAL_TIME", payload)
        }
    }
}