import apiClient from "@/api/apiClient";

/**service */
import dashboardService from "./dashboard.service";

/**store */
import store from "@/plugins/vuex"

export default{
    /**
     * @params
     * item?:   object,
     * id?:     int
     */
    async updateAllocatedTime(item, id){
        try{
            await apiClient.patch(`api/tasks/update_a_task/${id}`, item)
            await dashboardService.getMyTasks()
        }catch(error){
            console.log("error on saving new allocated time - service layar", error)
        }
    },

    /**
     * start time
     * @params
     * item?: object
     */
    async startTimer(item){
        try{
            await apiClient.post('api/timer', item)
            await dashboardService.getMyTasks()
        }catch(error){
            console.log("error on starting time - service layer", error)
        }
    },

    /**
     * stop timer
     * @params
     * record_id?: int,
     * item?: object
     */
    async stopTimer(record_id, item){
        try{
            await apiClient.patch(`api/timer/${record_id}`, item)
            await dashboardService.getMyTasks()
        }catch(error){
            console.log("error on stoping timer - service layer", error)
        }
    },

    /**
     * to edit manual time
     * @params
     * item?: object
     */
    toAddManualTime(item){
        store.commit("timetrack/SET_TO_ADD_MANUAL_TIME", item)
    },

    /**
     * add manual time
     * @Params
     * item?: object
     */
    async manualTimeInsert(item){
        try{
            await apiClient.post('api/timer/manualTime', item)
            await dashboardService.getMyTasks()
        }catch(error){
            console.log("error on adding manual time - service layer", error)
        }
    },

    async getGoalsForInvoiceLogs(){

    }
}