import ProfileComponent from '../ProfileComponent.vue'
import LeaveRequest from '../LeaveRequest.vue'
import LeaveApproval from '../LeaveApproval.vue'

export default [
    {
        path: '/profile',
        name: 'Profile',
        meta: { layout: 'default' },
        component: ProfileComponent,
    },
    {
        path: '/leaveRequest',
        name: 'LeaveRequest',
        meta: { layout: 'default' },
        component: LeaveRequest,
    },
    {
        path: '/leaveApproval',
        name: 'LeaveAPproval',
        meta: {layout: 'default'},
        component: LeaveApproval
    }
]