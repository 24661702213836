<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="d-flex justify-center">
                <v-card outlined width="450" class="mt-10">
                    <v-card-title>Login</v-card-title>
                    <v-card-text>
                        <v-text-field @keyup.enter="login()" v-model="email" label="Email" prepend-inner-icon="mdi-email-outline" outlined
                            dense></v-text-field>
                        <v-text-field @keyup.enter="login()" v-model="password"
                            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show_password ? 'text' : 'password'" label="Password"
                            @click:append="show_password = !show_password" prepend-inner-icon="mdi-lock-outline"
                            outlined dense></v-text-field>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn color="primary" @click="login()">Login</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'LoginComponent',
    data: () => ({
        show_password: false,
        email: null,
        password: null,
    }),
    mounted() {
        document.title = "DG Tasks - Login";
        this.checkLogin()
    },
    computed: {
        ...mapGetters({
            user: 'auth/get_user',
        }),
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        checkLogin() {
            if (Object.keys(this.user).length !== 0) {
                this.$router.push({ name: 'Dashboard' })
            }
        },
        async login() {
            let payload = {
                email: this.email,
                password: this.password,
            }
            await this.$axios.post('api/login', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.$store.dispatch('auth/set_user', data.user)
                        this.$store.dispatch('auth/set_token', data.token)
                        this.$store.dispatch('auth/set_roles', data.roles)
                        this.$store.dispatch('tasks/set_priorities', data.priorities)
                        this.$store.dispatch('tasks/set_statuses', data.statuses)
                        this.$store.dispatch('meetings/set_meeting_statuses', data.meeting_statuses)
                        this.$store.dispatch('projects/set_project_statuses', data.project_statuses)
                        this.$router.go({ name: 'Dashboard' })
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
    },
}
</script>

<style scoped></style>
