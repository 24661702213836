import axios from 'axios'
import router from '../router/router.js'

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL, // Make sure this is set in .env
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
    },
    withCredentials: true, // Needed if using authentication cookies
});

// Request Interceptor (Attach Token)
apiClient.interceptors.request.use((config) => {
    const storedData = JSON.parse(localStorage.getItem('vuex')) || {};
    const token = storedData?.auth?.token;

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => Promise.reject(error));

// Response Interceptor (Handle Unauthorized)
apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            localStorage.clear();
            router.push({ name: 'Login' });
            location.reload();
        }
        return Promise.reject(error);
    }
);

export default apiClient;
