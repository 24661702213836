import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import router from './router/router'
import store from './plugins/vuex'
import axios from './plugins/axios.js'
import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import wysiwyg from 'vue-wysiwyg'
import 'vue-wysiwyg/dist/vueWysiwyg.css';
import '@/plugins/dayjs'
import Pusher from 'pusher-js'
import VueEcho from 'vue-echo-laravel'

Vue.component('vue-timepicker', VueTimepicker)

import BlankLayout from './layouts/BlankLayout.vue'
import DefaultLayout from './layouts/DefaultLayout.vue'
Vue.component('default-layout', DefaultLayout)
Vue.component('blank-layout', BlankLayout)
Vue.config.productionTip = false

Vue.use(Vuetify)
Vue.use(axios)
Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
  position: POSITION.BOTTOM_LEFT
})
let wysiwyg_config = {
  hideModules: { 'removeFormat': true, 'code': true }
}
Vue.use(wysiwyg, wysiwyg_config)

Pusher.logToConsole = false
let g_token
let token
if (JSON.parse(localStorage.getItem('vuex'))) {
  g_token = JSON.parse(localStorage.getItem('vuex'))
  token = g_token.auth.token
}
Vue.use(VueEcho, {
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: true,
  authEndpoint: process.env.VUE_APP_API_URL + 'api/broadcasting/auth',
  auth: {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    }
  }
})

new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  render: h => h(App),
}).$mount('#app')