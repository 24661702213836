<template>
    <v-card elevation="0">
        <span style="font-weight: 800; font-size: 1rem;" class="ml-10">Personal tasks</span>
        <template
            v-if="tasks.length > 0"
        >
            <v-treeview
                :items="tasks"
                item-key="status_id"
                transition
            >
                <template v-slot:label="{ item }">
                    <div @click="toggleItem(item.status_id)" class="cursor-pointer tree-item">
                        <v-badge
                            bordered
                            overlap
                            :content="item.tasks.length"
                            offset-x="-2"
                            color="blue"
                        >
                            <small style="font-weight: 700; font-size: 0.9rem;">{{ item.name ?? "no name" }}</small>
                        </v-badge>
                        <v-btn @click.stop="$emit('create-task', 'self')" class="text-none ml-10 pa-0" depressed style="font-size: 0.7rem;"><v-icon small>mdi-plus</v-icon>Add task</v-btn>
                    </div>
                    <v-data-table
                        v-if="open.includes(item.status_id) && item.tasks.length"
                        :items="item.tasks"
                        :headers="header"
                        :items-per-page="-1"
                        :class="{ 'overflow-y-auto': isScreen1368 }"
                        hide-default-footer
                        disable-pagination
                        class="mt-3 tree-table"
                        style="max-height: 300px; overflow-y: auto;"
                    >
                        <template v-slot:[`item.name`]="{ item }">
                            <div class="d-flex align-center">
                                <v-checkbox
                                    class="mr-2"
                                    style="position: relative; top: -11px; font-size: 0.8rem; font-weight: 700;"
                                    :value="item.id"
                                    density="compact"
                                    hide-details
                                    @change="(isChecked) => bulkUpdateUser(item, isChecked)"
                                    @click.stop
                                ></v-checkbox>
                                <small class="font-weight-bold text-wrap" style="font-size: 0.8rem;">
                                    {{ item.name ?? "No task name" }}
                                </small>
                            </div>
                        </template>

                        <template v-slot:[`item.created`]="{ item }">
                            <small style="font-weight: 500; font-size: 0.8rem;" class="text-wrap">{{ $date(item.created_at).format('MMM D, YYYY') ?? "set a date" }}</small>
                        </template>

                        <template v-slot:[`item.company`]="{ item }">
                            <v-chip
                                label
                                small
                                class="text-caption"
                                style="cursor: pointer; font-weight: 500;"
                            >
                                {{ item.company?.name ?? "No company" }}
                            </v-chip>
                        </template>

                        <template v-slot:[`item.assignee`]="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="avatar-stack" v-bind="attrs" v-on="on">
                                        <v-avatar
                                            v-for="(assignee, assigneeIndex) in item.assignees"
                                            :key="assigneeIndex"
                                            size="28"
                                            :color="getRandomColor()"
                                            class="white--text"
                                            :style="{ left: `${assigneeIndex * -12}px` }"
                                        >
                                            <span style="font-size: 0.7rem; font-weight: 500;">
                                                {{ assignee.user?.name.substring(0, 2).toUpperCase() ?? "N" }}
                                            </span>
                                        </v-avatar>
                                    </div>
                                </template>
                                <div class="tooltip-content">
                                    <div v-for="(assignee, index) in item.assignees" :key="index">
                                        {{ assignee.user?.name ?? "Unknown" }}
                                    </div>
                                </div>
                            </v-tooltip>
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                            <v-chip
                                small
                                class="text-caption"
                                style="cursor: pointer; font-weight: 500; font-size: 0.8rem;"
                                :color="item.status.color || 'black'"
                            >
                                {{ item.status?.name ?? "No status" }}
                            </v-chip>
                        </template>

                        <template v-slot:[`item.priority`]="{ item }">
                            <small style="font-size: 0.8rem;" class="text-wrap">
                                <v-icon :style="{ color: item.priority?.color && item.priority.color.trim() !== '' ? item.priority.color : 'black' }">
                                    mdi-flag
                                </v-icon>
                            {{ item.priority?.name ?? "no priority assigned" }}
                            </small>
                        </template>

                        <template v-slot:[`item.view`]="{item}">
                            <v-btn icon @click="openTaskDetailsDialog(item)">
                                <v-icon>mdi-eye-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </template>
            </v-treeview>
        </template>
        
        <template
            v-else
        >
            <v-btn @click.stop="$emit('create-task', 'self')" class="text-none ml-1 pa-0" depressed style="font-size: 0.7rem;"><v-icon small>mdi-plus</v-icon>Add task</v-btn>
        </template>

        <TaskNavigationComponent 
            :task_details_dialog="task_details_dialog"
            :can_close_task_details_dialog="can_close_task_details_dialog" 
            :users="users" 
            @updateTask="updateTask"
            @closeTaskDetailsDialog="closeTaskDetailsDialog" 
            @getTasks="getTasks" 
        />

        <v-bottom-navigation
            v-if="bulk_assign_user.length > 0"
            fixed
            app
            dark
        >
            <v-chip
                style="position: relative; top: 13px"
                class="mr-5"
            >
                {{ bulk_assign_user.length }} selected users
            </v-chip>
            <!-- status -->
            <v-menu
                top
                offset-y
                small
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                        small
                        class="mr-3"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon>mdi-list-status</v-icon> Status
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item>
                        <v-btn
                            outlined
                            small
                            rounded
                            @click="removeStatus"
                        >
                            none
                        </v-btn>
                    </v-list-item>
                    <v-list-item
                        v-for="(item, index) in get_statuses"
                        :key="index"
                    >
                        <v-btn
                            rounded
                            :color="item.color"
                            dark
                            small
                            @click="changeBulkStatus(item)"
                        >
                            {{ item.name }}
                        </v-btn>
                    </v-list-item>
                </v-list>
            </v-menu>
            <!-- company -->
            <v-menu
                top
                offset-y
                small
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                        small
                        class="mr-3"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon>mdi-domain</v-icon> Company
                    </v-btn>
                </template>

                <v-sheet style="max-height: 400px; overflow-y: auto;">
                    <v-list>
                        <v-list-item>
                            <v-btn
                                outlined
                                small
                                @click="removeCompany"
                                rounded
                            >
                                remove all
                            </v-btn>
                        </v-list-item>
                        <v-list-item
                            v-for="(item, index) in get_companies"
                            :key="index"
                        >
                            <v-btn
                                rounded
                                color="dark"
                                dark
                                small
                                @click="changeCompany(item)"
                            >
                                {{ item.name }}
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </v-sheet>
            </v-menu>
            <!-- priorities -->
            <v-menu
                top
                offset-y
                small
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                        small
                        class="mr-3"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon>mdi-domain</v-icon> Priority
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item>
                        <v-btn
                            outlined
                            small
                            @click="removePriority"
                            rounded
                        >
                            remove all
                        </v-btn>
                    </v-list-item>
                    <v-list-item
                        v-for="(item, index) in get_priorities"
                        :key="index"
                    >
                        <v-btn
                            rounded
                            :color="item.color || 'black'"
                            dark
                            small
                            @click="changePriority(item)"
                        >
                            {{ item.name }}
                        </v-btn>
                    </v-list-item>
                </v-list>
            </v-menu>
            <!-- assignees -->
            <v-menu
                top
                offset-y
                small
                :close-on-content-click="false"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                        small
                        class="mr-3"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon>mdi-domain</v-icon> Assignees
                    </v-btn>
                </template>

                <v-sheet style="max-height: 400px; overflow-y: auto;">
                    <v-list>
                        <v-list-item>
                            <v-btn
                                outlined
                                small
                                @click="removeAssignee"
                                rounded
                            >
                                unassign
                            </v-btn>
                        </v-list-item>
                        <v-list-item
                            v-for="(item, index) in users"
                            :key="index"
                        >
                            <v-btn
                                rounded
                                color="dark"
                                dark
                                small
                                @click="addUser(item)"
                            >
                                {{ item.name }}
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </v-sheet>
            </v-menu>
            <v-btn 
                small
                class="mr-3"
                @click="deleteATask"
            >
                <v-icon>mdi-delete</v-icon> Delete
            </v-btn>
        </v-bottom-navigation>

    </v-card>
</template>

<script>
/**services */
import TimeTrackService from '@/service/TimeTrack.service';

import { mapGetters } from 'vuex'

import TaskNavigationComponent from "./TaskNavigationComponent.vue"

import dashboardService from '@/service/dashboard.service';

export default {
    name: 'TreeViewPersonalTask',
    props: {
        tasks: {
            type: Array,
            required: true
        },
        getStatuses: {
            type: Array,
            required: true
        },
        header: {
            type: Array,
            required: true
        }
    },
    components: {
        TaskNavigationComponent
    },
    data: () => ({
        open: [],
        allocated_time: "",
        elapsedTimes: {},
        intervalId: null,
        manual_timer_boolean: false,
        manual_time: "",
        openItems: [],
        // to bulk assign
        bulk_assign_user: [],
        selected_users: [],
        assign_more: false,
        task_details_dialog: false,
        can_close_task_details_dialog: false,
        original_selected_task: {},
    }),
    mounted() {
        this.openItems = this.tasks.map(task => task.status_id);
        this.startAutoUpdate();
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
    computed: {
        ...mapGetters({
            get_companies:      'companies/get_companies',
            get_priorities:     'tasks/get_priorities',
            get_statuses:       'tasks/get_statuses',
            users:              'tasks/get_users',
            get_selected_task:  'tasks/get_selected_task',
        }),
        allStatusIds() {
            return this.tasks.map(task => task.status_id);
        },
        totalAllocatedTime() {
            if (!this.open.length) return "00:00:00";
            const lastStatusId = this.open[this.open.length - 1];
            const group = this.tasks.find(t => t.status_id === lastStatusId);
            return group ? group.total_allocated_time : "00:00:00";
        },
        isScreen1368() {
            return window.innerWidth === 1368;
        }
    },
    methods: {
        getRandomColor() {
            const colors = ["blue", "green", "red", "pink"];
            return colors[Math.floor(Math.random() * colors.length)];
        },
        getTasks() {
            this.$emit('getTasks')
        },
        openTaskDetailsDialog(item) {
            this.task_details_dialog = true
            this.$store.dispatch('tasks/set_selected_task', item)
            this.original_selected_task = { ...item }
            setTimeout(() => {
                this.can_close_task_details_dialog = true
            }, 100)
        },
        async updateTask(item) {
            let payload = {
                company_id: item.company_id,
                assignee_id: item.assignee_id,
                project_id: item.project_id,
                name: item.name,
                status_id: item.status_id,
                description: item.description,
                priority_id: item.priority_id,
                due_date: item.due_date,
                allocated_time: item.allocated_time,
            }
            await this.$axios.post(`api/tasks/update_task/${item.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getTasks()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        closeTaskDetailsDialog() {
            this.task_details_dialog = false
            let selected_task = {
                name: this.get_selected_task.name,
                company_id: this.get_selected_task.company_id,
                project_id: this.get_selected_task.project_id,
                priority_id: this.get_selected_task.priority_id,
                status_id: this.get_selected_task.status_id,
                due_date: this.get_selected_task.due_date,
                // allocated_time: this.get_selected_task.allocated_time,
            }
            let original_task = {
                name: this.original_selected_task.name,
                company_id: this.original_selected_task.company_id,
                project_id: this.original_selected_task.project_id,
                priority_id: this.original_selected_task.priority_id,
                status_id: this.original_selected_task.status_id,
                due_date: this.original_selected_task.due_date,
                // allocated_time: this.original_selected_task.allocated_time,
            }
            console.log(JSON.stringify(selected_task))
            console.log(JSON.stringify(original_task))
            if (JSON.stringify(selected_task) != JSON.stringify(original_task)) {
                console.log('yes')
                this.updateTask(this.get_selected_task)
            }
            this.$store.dispatch('tasks/set_selected_task', {})
            this.original_selected_task = {}
        },
        bulkUpdateUser(item, checked) {
            if(checked === null){
                const index = this.bulk_assign_user.findIndex(user => user.id === item.id);
                this.bulk_assign_user.splice(index, 1)
                return
            }
            const exists = this.bulk_assign_user.some(user => user.id === item.id);
            if(!exists){
                this.bulk_assign_user.push(item)
                return
            }
        },
        // status
        async removeStatus(){
            try{
                for(const q of this.bulk_assign_user){
                    await this.$axios.patch(`api/tasks/update_a_task/${q.id}`, {
                        status_id: null
                    })
                }
                // this.getTasks();
                await dashboardService.getMyTasks()
            }catch(error){
                console.log("removing bulk tasks status", error)
            }
        },
        async changeBulkStatus(item){
            try{
                for(const q of this.bulk_assign_user){
                    await this.$axios.patch(`api/tasks/update_a_task/${q.id}`, {
                        status_id:  item.id
                    })
                }
                // this.getTasks();
                await dashboardService.getMyTasks()
            }catch(error){
                console.log("updating bulk tasks - status", error)
            }
        },
        // company
        async removeCompany(){
            try{
                for(const q of this.bulk_assign_user){
                    await this.$axios.patch(`api/tasks/update_a_task/${q.id}`,{
                        company_id: null
                    })
                }
                // this.getTasks();
                await dashboardService.getMyTasks()
            }catch(error){
                console.log("updating bulk tasks - remove company", error)
            }
        },
        async changeCompany(item){
            try{
                for(const q of this.bulk_assign_user){
                    await this.$axios.patch(`api/tasks/update_a_task/${q.id}`, {
                        company_id:  item.id
                    })
                }
                // this.getTasks();
                await dashboardService.getMyTasks()
            }catch(error){
                console.log("updating bulk tasks - company", error)
            }
        },
        // priority
        async changePriority(item){
            try{
                for(const q of this.bulk_assign_user){
                    await this.$axios.patch(`api/tasks/update_a_task/${q.id}`, {
                        priority_id:  item.id
                    })
                }
                // this.getTasks();
                await dashboardService.getMyTasks()
            }catch(error){
                console.log("updating bulk tasks - priority", error)
            }
        },
        async removePriority(){
            try{
                for(const q of this.bulk_assign_user){
                    await this.$axios.patch(`api/tasks/update_a_task/${q.id}`, {
                        priority_id:  null
                    })
                }
                // this.getTasks();
                await dashboardService.getMyTasks()
            }catch(error){
                console.log("removing priority as bulk in tasks", error)
            }
        },
        // assignees
        async removeAssignee(){
            try{
                for(const q of this.bulk_assign_user){
                    await this.$axios.delete(`api/tasks/unassign_all_user/${q.id}`)
                }
                // this.getTasks();
                await dashboardService.getMyTasks()
            }catch(error){
                console.log("removing assignee as bulk in tasks", error)
            }
        },
        async addUser(user){
            try{
                for(const q of this.bulk_assign_user){
                    await this.$axios.patch(`api/tasks/assign_user/${q.id}`, {
                        user_id: user.id
                    })
                }
                // this.getTasks();
                await dashboardService.getMyTasks()
            }catch(error){
                console.log("removing assignee as bulk in tasks", error)
            }
        },
        // delete task
        async deleteATask(){
            try{
                for(const q of this.bulk_assign_user){
                    await this.$axios.delete(`api/tasks/delete_a_task/${q.id}`)
                }
                // this.getTasks();
                await dashboardService.getMyTasks()
            }catch(error){
                console.log("removing assignee as bulk in tasks", error)
            }
        },
        toggleItem(status_id) {
            this.open = this.open[0] === status_id ? [] : [status_id]; 
        },
        async save(record) {
            const formattedTime = this.appendSeconds(this.allocated_time);
            try {
                await TimeTrackService.updateAllocatedTime({ allocated_time: formattedTime }, record.id);
            } catch (error) {
                console.error("Error saving allocated time - component level", error);
            }
        },
        appendSeconds(timeString) {
            return timeString.includes(":") && timeString.split(":").length === 2 
                ? `${timeString}:00` 
                : timeString;
        },
        async startTimer(data) {
            event.stopPropagation();
            this.$nextTick(() => {
                const dialogRef = this.$refs["editDialog" + data.id];
                if (dialogRef) {
                    dialogRef.isActive = false;
                }
            });
            try {
                await TimeTrackService.startTimer({ task_id: data.id });
            } catch (error) {
                console.error('Error starting task timer', error);
            }
        },
        startAutoUpdate() {
            clearInterval(this.intervalId);
            this.intervalId = setInterval(() => {
                this.tasks.forEach(group => {
                    group.tasks.forEach(task => {
                        if (task.task_timer?.created_at) {
                            const createdAt = new Date(task.task_timer.created_at).getTime();
                            const diff = Math.floor((Date.now() - createdAt) / 1000);
                            this.$set(this.elapsedTimes, task.task_timer.id, this.formatTime(diff));
                        }
                    });
                });
            }, 1000); // Update every second
        },
        formatTime(seconds) {
            const hours = String(Math.floor(seconds / 3600)).padStart(2, "0");
            const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
            const secs = String(seconds % 60).padStart(2, "0");
            return `${hours}:${minutes}:${secs}`;
        },
        async stopTimer(item){
            const time = this.elapsedTimes[item.id]
            try{
                const tp = {
                    is_active: 0,
                    time:   time
                }
                await TimeTrackService.stopTimer(item.id, tp)
            }catch(error){
                console.log("error on stoping timer - component layer", error)
            }
        },
        addManualTimer(item){
            TimeTrackService.toAddManualTime(item)
            this.manual_timer_boolean = true
        },
        formatTimeManual(){
            let time = this.manual_time.replace(/\D/g, "");

            if (time.length > 6) {
                time = time.substring(0, 6);
            }

            this.manual_time = `${time.substring(0, 2)}:${time.substring(2, 4)}:${time.substring(4, 6)}`;
        },
        allowOnlyNumbers(event) {
            if (!/^\d$/.test(event.key)) {
                event.preventDefault();
            }

            if (this.manual_time.replace(/\D/g, "").length >= 6) {
                event.preventDefault();
            }

            // if (this.allocated_time.replace(/\D/g, "").length >= 6) {
            //     event.preventDefault();
            // }
        },
        formatTimeManualAllocated(){
            console.log('went here')
            let time = this.allocated_time.replace(/\D/g, "");

            if (time.length > 6) {
                time = time.substring(0, 6);
            }

            this.allocated_time = `${time.substring(0, 2)}:${time.substring(2, 4)}:${time.substring(4, 6)}`;
        },
        allowOnlyNumbersAllocated(event) {
            if (!/^\d$/.test(event.key)) {
                event.preventDefault();
            }

            if (this.allocated_time.replace(/\D/g, "").length >= 6) {
                event.preventDefault();
            }
        },
        async addManualTimeFn(parent){
            // console.log(parent)
            try{
                const tp = {
                    "is_active":            0,
                    "time":                 this.manual_time,
                    "task_id":              parent.id
                }
                await TimeTrackService.manualTimeInsert(tp)
                this.manual_time = ""
            }catch(error){
                console.log("error on adding manual time - component layer", error)
            }
        }
    },
    watch: {
        tasks: {
            handler(newTasks) {
                this.startAutoUpdate();
                this.openItems = newTasks.map(task => task.status_id);
            },
            deep: true,
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
    cursor: pointer;
}

.tree-item {
    margin-bottom: 24px; 
    padding: 10px;
    border-radius: 8px;
    background-color: #f9f9f9; 
}

.tree-table {
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
    border-radius: 6px;
    background: white;
    margin-bottom: 30px;
}

.tree-table .v-data-table__wrapper td {
    height: 300px !important;
}
.text-wrap {
    white-space: normal !important;
    word-break: break-word;
}

.avatar-stack {
    display: flex;
    position: relative;
}

.stacked-avatar {
    position: relative;
    margin-right: 4px;
}

.tooltip-content {
    display: flex;
    flex-direction: column;
    text-align: left;
}
</style>