<template>
    <V-container>
        <v-row
            justify="center"
        >
            <v-dialog
                v-model="localViewStatusLogsModel"
                persistent
                :max-width="dialogWidth"
            >
                <v-card elevation="0" style="height: 80vh">
                    <v-card-title class="py-3">
                        <small>Status of {{ getViewPersonalGoal?.goal_title ?? "no title" }}</small>
                        <v-spacer/>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    class="text-none mr-2"
                                    outlined
                                >
                                    Set status
                                </v-btn>
                            </template>
                            <v-col style="background-color: white;">
                                <small style="font-size: .8rem; font-weight: 500;">Open</small>
                                <v-chip 
                                    v-for="(open, openIndex) in getGoalStatusesOpen"
                                    :key="open.id || openIndex" 
                                    small 
                                    class="mt-2"
                                    @click.stop="addStatus(open)"
                                    style="display: block;"
                                    label
                                >
                                    <v-icon :style="{ color: open.color }">mdi-circle-medium</v-icon>{{ open.name ?? "open" }}
                                </v-chip>
                                <v-divider/>
                                <small style="font-size: .8rem; font-weight: 500;">Close</small>
                                <v-chip 
                                    v-for="(close, closeIndex) in getGoalStatusesClose"
                                    :key="close.id || closeIndex" 
                                    small 
                                    class="mt-2"
                                    @click.stop="addStatus(close)"
                                    style="display: block;"
                                    label
                                >
                                    <v-icon :style="{ color: close.color }">mdi-circle-medium</v-icon>{{ close.name ?? "close" }}
                                </v-chip>
                            </v-col>
                        </v-menu>
                        <v-icon
                            @click="$emit('update:view_status_logs_model', false)"
                        >
                            mdi-close
                        </v-icon>
                    </v-card-title>
                    <v-divider style="border-width: 1px; background-color: f9f9f9;"/>
                    <v-card-text class="pa-0 ma-0">
                        <v-row>
                            <v-col
                                :cols="leftColSize"
                                style="overflow-x: hidden; height: 70vh; overflow-y: auto; scroll-behavior: smooth; padding-right: 8px;"
                                class="custom-scroll"
                            >
                                <v-list flat>
                                    <v-list-item
                                        v-for="(statusLogs, statusLogsIndex) in getViewPersonalGoal.status_logs"
                                        :key="statusLogs.id || statusLogsIndex"
                                        style="cursor: pointer; border-bottom: 1px solid #f1f1f1; border-top: 1px solid #f1f1f1;"
                                        @click.stop="viewGoalStatusLog(statusLogs)"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <small style="font-weight: 700; font-size: 0.8rem;">Status update</small>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <v-chip
                                                    small
                                                    dark
                                                    :color="statusLogs?.status?.color"
                                                >
                                                    {{ statusLogs?.status.name ?? "no status" }}
                                                </v-chip>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-list-item-action-text>{{ $date(statusLogs.created_at).format('MMM D') }} </v-list-item-action-text>
                                            <small style="font-weight: 400; font-size: 0.7rem;">{{ formatDate(statusLogs.created_at) }}</small>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                            <v-col 
                                :cols="rightColSize"
                                style="height: 55vh; overflow-y: auto; scroll-behavior: smooth; padding-right: 8px;"
                                class="custom-scroll"
                            >
                                <v-card elevation="0" no-gutter>
                                    <v-card-title class="ma-0">
                                        <small style="font-weight: 700; font-size: 1rem;">
                                            Status update 
                                            <v-icon style="color: black;">mdi-circle-small</v-icon>
                                            {{ $date(getViewGoalStatusLog.created_at).format('MMM D') }}
                                        </small>
                                    </v-card-title>
                                    <v-card-subtitle class="pa-0">
                                        <v-list flat class="pa-0 ma-0 d-flex justify-start">
                                            <v-list-item v-if="Object.keys(getViewGoalStatusLog).length > 0">
                                                <v-list-item-avatar>
                                                    <v-avatar color="blue" size="36">
                                                        <label style="font-size: 0.9rem;" class="white--text">
                                                        {{ getViewGoalStatusLog.user.name[0] || "N" }}
                                                        </label>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <small style="font-weight: 700;">
                                                            {{ getViewGoalStatusLog.user.name || "No Name" }}
                                                        </small>
                                                        <v-icon style="color: black;">mdi-circle-small</v-icon>
                                                        <small style="font-weight: 400;">
                                                            {{ $date(getViewGoalStatusLog.created_at).fromNow() }}
                                                        </small>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-subtitle>
                                    <v-card-text class="mt-5">
                                        <v-row>
                                            <v-col
                                                cols="2"
                                            >
                                                <small style="font-weight: 400; font-size: 0.9rem;">Status</small>
                                            </v-col>
                                            <v-col
                                                cols="10"
                                            >
                                                <v-chip
                                                    label
                                                    style="font-size: 0.9rem; font-weight: 700;"
                                                    v-if="Object.keys(getViewGoalStatusLog).length > 0"
                                                >
                                                    <v-icon :style="{ color: getViewGoalStatusLog?.status?.color || 'black' }">
                                                        mdi-circle-small
                                                    </v-icon>
                                                    {{ getViewGoalStatusLog.status.name || "no status" }}
                                                </v-chip>
                                            </v-col>
                                            <v-col
                                                cols="2"
                                            >
                                                <small style="font-weight: 400; font-size: 0.9rem;">Goal</small>
                                            </v-col>
                                            <v-col
                                                cols="10"
                                            >
                                                <small
                                                    style="font-size: 0.9rem; font-weight: 700;"
                                                >
                                                    <v-icon>mdi-target</v-icon>
                                                    {{ getViewPersonalGoal.goal_title ?? "no title" }}
                                                </small>
                                            </v-col>
                                            <v-col
                                                cols="2"
                                            >
                                                <small style="font-weight: 400; font-size: 0.9rem;">Progress</small>
                                            </v-col>
                                            <v-col
                                                cols="10"
                                            >
                                                <small
                                                    style="font-size: 0.9rem; font-weight: 700;"
                                                >
                                                    WIP (Work in progress)
                                                </small>
                                            </v-col>
                                            <v-col
                                                cols="2"
                                            >
                                                <small style="font-weight: 400; font-size: 0.9rem;">Owner</small>
                                            </v-col>
                                            <v-col
                                                cols="10"
                                            >
                                                <small
                                                    style="font-weight: 700; font-size: 0.9rem;"
                                                    v-if="Object.keys(getViewPersonalGoal).length > 0"
                                                >
                                                    {{ getViewPersonalGoal.owner?.name || "no name" }}
                                                </small>
                                            </v-col>
                                            <v-col
                                                cols="2"
                                            >
                                                <small style="font-weight: 400; font-size: 0.9rem;">Time Period</small>
                                            </v-col>
                                            <v-col
                                                cols="10"
                                            >
                                                <small
                                                    style="font-size: 0.9rem; font-weight: 700;"
                                                >
                                                    WIP (Work in progress)
                                                </small>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                                <v-list flat>
                                    <v-divider style="border-width: 1px; background-color: f9f9f9;"/>
                                    <v-list-item
                                        v-for="(comment, commentIndex) in getViewGoalStatusLog.comments"
                                        :key="comment.id || commentIndex"
                                    >
                                        <v-list-item-avatar>
                                            <v-avatar color="blue" size="36">
                                                <label style="font-size: 0.9rem;" class="white--text">{{ comment.user.name[0] ?? "N" }}</label>
                                            </v-avatar>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <small style="font-weight: 700; font-size: 0.8rem;">{{ comment.user.name }}</small>
                                                <v-icon style="color: black;">mdi-circle-small</v-icon>
                                                <small style="font-weight: 400;">
                                                    {{ $date(comment.created_at).fromNow() }}
                                                </small>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <small style="font-weight: 600; font-size: 0.8rem;">
                                                    {{ comment.comment }}
                                                </small>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <v-list v-if="Object.keys(getViewPersonalGoal).length > 0" flat style="position: absolute; bottom: 0; width: 36vw;">
                                    <v-divider style="border-width: 1px; background-color: f9f9f9;"/>
                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-avatar color="blue" size="36">
                                                <label style="font-size: 0.9rem;" class="white--text">{{ getViewPersonalGoal.owner.name[0] ?? "N" }}</label>
                                            </v-avatar>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title 
                                                class="ml-2"
                                            >
                                                <wysiwyg v-model="comment"/>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-btn 
                                        class="float-right mr-3 text-none"
                                        color="primary"
                                        @click.stop="addStatusLogComment"
                                    >
                                        Send
                                    </v-btn>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </V-container>
</template>

<script>

    /**service */
    import goalService from '@/service/goal.service'

    /**vuex */
    import { mapGetters } from 'vuex';

    /**dayjs */
    import dayjs from "dayjs";
    import relativeTime from "dayjs/plugin/relativeTime";
    import isToday from "dayjs/plugin/isToday";
    import isYesterday from "dayjs/plugin/isYesterday";

    dayjs.extend(relativeTime);
    dayjs.extend(isToday);
    dayjs.extend(isYesterday);

    export default{
        props: {
            getViewPersonalGoal: {
                type: Object,
                required: true,
            },
            getGoalStatusesOpen: {
                type: Array,
                required: true
            },
            getGoalStatusesClose: {
                type: Array,
                required: true
            },  
            view_status_logs_model: {
                type: Boolean,
                default: false,
            }
        },
        data: () => ({
            comment: null,
        }),
        computed: {
            ...mapGetters({
                getViewGoalStatusLog: 'goal/getViewGoalStatusLog'
            }),
            localViewStatusLogsModel: {
                get() {
                    return this.view_status_logs_model;
                },
                set(value) {
                    this.$emit("update:view_status_logs_model", value);
                }
            },
            dialogWidth() {
                if (this.$vuetify.breakpoint.xs) return '90vw';
                if (this.$vuetify.breakpoint.sm) return '70vw';
                if (this.$vuetify.breakpoint.md) return '60vw';
                return '50vw';
            },
            rightColSize() {
                if (this.$vuetify.breakpoint.xs) return 12;
                if (this.$vuetify.breakpoint.sm) return 10;
                if (this.$vuetify.breakpoint.md) return 9;
                return 9;
            },
            leftColSize(){
                if (this.$vuetify.breakpoint.xs) return 12;
                if (this.$vuetify.breakpoint.sm) return 2;
                if (this.$vuetify.breakpoint.md) return 3;
                return 3;
            }
        },
        methods: {
            addStatus(item){
                try{
                    const tp = {
                        status_id:  item.id,
                        goal_id:    this.getViewPersonalGoal.id
                    }
                    goalService.addStatus(tp)
                }catch(error){
                    console.log("error on updating status - component level personal goal card", error)
                }
            },
            formatDate(date) {
                const d = dayjs(date);
                if (d.isToday()) return "Today";
                if (d.isYesterday()) return "Yesterday";
                return d.fromNow();
            },
            viewGoalStatusLog(item){
                goalService.toViewStatusLog(item)
            },
            async addStatusLogComment(){
                try{
                    const tp = {
                        goal_status_log_id: this.getViewGoalStatusLog.id,
                        comment: this.comment
                    }
                    await goalService.addStatusLogComment(tp)
                    this.comment = null
                }catch(error){
                    console.log("error on adding status log comment - component layer", error)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.custom-scroll::-webkit-scrollbar {
  width: 6px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: transparent;
}
</style>