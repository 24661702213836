<template>
    <v-container fluid>
        <v-sheet
            color="dark"
            class="pa-3"
            v-if="get_loading"
        >
            <v-skeleton-loader
                class="mx-auto"
                max-width="100vw"
                type="card"
            ></v-skeleton-loader>
        </v-sheet>
        <v-row v-else>
            <v-col cols="12" sm="12">
                <v-card elevation="0" outlined class="w-100">
                    <v-card-title class="d-flex align-center">
                        <label>Departments of: <v-chip>{{ get_company.name }}</v-chip></label>
                        <v-spacer></v-spacer>
                        <v-btn depressed color="primary" @click.stop="add_folder = true">
                            <v-icon class="mr-1">mdi-plus</v-icon>
                            Add Department
                        </v-btn>
                    </v-card-title>
                    <v-divider />
                    <v-card-text class="md-5">
                        <v-toolbar
                            color="dark"
                            dark
                            flat
                        >
                            <v-tabs
                                v-model="tab"
                                align-with-title
                                show-arrows
                            >
                                <v-tabs-slider color="yellow"></v-tabs-slider>
                                <v-tab
                                    v-for="(item, index) in get_folders"
                                    :key="item.id || index"
                                >
                                    {{ item.folder_name }}
                                </v-tab>
                            </v-tabs>
                        </v-toolbar>
                        <v-tabs-items v-model="tab" class="scrollable-tabs">
                            <v-tab-item
                                v-for="(item, index) in get_folders"
                                :key="item.id || index"
                            >
                                <v-btn
                                    color="secondary"
                                    class="mt-2"
                                    @click.stop="content = false"
                                    outlined
                                    style="color: #1976d2 !important;"
                                >
                                    <v-icon class="mr-1">mdi-plus</v-icon>
                                    add page
                                </v-btn>
                                <v-card 
                                    flat
                                    v-for="(iitem, iindex) in item.files"
                                    :key="iindex"
                                    elevation="0"
                                    outlined
                                    class="my-5 py-4"
                                    v-show="content && !edit_page_mode"
                                >
                                    <v-card-title class="py-4">
                                        <small>{{iitem.id}}.) {{ iitem.name }}</small>
                                        <v-spacer/>
                                        <v-btn icon @click.stop="deletePage(item)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                        <v-btn icon @click.stop="editPage(iitem)">
                                            <v-icon class="mr-2" >mdi-pen</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-divider class="mb-3"/>
                                    <v-card-text class="pa-4">
                                        <label v-html="iitem.file_location"></label>
                                    </v-card-text>
                                </v-card>
                                <!-- add page -->
                                <v-card
                                    class="py-4"
                                    v-show="!content"
                                >
                                    <v-divider/>
                                    <v-card-text class="mt-5">
                                        <v-row>
                                            <v-col
                                                cols="12"
                                            >
                                                <label>Title</label>
                                                <v-text-field v-model="name" outlined dense></v-text-field>
                                                <label>Content</label>
                                                <wysiwyg v-model="file_location"/>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="content = true; file.name = null; file.file = null;" outlined>Cancel</v-btn>
                                        <v-btn @click="addFile(item)" color="primary">create</v-btn>
                                    </v-card-actions>
                                </v-card>
                                <!-- edit page-->
                                <v-card
                                    v-show="edit_page_mode"
                                    class="py-4"
                                >
                                    <v-divider/>
                                    <v-card-text class="mt-5">
                                        <v-row>
                                            <v-col
                                                cols="12"
                                            >
                                                <label>Title</label>
                                                <v-text-field v-model="get_edit_page.name" outlined dense></v-text-field>
                                                <label>Content</label>
                                                <wysiwyg v-model="get_edit_page.file_location"/>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="edit_page_mode = false" outlined>Cancel</v-btn>
                                        <v-btn @click="updateFile(get_edit_page)" color="primary">Update</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- add folder modal -->
        <v-dialog
            v-model="add_folder"
            persistent
            max-width="500"
        >
            <v-card
                class="py-4"
            >
                <v-card-title>
                    <small>Add Department</small>
                    <v-spacer/>
                    <v-btn
                        icon
                        @click.stop="add_folder = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <v-row>
                        <v-col
                            cols="12"
                            class="mt-5"
                        >
                            <label>Department</label>
                            <v-text-field v-model="folder_name" outlined dense @keydown.enter="addFolder"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="add_folder = false; folder_name = null" outlined>Cancel</v-btn>
                    <v-btn @click="addFolder" color="primary">create</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- delete a page -->
        <v-dialog
            v-model="to_delete_mode"
            persistent
            max-width="290"
        >
            <v-card>
                <v-card-title>
                    <small>You are about to delete a page</small>
                </v-card-title>
                <v-card-text>Are you sure about this?</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="to_delete_mode = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="red"
                    dark
                    @click.stop="deletePageProceed"
                >
                    Delete
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>

    </v-container>
</template>

<script>
    /**getters */
    import { mapGetters } from 'vuex'

    /**service */
    import documentService from '@/service/document.service';

    export default {
        name: 'DocumentComponent',
        data: () => ({
            add_folder: false,
            folder_name: null,
            add_file: false,
            selected_card: null,
            file: {
                file: null,
                name: null
            },
            file_location: null,
            name: null,
            tab: null,
            content: true,
            edit_page_mode: false,
            to_delete_mode: false
        }),
        created() {},
        async mounted() {
            document.title = "DG Tasks - Document & SOP";
            await documentService.getFolders()
        },
        computed: {
            ...mapGetters({
                get_folders:    'document/get_folders',
                get_loading:    'document/get_loading',
                get_company:    'companies/get_selected_company',
                get_edit_page:  'document/get_edit_page'
            })
        },
        methods: {
            showSnackBar(message) {
                this.$store.commit("auth/setMessage",
                    { show: true, message: message },
                    { root: 1 })
            },
            async addFolder(){
                try{
                    const tp = {
                        company_id: this.get_company.id,
                        folder_name: this.folder_name
                    }
                    await documentService.addFolder(tp)
                    this.add_folder = false
                    this.folder_name = null
                    this.showSnackBar("adding department, successful!")
                }catch(error){
                    console.log('error on adding folder - component layer', error)
                }
            },
            addFileTrigger(id){
                this.selected_card = id;
                this.add_file = true
            },
            handleFileSelect(file) {
                if (file) {
                    this.file.file = file;
                    console.log("Selected file:", file);
                } else {
                    console.error("No file selected");
                }
            },
            async addFile(item){
                try{
                    const tp = {
                        document_id: item.id,
                        file_location: this.file_location,
                        name: this.name
                    }
                    await documentService.addFile(tp)
                    this.content = true
                    this.file_location = null;
                    this.name = null;
                    this.showSnackBar("adding page, successful!")
                }catch(error){
                    console.log('error on uploading file - component level', error)
                }
            },
            deletePage(item){
                documentService.setEditPage(item)
                this.to_delete_mode = true
            },
            async deletePageProceed(){
                try{
                    await documentService.deletePage(this.get_edit_page.id)
                    await documentService.getFolders()
                    this.to_delete_mode = false
                }catch(error){
                    console.log("error on delete page - component layer", error)
                }
            },
            editPage(item){
                documentService.setEditPage(item)
                this.edit_page_mode = true
            },
            async updateFile(item){
                try{
                    const tp = {
                        name:           item.name,
                        file_location:  item.file_location
                    }
                    await documentService.updateFile(tp, item.id)
                    await documentService.getFolders()
                    this.edit_page_mode = false
                }catch(error){
                    console.log('error on updating page - component layer', error)
                }
            }
        },
        watch: {
        }
    }
</script>

<style scoped>
.scrollable-list {
    max-height: 300px;
    overflow-y: auto;
}
.file-list {
    max-height: 300px;
    overflow-y: auto;
    border-radius: 8px;
}

.file-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px 16px;
}

.file-list-item:last-child {
    border-bottom: none;
}

.scrollable-tabs {
    max-height: 85vh;
    overflow-y: auto;
}
</style>