export default {
    namespaced: true,
    state: {
        companies: [],
        selected_company: {},
        notifications: []
    },
    mutations: {
        set_companies(state, payload) {
            state.companies = [...payload]
        },
        set_selected_company(state, payload) {
            state.selected_company = payload
        },
        set_notification(state, payload){
            state.notifications = [...payload]
        }
    },
    getters: {
        get_companies: state => state.companies,
        get_selected_company: state => state.selected_company,
        get_notification: state => state.notifications
    },
    actions: {
        set_companies({ commit }, payload) {
            commit('set_companies', payload)
        },
        set_selected_company({ commit }, payload) {
            commit('set_selected_company', payload)
        },
        set_notification({commit}, payload){
            commit('set_notification', payload)
        }
    }
}