import apiClient from "@/api/apiClient"

/**store */
import store from "@/plugins/vuex"

export default {
    /**
     * get my tasks
     * @params
     * tags?: object
     */
    async getMyTasks(tags, selected_assign_filters, meeting_id = null, project_id = null){
        try{
            console.log(meeting_id, project_id)
            const user = store.getters["auth/get_user"]
            const selectedUser = store.getters["tasks/getUserId"]
            if(Object.keys(selectedUser).length == 0){
                store.commit('tasks/SET_USER_ID', user)
            }
            const company = store.getters["companies/get_selected_company"];
            const statuses = store.getters["tasks/get_statuses"];
            //console.log('edgar', selectedUser)
            const response = await apiClient.get('api/tasks/get_my_tasks', {
                params: {
                    // user_id:                    user.id,
                    // assignee_id:                user.id,
                    user_id:                    Object.keys(selectedUser).length > 0 ? selectedUser.id : user.id,
                    assignee_id:                Object.keys(selectedUser).length > 0 ? selectedUser.id : user.id,
                    company_id:                 company.id,
                    selected_assign_filter:     'All',
                    tags:                       tags
                }
            })
            const { data } = response            
            
            /**for overall data */            
            const filteredAssignedTasks = data.assigned_tasks.filter(
                t => {
                    if (selected_assign_filters.statuses.includes(t.status_id) && selected_assign_filters.priorities.includes(t.priority_id)) {                        
                        if ( meeting_id != null ) {
                            return t.meeting_id == meeting_id
                        } else if ( project_id != null ) {
                            return t.project_id == project_id
                        } else {
                            return t
                        }
                    }
                })
            
            const groupedAssignedTasks = filteredAssignedTasks.reduce((acc, task) => {
                // Find the group by company_id
                const group = acc.find(g => g.company_id === task.company_id);
            
                if (group) {
                    group.tasks.push(task);
                    group.total_allocated_time = this.sumTime(group.total_allocated_time || "00:00:00", task.allocated_time);
                } else {
                    acc.push({ 
                        company_id: task.company_id,
                        name: task.company?.name ?? "no company",
                        tasks: [task], 
                        total_allocated_time: task.allocated_time
                    });
                }
            
                return acc;
            }, []);
            store.commit("timetrack/SET_ASSIGNED_TASKS", groupedAssignedTasks)
            store.commit("tasks/SET_ASSIGNED_TASKS", groupedAssignedTasks)

            const filteredPersonalTasks = data.personal_tasks.filter(
                t => {
                    if (selected_assign_filters.statuses.includes(t.status_id) && selected_assign_filters.priorities.includes(t.priority_id)) {                        
                        if ( meeting_id != null ) {
                            return t.meeting_id == meeting_id
                        } else if ( project_id != null ) {
                            return t.project_id == project_id
                        } else {
                            return t
                        }
                    }
                });

            const groupedPersonalTasks = filteredPersonalTasks.reduce((acc, task) => {
                let group = acc.find(g => g.status_id === task.status_id);

                if (group) {
                    group.tasks.push(task);
                    group.total_allocated_time = this.sumTime(group.total_allocated_time || "00:00:00", task.allocated_time);
                } else {
                    const status = statuses.find(s => s.id === task.status_id) || {};
                    acc.push({ 
                        status_id: task.status_id, 
                        name: status.name || "(Unknown Status)", 
                        tasks: [task],
                        total_allocated_time: task.allocated_time
                    });
                }
                return acc;
            }, []);

            store.commit("timetrack/SET_PERSONAL_TASKS", groupedPersonalTasks)
            store.commit("tasks/SET_PERSONAL_TASKS", groupedPersonalTasks)
            /**end for invoice logs */
            // console.log(JSON.stringify(groupedPersonalTasks, null, 2));
        }catch(error){
            console.log('error fetching get my tasks - service layer', error)
        }
    },

    sumTime(time1, time2) {
        const [h1, m1, s1] = time1.split(":").map(Number);
        const [h2, m2, s2] = time2.split(":").map(Number);
        
        let totalSeconds = (h1 * 3600 + m1 * 60 + s1) + (h2 * 3600 + m2 * 60 + s2);
        
        const hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    },

    /**
     * get notifications
     * @params
     * - payload?: object
     */
    async getNotifications(payload){
        try{
            const response = await apiClient.get('api/notifications/get_notifications', {
                params: payload
            });
            const { data } = response.data
            
            const unreadCount = data.filter(item => item.is_read === 0).length;
            store.commit('tasks/set_unread_tasks', unreadCount)
            
            store.commit('companies/set_notification', data)
            return data
        }catch(error){
            console.log('error on fetching notifications - service layer', error)
        }
    },

    /**
     * set task to open right side of notification in inbox
     * @params
     * - item?: object
     */
    task(item){
        store.commit('tasks/set_selected_task', item)
    },

    /**
     * mark notitification as read
     * @params
     * -record_id?: int
     */
    async markAsRead(record_id){
        try{
            const user = store.getters['auth/get_user'];
            await apiClient.patch(`api/notifications/mark_read/${record_id}`)
            const payload = {
                receiver_id: user.id,
            }
            this.getNotifications(payload)
        }catch(error){
            console.log("error on marking a notification as read - service layer", error)
        }
    },

    /**
     * set parent task data
     * @params
     * item?: object
     */
    parentTaskData(item){
        store.commit("tasks/SET_PARENT_TASK", item)
    },

    /**
     * set user from dropdown
     * @params
     * id
     */
    setUserId(id){
        store.commit('tasks/SET_USER_ID', id)
    }
}