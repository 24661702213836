import { render, staticRenderFns } from "./ViewProjectComponent.vue?vue&type=template&id=1e0d5f81&scoped=true"
import script from "./ViewProjectComponent.vue?vue&type=script&lang=js"
export * from "./ViewProjectComponent.vue?vue&type=script&lang=js"
import style0 from "./ViewProjectComponent.vue?vue&type=style&index=0&id=1e0d5f81&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e0d5f81",
  null
  
)

export default component.exports