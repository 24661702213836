<template>
    <v-container class="mt-10" fluid>
        <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
        <v-row v-else-if="meeting">
            <v-col cols="12">
                <v-card elevation="0" outlined>
                    <v-card-title>
                        {{ meeting?.name ?? "no meeting name" }}
                        <v-spacer></v-spacer>
                        <v-btn @click="editMeeting()" v-if="meeting.user_id == get_user.id || get_user.role_id < 3"
                            color="primary">
                            <v-icon class="mr-2">mdi-pen</v-icon>
                            <label v-if="!edit_meeting" style="cursor:pointer;">Edit</label>
                            <label v-else style="cursor:pointer;">Save Changes</label>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="3">
                                Date: {{ $date(meeting.created_at).format('M/DD/YYYY') }}
                            </v-col>
                            <v-col cols="3">
                                Week No: {{ $date(meeting.created_at).week() }}
                            </v-col>
                        </v-row>
                        <br>
                        <h4>Who's in this meeting</h4>
                        <label v-for="(access, index) in meeting.access" :key="index" class="access-label">
                            {{ access.user?.name ?? "no access name" }}
                            <v-btn @click="deleteMeetingAccess(access)" v-if="edit_meeting" icon small>
                                <v-icon small>mdi-close</v-icon>
                            </v-btn>
                        </label>
                        <v-select v-model="users_to_add" v-if="edit_meeting" multiple :items="selectable_users"
                            item-text="name" item-value="id" outlined dense style="max-width:25%;"
                            label="Add Users to Meeting" class="mt-2"></v-select>
                        <hr class="my-5" color="lightgrey">
                        <h4>Meeting Agenda</h4>
                        <wysiwyg v-model="meeting.agenda" v-if="edit_meeting" />
                        <div v-html="meeting.agenda" v-else></div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card elevation="0" outlined>
                    <v-card-title>
                        Tasks for this meeting
                        <!--<v-btn @click="openShowFiltersDialog()" text small class="ml-5">
                            <v-icon>mdi-filter</v-icon>
                            Filters
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="openCreateTaskDialog()" color="primary">
                            <v-icon>mdi-plus</v-icon>
                            Add Task
                        </v-btn>-->
                        <v-row class="ml-5">
                            <!-- priority -->
                            <v-menu
                                open-on-hover
                                bottom
                                offset-y
                                :close-on-content-click="false"
                                content-class="custom-menu"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                        class="ma-2 d-flex justify-center align-center"
                                        text-color="black"
                                        v-bind="attrs"
                                        v-on="on"
                                        style="width: 100px;"
                                    >
                                        <v-icon left style="color: black;">
                                            mdi-priority-high
                                        </v-icon>
                                        <small style="font-weight: 700; font-size: 0.9rem;">Priority</small>
                                    </v-chip>
                                </template>

                                <v-list dense>
                                    <v-list-item v-for="priority in prioritiesSelect" :key="priority.id" class="tight-checkbox">
                                        <v-checkbox
                                            v-model="selected_priorities"
                                            :label="priority.name"
                                            :value="priority.id"
                                            dense
                                            @change="getTasks"
                                            label-class="custom-checkbox-text"
                                        ></v-checkbox>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <!-- status -->
                            <v-menu
                                open-on-hover
                                bottom
                                offset-y
                                :close-on-content-click="false"
                                content-class="custom-menu"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                        class="ma-2 d-flex justify-center align-center"
                                        text-color="black"
                                        v-bind="attrs"
                                        v-on="on"
                                        style="width: 100px;"
                                    >
                                        <v-icon left style="color: black;">
                                            mdi-list-status
                                        </v-icon>
                                        <small style="font-weight: 700; font-size: 0.9rem;">Status</small>
                                    </v-chip>
                                </template>

                                <v-list dense>
                                    <v-list-item v-for="status in statusesSelect" :key="status.id" class="tight-checkbox">
                                        <v-checkbox
                                            v-model="selected_statuses"
                                            :label="status.name"
                                            :value="status.id"
                                            dense
                                            @change="getTasks"
                                            label-class="custom-checkbox-text"
                                        ></v-checkbox>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <!-- users -->
                            <v-menu
                                open-on-hover
                                bottom
                                offset-y
                                :close-on-content-click="false"
                                content-class="custom-menu"
                                v-if="[1,2].includes(get_user.role_id)"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                        class="ma-2 d-flex justify-center align-center"
                                        text-color="black"
                                        v-bind="attrs"
                                        v-on="on"
                                        style="max-width: 400px;"
                                    >
                                        <v-icon left style="color: black;">
                                            mdi-account-outline
                                        </v-icon>
                                        <small style="font-weight: 700; font-size: 0.9rem;">User {{ selectedUserGetters.name }}</small>
                                    </v-chip>
                                </template>

                                <v-list dense style="height: 350px; overflow: auto;">
                                    <v-list-item v-for="(user, userIndex) in getUsersGetters" :key="user.id || userIndex" class="tight-checkbox">
                                        <v-chip
                                            label
                                            small
                                            style="cursor: pointer; font-weight: 700; font-size: .9rem;"
                                            @change="setUser(user)"
                                            @click.stop="setUser(user)"
                                        >
                                            {{ user.name }}
                                        </v-chip>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <!--<TaskTableComponent :task_type="'personal'" :tasks="tasks" :users="users"
                            :meeting_id="meeting_id" :tags="tags" @getTasks="getTasks" @getTags="getTags" />-->
                        <TreeViewDashboardPersonal
                            :tasks="getPersonalTasks"
                            :header="header_personal"
                            :getStatuses="get_statuses"
                            @create-task="openCreateTaskDialog"/>
                            <v-divider class="py-5"/>
                        <TreeViewDashboardAssigned
                            :tasks="getAssignedTasks"
                            :header="header_assigned"
                            :getStatuses="get_statuses"
                            @create-task="openCreateTaskDialog"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="create_task_dialog" persistent max-width="500">
            <v-card v-if="meeting">
                <v-card-title>Create Task</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="new_task.name" label="Task Name *" outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-select v-model="new_task.assignee_id" :items="meeting.access" item-text="user.name"
                                item-value="user_id" outlined dense label="Assign To *"></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-select v-model="new_task.priority_id" :items="get_priorities" item-text="name"
                                item-value="id" label="Priority" outlined dense></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-menu v-model="new_due_date_menu" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="new_task.due_date" label="Due Date" readonly v-bind="attrs"
                                        v-on="on" outlined dense></v-text-field>
                                </template>
                                <v-date-picker v-model="new_task.due_date"
                                    @input="new_due_date_menu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="new_task.description" label="Description" outlined dense></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeCreateTaskDialog()" text>Cancel</v-btn>
                    <v-btn @click="createTask()" color="primary">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="show_filters_dialog" persistent max-width="500">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <v-select v-model="selected_priorities" :items="[{ name: 'None', id: null }, ...get_priorities]"
                        multiple item-text="name" item-value="id" label="Filter by priority"
                        @change="getTasks"></v-select>
                    <v-select v-model="selected_statuses" :items="[{ name: 'None', id: null }, ...get_statuses]"
                        multiple item-text="name" item-value="id" label="Filter by status"
                        @change="getTasks"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeShowFiltersDialog()" text>Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
//import TaskTableComponent from '../modules/TaskTableComponent.vue'
import TreeViewDashboardPersonal from '../modules/TreeViewDashboardPersonal.vue'
import TreeViewDashboardAssigned from '../modules/TreeViewDashboardAssigned.vue'

import dashboardService from '@/service/dashboard.service'

export default {
    name: 'ViewMeetingComponent',
    components: {
        //TaskTableComponent
        TreeViewDashboardPersonal,
        TreeViewDashboardAssigned
    },
    data: () => ({
        loading: false,
        table_loading: false,
        app_url: null,
        api_url: null,
        meeting_id: null,
        meeting: null,
        users: [],
        users_to_add: [],
        selectable_users: [],
        edit_meeting: false,
        selected_assignee: {
            id: null,
            name: null,
            access: [],
        },
        meeting_tasks: [],
        task_headers: [
            { text: 'Created', value: 'created_at', width: '10%', sortable: false },
            { text: 'Task Name', value: 'name', width: '41%', sortable: false },
            { text: 'Assigned To', value: 'name', width: '12%', sortable: false },
            { text: 'Due Date', value: 'due_date', width: '17%', sortable: false },
            { text: 'Priority', value: 'priority_id', width: '14%', sortable: false },
            { text: 'Status', value: 'status_id', width: '17%', sortable: false },
        ],
        header_personal: [
            { text: "Task", value: "name", width: '10%' },
            { text: "Date created", value: "created", width: '5%' },
            { text: "Company", value: "company", width: '5%' },
            { text: "Assignees", value: "assignee", width: '5%' },
            { text: "Status", value: "status", width: "5%"},
            { text: "Priority",  value: "priority", width: '5%'},
            { text: "", value: "view", width: '5%' }
        ],
        header_assigned: [
            { text: "Task", value: "name", width: '10%' },
            { text: "Date created", value: "created", width: '5%' },
            { text: "Company", value: "company", width: '5%' },
            { text: "Assignees", value: "assignee", width: '5%' },
            { text: "Status", value: "status", width: "5%"},
            { text: "Priority",  value: "priority", width: '5%'},
            { text: "", value: "view", width: '5%' }
        ],
        tasks: [],
        due_date_menu: [],
        create_task_dialog: false,
        new_due_date_menu: false,
        new_task: {
            name: null,
            assignee_id: null,
            description: null,
            priority_id: null,
            due_date: null,
        },
        task_details_dialog: false,
        can_close_task_details_dialog: false,
        navigation: {
            width: 600,
            borderSize: 5
        },
        original_selected_task: null,
        create_quick_task: false,
        tags: [],
        show_filters_dialog: false,
    }),
    created() {
        this.app_url = process.env.VUE_APP_URL
        this.api_url = process.env.VUE_APP_API_URL
    },
    mounted() {
        this.loading = true
        this.table_loading = true
        this.selected_assignee.id = this.get_user.id
        this.selected_assignee.name = this.get_user.name
        this.selected_assignee.access = this.get_user.access
        this.getMeeting()
        this.getTags()        
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_selected_company: 'companies/get_selected_company',
            get_companies: 'companies/get_companies',
            get_priorities: 'tasks/get_priorities',
            get_statuses: 'tasks/get_statuses',
            get_selected_priorities: 'tasks/get_selected_priorities',
            get_selected_statuses: 'tasks/get_selected_statuses',
            getPersonalTasks:       "tasks/getPersonalTasks",
            getAssignedTasks:       "tasks/getAssignedTasks",
            getUsersGetters:        'tasks/get_users',
            selectedUserGetters:        "tasks/getUserId"
        }),
        selected_priorities: {
            get() {
                return this.get_selected_priorities
            },
            set(new_priorities) {
                this.$store.dispatch('tasks/set_selected_priorities', new_priorities)
            }
        },
        selected_statuses: {
            get() {
                return this.get_selected_statuses
            },
            set(new_statuses) {
                this.$store.dispatch('tasks/set_selected_statuses', new_statuses)
            }
        },
        prioritiesSelect(){
            return [{ name: 'None', id: null }, ...(this.get_priorities || [])];
        },
        statusesSelect(){
            return [{ name: 'None', id: null }, ...(this.get_statuses || [])]
        }
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async getMeeting() {
            this.meeting_id = parseInt(this.$route.params.id)
            await this.$axios.get(`api/meetings/get_meeting/${this.meeting_id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.meeting = data.data
                        this.getUsers()
                        this.getTasks()
                    }
                })
        },
        async getUsers() {
            await this.$axios.get(`api/users/get_users_by_company/${this.get_selected_company.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.users = JSON.parse(JSON.stringify(data.data))
                        this.selectable_users = data.data
                        if (this.meeting.access) {
                            this.meeting.access.forEach(access => {
                                this.selectable_users.forEach(user => {
                                    if (access.user_id == user.id) {
                                        this.selectable_users.splice(this.selectable_users.indexOf(user), 1)
                                    }
                                })
                            })
                        }
                    }
                })
        },
        editMeeting() {
            this.edit_meeting = !this.edit_meeting
            if (this.edit_meeting == false) {
                this.updateMeeting(this.meeting)
            }
        },
        async updateMeeting(item) {
            let payload = {
                id: item.id,
                name: item.name,
                agenda: item.agenda,
                meeting_status_id: item.meeting_status_id,
                duration: item.duration,
                users: this.users_to_add,
            }
            await this.$axios.post(`api/meetings/update_meeting/${item.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.users_to_add = []
                        this.getMeeting()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        async deleteMeetingAccess(item) {
            await this.$axios.delete(`api/meetings/delete_meeting_access/${item.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getMeeting()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        async getTasks() {
            this.table_loading = true
            /*let payload = {
                meeting_id: this.meeting_id,
            }*/
            const tags= null
            const selectedFilters = {
                priorities: this.selected_priorities,
                statuses: this.selected_statuses
            }

            await dashboardService.getMyTasks(tags, selectedFilters, this.meeting_id)
            this.loading = false
            this.table_loading = false
        },
        async getTags() {
            this.$axios.get('api/tasks/get_tags')
                .then(({ data }) => {
                    if (data.response) {
                        this.tags = data.data
                    }
                })
        },
        openCreateTaskDialog() {
            this.create_task_dialog = true
        },
        closeCreateTaskDialog() {
            this.create_task_dialog = false
            this.new_task.name = null
            this.new_task.description = null
            this.new_task.priority_id = null
            this.new_task.due_date = null
        },
        openShowFiltersDialog() {
            this.show_filters_dialog = true
        },
        closeShowFiltersDialog() {
            this.show_filters_dialog = false
        },
        async createTask() {
            let payload = {
                assignee_id: (this.new_task_type == 'self') ? this.get_user.id : this.selected_assignee.id, // If creating personal task, assign to this user, else assign to selected user
                // company_id: (this.is_company_selected == true) ? this.get_selected_company.id : this.selected_company ? this.selected_company.id : null,
                company_id: this.meeting?.company?.id,
                meeting_id: this.meeting_id,
                ...this.new_task
            }
            await this.$axios.post('api/tasks/create_task', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateTaskDialog()
                        this.getTasks()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        async setUser(user){
            dashboardService.setUserId(user)

            let tags = {}
            if (this.selected_tags != null && this.selected_tags.length > 0) {
                tags = this.selected_tags
            } 

            const selectedFilters = {
                priorities: this.selected_priorities,
                statuses: this.selected_statuses
            }
            await dashboardService.getMyTasks(tags, selectedFilters)
        }
    }
}
</script>

<style lang="scss" scoped>
    :deep(.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr) {
        height: 50px;
    }

    :deep(.v-input__slot::before) {
        border-color: rgb(221, 221, 221) !important;
    }

    :deep(.hide-border>.v-input__control>.v-input__slot:before, .hide-border>.v-input__control>.v-input__slot:after) {
        border: none !important;
    }

    /* Tooltip container */
    .tooltip {
        position: relative;
        display: inline-block;
    }

    /* Tooltip text */
    .tooltip .tooltiptext {
        visibility: hidden;
        min-width: 120px;
        height: 50px;
        align-items: center;
        display: flex;
        background-color: white;
        text-align: center;
        padding: 5px 10px;
        border: 1px solid grey;
        border-radius: 6px;

        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 10;
        left: 110%;
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover .tooltiptext {
        visibility: visible;
    }

    :deep(.toggle-am-pm) {
        display: none;
    }

    .access-label {
        color: #669E53;
        background-color: #E6F3E2;
        font-size: 12px;
        border: solid 1px #669E53;
        border-radius: 4px;
        padding: 3px 5px;
        margin-right: 5px;
    }
</style>