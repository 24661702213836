export default {
    namespaced: true,
    state: {
        users: [],
        searchQuery: ''
    },
    mutations: {
        set_users(state, payload) {
            state.users = [...payload];
        },
        set_search_query(state, query) {
            state.searchQuery = query;
        }
    },
    getters: {
        get_users: state => state.users,
        get_search_query: state => state.searchQuery
    },
    actions: {
        set_users({ commit }, payload) {
            commit('set_users', payload);
        },
        set_search_query({ commit }, query) {
            commit('set_search_query', query);
        }
    }
};
