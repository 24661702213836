<template>
    <v-menu
        :close-on-content-click="false"
        open-on-hover
        :offset-y="true"
        top
    >
        <template v-slot:activator="{ on, attrs }">
            <v-avatar 
                v-bind="attrs"
                v-on="on" 
                color="blue" 
                size="36"
            >
                <span class="white--text" style="font-size: 1rem; font-weight: 500;">
                    {{ item.owner?.name?.[0] ?? "N" }}
                </span>
            </v-avatar>
        </template>

        <v-card width="280" elevation="4" class="pa-3">
            <v-row>
                <v-col
                    cols="4"
                    class="d-flex align-center justify-center"
                >
                    <v-avatar color="blue" tile size="95">
                        <span class="white--text" style="font-size: 1.2rem; font-weight: 500;">
                            {{ item.owner?.name?.[0] ?? "N" }}
                        </span>
                    </v-avatar>
                </v-col>
                <v-col
                    cols="8"
                >
                    <div class="font-weight-bold text-body-1">{{ item.owner?.name ?? "No Name" }}</div>
                    <div class="text-caption grey--text">{{ item.owner?.email ?? "No Email" }}</div>
                    <div class="text-caption grey--text">{{ new Date().toLocaleTimeString() }} local time</div>
                </v-col>
            </v-row>
            
            <v-card-actions class="mt-2">
                <v-btn block outlined color="primary" class="text-none">
                    {{ item.owner?.id == users.id ? "Edit Profile" : "View Profile" }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<script>
    export default {
        props: {
            item: {
                type: Object,
                required: true
            },
            users: {
                type: Object,
                required: true
            }
        }
    }
</script>