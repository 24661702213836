<template>
    <v-list flat>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>
                    <small 
                        style="font-weight: 700; cursor: pointer; text-decoration: none;"
                        @click.stop="unmountPersonalGoal"
                        @mouseover="hover = true"
                        @mouseleave="hover = false"
                        :style="{ textDecoration: hover ? 'underline' : 'none' }"
                    >
                        {{ getViewPersonalGoal.workspace_name ?? "no workspace name" }} goals >
                    </small>
                </v-list-item-title>
                <v-list-item-subtitle>
                    <v-icon style="background-color: gainsboro !important;">mdi-target</v-icon>
                    <label class="ml-2" style="font-weight: 700; font-size: 1rem; position: relative; top: 2px;">{{ getViewPersonalGoal.goal_title ?? "no title" }}</label>
                    <v-btn icon>
                        <v-icon small>mdi-thumb-up-outline</v-icon>
                    </v-btn>
                    <v-btn icon>
                        <v-icon small>mdi-share-outline</v-icon>
                    </v-btn>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text v-bind="attrs" v-on="on" class="text-none">
                                Set status
                            </v-btn>
                        </template>
                        <v-card elevation="1" width="200px">
                            <v-card-title @click.stop="view_status_logs_model = true" style="cursor: pointer;" class="text-left" v-if="Object.keys(getViewPersonalGoal).length > 0">
                                <small class="font-weight-bold text-caption">View latest update</small>
                                <small class="font-weight-black text-caption">
                                    Status update 
                                    <v-icon color="black">mdi-circle-small</v-icon>
                                    {{ $date(getViewPersonalGoal?.status_logs[0]?.created_at).format('MMM D') }}
                                </small>
                            </v-card-title>
                            <v-card-subtitle @click.stop="view_status_logs_model = true" style="cursor: pointer;" v-if="Object.keys(getViewPersonalGoal).length > 0">
                                <small>
                                    {{ getViewPersonalGoal?.status_logs[0]?.user?.name }} at 
                                    {{ $date(getViewPersonalGoal?.status_logs[0]?.created_at).format('h:mm A') }}
                                </small>
                            </v-card-subtitle>
                            <v-divider />
                            <v-list dense>
                            <v-list-item>
                                <v-list-item-content>
                                    <small class="font-weight-medium">Open</small>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-for="(open, openIndex) in getGoalStatusesOpen" :key="open.id || openIndex" @click.stop="addStatus(open)">
                                <v-chip small label class="px-0 pr-2 pl-2" style="cursor: pointer;">
                                <v-icon :style="{ color: open.color }">mdi-circle-medium</v-icon>
                                    {{ open.name }}
                                </v-chip>
                            </v-list-item>
                            <v-divider class="mt-2" />
                            <v-list-item>
                                <v-list-item-content>
                                    <small class="font-weight-medium">Close</small>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-for="(close, closeIndex) in getGoalStatusesClose" :key="close.id || closeIndex" @click.stop="addStatus(close)">
                                <v-chip small label class="px-0 pr-2 pl-2" style="cursor: pointer;">
                                <v-icon :style="{ color: close.color }">mdi-circle-medium</v-icon>
                                    {{ close.name }}
                                </v-chip>
                            </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>
<script>

    /**service */
    import goalService from '@/service/goal.service';

    export default{
        props:{
            getViewPersonalGoal: {
                type: Object,
                required: true,
            },
            getGoalStatusesOpen: {
                type: Array,
                required: true
            },
            getGoalStatusesClose: {
                type: Array,
                required: true
            }, 
        },
        data: () => ({
            hover: false
        }),
        methods:{
            unmountPersonalGoal(){
                goalService.unmountPersonalGoal()
            },
            addStatus(item){
                try{
                    const tp = {
                        status_id:  item.id,
                        goal_id:    this.getViewPersonalGoal.id
                    }
                    goalService.addStatus(tp)
                }catch(error){
                    console.log("error on updating status - component level", error)
                }
            }
        }
    }
</script>