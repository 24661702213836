/**api */
import apiClient from "@/api/apiClient"

/**store */
import store from "@/plugins/vuex"

export default {
    async updateUserName(payload){
        try{
            const user = store.getters["auth/get_user"]
            /**api call */
            await apiClient.post(`/api/users/update_user_for_all/${user.id}`, payload)
            await this.getUser(user?.id)
        }catch(error){
            console.log('error on user update - service layer', error)
        }
    },

    /**
     * get user via id (single)
     * @params
     * id?: int
     * 
     * @return
     * mixed
     */
    async getUser(id){
        try{
            const response = await apiClient.get(`/api/users/${id}`)
            const {data} = response
            store.commit('auth/set_user', data)
        }catch(error){
            console.log('error on fetching user information - service layer', error);
        }
    },

    /**
     * add note to a user
     * @params
     * object (data)
     * - user_id?: int
     * - notes?: string
     * - note_by?: int
     * 
     * @return
     * mixed
     */
    async addNoteUser(data){
        try{
            await apiClient.post('/api/notes', data)
            const get_users = this.getUsers()
            return get_users
        }catch(error){
            console.log('error on adding note on a user - service layer', error)
        }
    },

    /**
     * get users (multiple)
     * @params
     * none
     * 
     * @return
     * mixed
     */
    async getUsers(){
        try{
            const response = await apiClient.get('/api/users/get_users')
            const {data} = response.data
            store.commit('users/set_users', data)
            return data
        }catch(error){
            console.log('error fetching users - service layer', error)
        }
    },

    /**
     * update user password
     * @params
     * record_id?: int,
     * item?: object
     * - password?: string
     */
    async changePassword(
        record_id,
        item
    ){
        try{
            return await apiClient.patch(`/api/users/password/${record_id}`, item)
        }catch(error){
            console.log('error on updating password service - layer', error)
        }
    }
}