/**api */
import apiClient from "@/api/apiClient";

/**store */
import store from "@/plugins/vuex"

export default {

    /**
     * add folder
     * @params
     * - int?: company_id,
     * - string?: folder_name
     * 
     * @returns
     * mixed
     */
    async addFolder(data){
        try{
            await apiClient.post('/api/documents/folder', data)
            this.getFolders()
        }catch(error){
            console.log('error on adding folder - service layer', error)
        }
    },

    /**
     * @params
     * id?: company_id
     * 
     * @returns
     * mixed
     */
    async getFolders(){
        try{
            const company_id = store.getters['companies/get_selected_company']
            const response = await apiClient.get(`/api/documents/folder/getFolder/${company_id.id}`)
            const { data } = response
            store.commit('document/set_folder', data)
            store.commit('document/set_loading', false)
        }catch(error){
            console.log('error on fetching folders - service layer', error)
        }
    },

    /**
     * add a file
     * @params
     * data: object
     * - document_id?: int
     * - file_location?: string
     * - name?: string
     * 
     * @return
     * mixed
     */
    async addFile(item){
        try{
            await apiClient.post('/api/documents/files', item)
            this.getFolders()
        }catch(error){
            console.log('adding file error - service layer', error)
        }
    },

    /**
     * set edit page
     * @params
     * item?: object
     * 
     * return
     * void
     */
    setEditPage(item){
        try{
            store.commit('document/set_edit_page', item)
        }catch(error){
            console.log("there's an error assigning to be edit page", error)
        }
    },

    /**
     * update file
     * @params
     * -item?: object,
     * -id?: number
     * 
     * @return
     * mixed
     */
    async updateFile(
        item,
        id
    ){
        try{
            await apiClient.patch(`/api/documents/files/${id}`, item)
            return true
        }catch(error){
            console.log('error on update page - service layer', error)
        }
    },

    /**
     * delete a page
     * @params
     * -int?: number
     * 
     * @return
     * void
     */
    async deletePage(id){
        try{
            await apiClient.delete(`/api/documents/files/${id}`)
        }catch(error){
            console.log("error on delete page - service layer", error)
        }
    }
}