import apiClient from "@/api/apiClient";

/**store */
import store from "@/plugins/vuex"

export default{
    /**
     * get leaves of logged in user
     * @params
     * none
     */
    async getLeaves(){
        try{
            const response = await apiClient.get('/api/leave')
            const { data } = response
            store.commit("leave/SET_LEAVES", data)
        }catch(error){
            console.log("error on fetching leaves - service layer", error)
        }
    },

    /**
     * get leave status
     * @param
     * none
     */
    async getLeaveStatus(){
        try{
            const response = await apiClient.get('/api/leave/status')
            const { data } = response
            store.commit('leave/SET_LEAVE_STATUS', data)
        }catch(error){
            console.log('error on fetching leave status - service layer', error)
        }
    },

    /**
     * get leave types
     * @params
     * none
     */
    async getLeaveTypes() {
        try{
            const response = await apiClient.get('/api/leave/type')
            const { data } = response
            store.commit('leave/SET_LEAVE_TYPES', data)
        }catch(error){
            console.log('error on fetching leave types - service layer', error)
        }
    },

    /**
     * add leave credits to a user
     * @params
     * item?: object
     */
    async addLeaveCredits(item){
        try{
            return await apiClient.post('/api/leave/credits', item)
        }catch(error){
            console.log('error on adding leave credits to a user - service layer', error)
        }
    },

    /**
     * get users leave credits
     * @params
     * none
     */
    async getLeaveCredits(){
        try{
            const response = await apiClient.get('/api/leave/credits')
            const { data } = response
            store.commit('leave/SET_LEAVE_CREDITS', data)
        }catch(error){
            console.log("error occured on fetching leave credits - service layer", error)
        }
    },

    /**
     * request a leave
     * @params
     * item?: object
     */
    async requestALeave(item){
        try{
            return await apiClient.post('/api/leave/request', item)
        }catch(error){
            console.log('error on requesting a leave - service layer', error)
        }
    },

    /**
     * get to approve leave request
     * @params
     * none
     */
    async getToApproveLeaveRequest(){
        try{
            const response = await apiClient.get('/api/leave/approval')
            const { data } = response
            store.commit('leave/SET_TO_APPROVE_LEAVE_REQUEST', data)
        }catch(error){
            console.log("error on fetching to approve leave request - service layer", error)
        }
    },

    /**
     * update leave request
     * @params
     * record_id?:          int
     * item?:               object,
     */
    async updateRequest(
        record_id,
        item
    ) {
        try{
            return await apiClient.patch(`/api/leave/approval/${record_id}`, item)
        }catch(error){
            console.log("error on updating record - service layer", error)
        }
    }
}