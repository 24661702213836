<template>
    <v-container fluid class="d-flex justify-center">
        <v-card
            :style="{ width: cardWidth }"
            elevation="0"
            class="mt-10"
        >
            <span style="font-size: 1.5rem; font-weight: 600;">Overview</span>
            <v-list three-line>
                <v-list-item>
                    <v-list-item-avatar size="96">
                        <v-avatar color="black" size="96">
                            <v-icon dark size="60">
                                mdi-account-outline
                            </v-icon>
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-badge
                                :content="user?.role?.name ?? 'no role'"
                            >
                                <span style="font-weight: 500; font-size: 1.30rem; color: black;">{{ user.name ?? "no name" }}</span>
                            </v-badge>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <span>{{ user?.role?.name ?? "no role" }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mt-2">
                            <v-chip
                                small
                                outlined
                                class="pa-0 pl-2 pr-2"
                            >
                                <v-icon small>mdi-calendar</v-icon>
                                <span style="font-size: 0.8rem; font-weight: 600;">Joined {{ $date(user.created_at).format('MMM YYYY') }}</span>
                            </v-chip>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider/>
            <v-card-title>
                <span
                    style="font-size: 1.2rem; font-weight: 500;"
                >Quick Overview</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col
                        v-for="(cardInfo, cardInfoIndex) in overview"
                        :key="cardInfoIndex"
                        md="4" sm="6" xs="12"
                    >
                        <v-card elevation="0">
                            <v-card-title>
                                <v-avatar color="black" size="40">
                                    <v-icon dark size="20" color="white">
                                        {{ cardInfo.icon }}
                                    </v-icon>
                                </v-avatar>
                                <v-spacer/>
                                <v-chip
                                    small
                                    outlined
                                >
                                    <span style="font-size: 0.8rem; font-weight: 500;">{{ cardInfo.chip }}</span>
                                </v-chip>
                            </v-card-title>
                            <v-card-subtitle style="position: relative; top: 4px;">
                                <span style="font-weight: 600; font-size: 0.9rem;">{{ cardInfo.title }}</span>
                            </v-card-subtitle>
                            <v-card-title style="position: relative; bottom: 15px;">
                                <span style="font-weight: 700; font-size: 1.60rem;">{{ cardInfo.number }}</span>
                            </v-card-title>
                            <v-card-subtitle style="position: relative; bottom: 10px;">
                                <span style="font-weight: 600; font-size: 0.8rem;">{{ cardInfo.last_review }}</span>
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

/**vuex */
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      cardWidth: "50%",
      overview: [
        {
            "icon":         "mdi-chart-bar",
            "chip":         "On Track",
            "title":        "Current Performance",
            "number":       "4.2 / 5.0",
            "last_review":  "8% increase from last review"
        },
        {
            "icon":         "mdi-seal",
            "chip":         "Next Level",
            "title":        "Career Progress",
            "number":       "67%",
            "last_review":  "Marketing Team Lead"
        },
        {
            "icon":         "mdi-seal",
            "chip":         "Plan Ahead",
            "title":        "Available Time Off",
            "number":       "12 Days",
            "last_review":  "Leave: 12"
        }
      ]
    };
  },
  mounted() {
    this.updateCardWidth();
    window.addEventListener("resize", this.updateCardWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateCardWidth);
  },
  computed:{
    ...mapGetters({
        user:               'auth/get_user',
    })
  },
  methods: {
    updateCardWidth() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 600) {
        this.cardWidth = "90%";
      } else if (screenWidth < 960) {
        this.cardWidth = "70%";
      } else if (screenWidth < 1264) {
        this.cardWidth = "60%";
      } else {
        this.cardWidth = "50%";
      }
    },
  },
};
</script>

