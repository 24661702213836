<template>
    <div>
        <v-app-bar clipped-left app color="#343642" dark>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>
                <small>
                    Welcome to DG Ventures Project Management Software —
                    {{ $date().format('M/DD/YYYY') }} — Week {{ $date().week() }}
                </small>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div v-if="get_user.id" style="display:flex;align-items:center;">
                <!-- <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" @click="openNotifications()" icon>
                            <v-badge
                                :content="notifications.filter(x => x.is_read == 0).length"
                                :value="notifications.filter(x => x.is_read == 0).length > 0"
                                color="red"
                                bottom
                            >
                                <v-icon class="mr-3">mdi-bell</v-icon>
                            </v-badge>
                        </v-btn>
                    </template>
                    <v-card
                        style="width:800px;border: solid 1px grey;padding-bottom:20px;overflow-y:scroll;max-height:800px;">
                        <v-card-title>
                            Recent Notifications
                        </v-card-title>
                        <v-card-subtitle v-if="notifications.length == 0">
                            No notifications to display
                        </v-card-subtitle>
                        <div v-for="(notification, index) in notifications" :key="index" style="padding-bottom:0px;">
                            <v-card-text v-if="notification.task != null">
                                <v-btn v-if="notification.task != null"
                                    @click="openTaskDetailsDialog(notification.task)"
                                    style="font-size:16px;color:black;text-transform:initial;text-align:left;letter-spacing:initial;justify-content:initial;height:auto;max-width:180px;text-align:left;"
                                    block>
                                    <div>
                                        <div>
                                            <v-icon class="mt-1"
                                                :color="getColor(notification.task.priority_id)">mdi-circle-medium</v-icon>
                                            <label v-html="notification.content"
                                                style="white-space: normal; overflow-wrap: break-word;"></label>
                                        </div>
                                        <div style="color:grey;font-size:14px;" class="mt-1">
                                            <v-icon class="mt-1" style="visibility:hidden;">mdi-circle-medium</v-icon>
                                            {{ $date(notification.created_at).fromNow() }}
                                            on {{ $date(notification.created_at).format('MMM DD, YYYY hh:mm A') }}
                                        </div>
                                    </div>
                                </v-btn>
                            </v-card-text>
                        </div>
                    </v-card>
                </v-menu> -->
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-badge
                            color="blue"
                            :content="approveLeaveRequest.length"
                            dot
                        >
                            <v-icon v-bind="attrs" v-on="on">
                                mdi-account-settings
                            </v-icon>
                        </v-badge>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-avatar
                                    color="primary"
                                    size="56"
                                >
                                    <span class="white--text text-h5">{{ get_user?.name[0] ?? "NN" }}</span>
                                </v-avatar>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ get_user?.name || 'Guest' }}</v-list-item-title>
                                <v-list-item-subtitle>{{ get_user?.role?.name || 'No Role' }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item @click.stop="viewProfile" style="cursor:pointer;">
                            <v-list-item-title><span style="font-size: .9rem; font-weight: 600;">Profile</span></v-list-item-title>
                        </v-list-item>
                        <v-list-item @click.stop="leaveRequest" style="cursor:pointer;">
                            <v-list-item-title><span style="font-size: .9rem; font-weight: 600;">Leave Request</span></v-list-item-title>
                        </v-list-item>
                        <v-list-item 
                            @click.stop="approveLeaveRequest.length > 0 ? approveRequest() : null" 
                            :style="{ 
                                cursor: approveLeaveRequest.length > 0 ? 'pointer' : 'default',  
                                color: approveLeaveRequest.length > 0 ? '' : 'lightgrey' 
                            }"
                            v-if="[1, 2].includes(get_user.role_id)"
                        >
                            <v-badge
                                color="error"
                                :content="approveLeaveRequest.length"
                                v-if="approveLeaveRequest.length > 0"
                            >
                                <v-list-item-title><span style="font-size: .9rem; font-weight: 600;">Approve Request</span></v-list-item-title>
                            </v-badge>
                            <v-list-item-title v-else><span style="font-size: .9rem; font-weight: 600;">Approve Request</span></v-list-item-title>
                        </v-list-item>
                        <v-list-item @click.stop="changePassword" style="cursor: pointer;">
                            <v-list-item-title><span style="font-size: .9rem; font-weight: 600;">Change Password</span></v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="logout()" style="cursor:pointer;">
                            <v-list-item-title><span style="font-size: .9rem; font-weight: 600;">Logout</span></v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-app-bar>
        <v-navigation-drawer v-if="get_user.id" 
            :value="drawer" 
            app
            v-click-outside="{ handler: closeDrawer, closeConditional: onCloseConditional }" 
            clipped
            style="background-color: #E9E9E9;"
        >
            <v-list>
                <v-list-item>
                    <v-menu v-model="select_company_menu" :close-on-content-click="false" :nudge-width="200" offset-x>
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item class="mx-4 mt-3 mb-3 company-menu" link two-line v-bind="attrs" v-on="on"
                                v-if="get_selected_company.id">
                                <v-list-item-content>
                                    <v-list-item-title style="font-weight:500;">
                                        {{ get_selected_company.name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-icon small color="grey lighten-1">mdi-unfold-more-horizontal</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item class="mx-4 mt-3 mb-3 company-menu" link two-line v-bind="attrs" v-on="on"
                                v-else>
                                <v-list-item-content>
                                    <v-list-item-title>Select Company</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-icon small color="grey lighten-1">mdi-unfold-more-horizontal</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                        <v-card max-width="450" style="border:1px solid #CCC;">
                            <v-card-title>
                                <v-text-field placeholder="Search Company" class="mx-3" hide-details
                                    v-model="search"></v-text-field>
                                <v-btn icon @click="openCreateCompanyDialog()"
                                    v-if="get_user.role_id < 3"><v-icon>mdi-plus</v-icon></v-btn>
                            </v-card-title>
                            <v-divider class="mx-6"></v-divider>
                            <v-card-text>
                                <v-data-table :headers="headers" :items="[{ name: 'All' }, ...get_companies]"
                                    :search="search" hide-default-header single-select :sort-by.sync="sort_by"
                                    :sort-desc.sync="sort_desc" style="cursor:pointer;">
                                    <template v-slot:item="{ item }">
                                        <tr @click="selectCompany(item)">
                                            <td>
                                                <label v-if="item.name == 'All'">
                                                    <b>{{ item.name }}</b>
                                                </label>
                                                <label
                                                    v-else
                                                    :style="
                                                        item.deleted_at !== null ? 'text-decoration: line-through; color: gray;' : ''
                                                    "
                                                >
                                                    {{ item.name }}
                                                </label>
                                            </td>
                                            <td>
                                                <label v-if="item.name !== 'All'">
                                                    <!-- show delete icon if deleted_at is null -->
                                                    <v-icon
                                                        v-if="(item.deleted_at === null) || ([1, 2].includes(get_user.role_id))"
                                                        style="cursor: pointer;"
                                                        @click.stop="deleteCompany(item)"
                                                    >mdi-delete-forever-outline</v-icon>
                                                    <!-- else show restore -->
                                                    <v-icon
                                                        v-else
                                                        @click.stop="restoreCompany(item)"
                                                    >
                                                        mdi-restore
                                                    </v-icon>
                                                </label>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </v-list-item>
                <v-list-item :link="true" class="mx-2" to="/dashboard">
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title><small style="font-weight: 500; font-size: 0.8rem;">Home</small></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :link="true" class="mx-2" to="/messages">
                    <v-list-item-icon>
                        <v-icon>mdi-bell</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="d-flex align-center">
                            <small style="font-weight: 500; font-size: 0.8rem;">Inbox</small>
                            <v-badge
                                v-if="get_unread_tasks > 0"
                                :content="get_unread_tasks"
                                color="red"
                                class="ml-2"
                                style="position: relative; top: -2px"
                            >
                            </v-badge>
                        </v-list-item-title>
                    </v-list-item-content>
                    <span v-if="get_unread_count > 0" class="notification-bubble">
                        {{ get_unread_count }}
                    </span>
                </v-list-item>
                <v-list-item :link="true" class="mx-2" to="/goals">
                    <v-list-item-icon>
                        <v-icon style="color: black;">mdi-bullseye</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title><small style="font-weight: 500; font-size: 0.8rem;">Goals</small></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :link="true" class="mx-2" to="/timetrack">
                    <v-list-item-icon>
                        <v-icon>mdi-clock-time-two</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title><small style="font-weight: 500; font-size: 0.8rem;">Invoice Log</small></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-menu v-model="select_project_menu" open-on-hover :close-on-content-click="false" :nudge-width="200"
                    offset-x>
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item :link="(get_selected_company.name != null)" v-bind="attrs" v-on="on"
                            :class="$route.name == 'ViewProject' ? 'mx-2 project-active' : 'mx-2'"
                            :style="(get_selected_company.name == null) ? 'color:lightgrey;cursor:initial;' : ''">
                            <v-list-item-icon>
                                <v-icon :style="(get_selected_company.name == null) ? 'color:lightgrey;' : ''">
                                    mdi-view-dashboard
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <small style="font-weight: 500; font-size: 0.8rem;">Projects</small>
                                    <v-icon v-if="get_selected_company.name != null">mdi-menu-right</v-icon>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-card v-if="get_selected_company.name != null" max-width="400" style="border:1px solid #CCC;">
                        <v-card-title>
                            <v-text-field placeholder="Search Projects" class="mx-3" hide-details
                                v-model="search_project"></v-text-field>
                            <v-btn icon @click="openCreateProjectDialog()" v-if="get_user.role_id < 3">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider class="mx-6"></v-divider>
                        <v-card-text>
                            <v-data-table :headers="project_headers" :items="projects" :search="search_project"
                                hide-default-footer hide-default-header single-select style="cursor:pointer;">
                                <template v-slot:item="{ item }">
                                    <tr @click="selectProject(item)">
                                        <td>
                                            {{ item.name }}
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-menu>
                <v-list-item :link="(get_selected_company.name != null)" @click="openProjectsNavigation()" class="mx-2"
                    :to="(get_selected_company.name != null) ? '/meetings' : ''"
                    :style="(get_selected_company.name == null) ? 'color:lightgrey;cursor:initial;' : ''">
                    <v-list-item-icon>
                        <v-icon :style="(get_selected_company.name == null) ? 'color:lightgrey;' : ''">
                            mdi-account-network
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title><small style="font-weight: 500; font-size: 0.8rem;">Meetings</small></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-for="(item) in sidebars" :key="item.id"
                    :link="(!item.disabled && get_selected_company.name != null)" class="mx-2"
                    :to="(item.disabled == false && get_selected_company.name != null) ? item.route : ''"
                    :style="(item.disabled | get_selected_company.name == null) ? 'color:lightgrey;cursor:initial;' : ''">
                    <v-list-item-icon>
                        <v-icon :style="(item.disabled | get_selected_company.name == null) ? 'color:lightgrey;' : ''">
                            {{ item.icon }}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title><small style="font-weight: 500; font-size: 0.8rem;">{{ item.label }}</small></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <template v-slot:append>
                <v-list v-if="get_user.role_id < 3">
                    <v-divider class="mb-2"></v-divider>
                    <div class="mb-4">
                        <label class="mx-6" style="font-weight:900; font-size: 0.8rem; color:#7C7C7C;">
                            Agency Menu
                        </label>
                    </div>
                    <v-list-item link class="mx-2" to="/users">
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title><small style="font-weight: 500; font-size: 0.8rem;">Users</small></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="mx-2" to="/user-activity">
                        <v-list-item-icon>
                            <v-icon>mdi-gesture-tap</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title><small style="font-weight: 500; font-size: 0.8rem;">User Activity</small></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="mx-2" to="" style="color:lightgrey;cursor:initial;">
                        <v-list-item-icon>
                            <v-icon style="color:lightgrey;">mdi-cog</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title><small style="font-weight: 500; font-size: 0.8rem;">Agency Settings</small></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </v-navigation-drawer>
        <v-row justify="center">
            <v-dialog max-width="600" v-model="create_company_dialog" persistent>
                <v-card>
                    <v-card-title>
                        <small>Add Company</small>
                        <v-spacer></v-spacer>
                        <v-icon @click="closeCreateCompanyDialog()">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field dense label="Name *" outlined v-model="new_company.name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field dense label="Email" outlined v-model="new_company.email">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field dense label="Number" outlined v-model="new_company.number">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field dense label="Website" outlined v-model="new_company.website">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="createCompany()" dark>Create</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog max-width="600" v-model="create_project_dialog" persistent>
                <v-card>
                    <v-card-title>
                        <small>Add Project</small>
                        <v-spacer></v-spacer>
                        <v-icon @click="closeCreateProjectDialog()">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field dense label="Name *" outlined v-model="new_project.name">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="createProject()" dark>Create</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <!-- delete a company -->
        <v-row justify="center">
            <v-dialog max-width="450" v-model="is_delete_company" persistent>
                <v-card class="overflow-hidden pa-4" style="border-radius: 8px;">
                    <v-card-title class="text-center text-h6 font-weight-bold red--text">
                        You are about to delete this company
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="text-center py-4">
                        <v-row justify="center">
                            <v-col cols="12">
                                <span class="text-body-1 font-weight-medium">Company</span>
                            </v-col>
                            <v-col cols="12">
                                <span class="text-h5 font-weight-bold">{{ to_delete_company.name }}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="pa-4">
                        <v-spacer></v-spacer>
                        <v-btn outlined class="px-6 py-2" @click.stop="cancelDeleteCompany">Cancel</v-btn>
                        <v-btn color="red" dark elevation="2" class="px-6 py-2" @click.stop="deleteCompanyFn">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <!-- profile edit -->
        <v-row justify="center">
            <v-dialog max-width="450" v-model="profile_edit" persistent>
                <v-card class="overflow-hidden" style="border-radius: 8px;">
                    <v-card-title class="text-h6">
                        <small>Profile edit</small>
                        <v-spacer></v-spacer>
                        <v-icon @click="profile_edit = false">mdi-close</v-icon>
                    </v-card-title>
                    <hr/>
                    <v-card-text class="py-4">
                        <v-text-field
                            :placeholder="get_user.name"
                            label="Name"
                            filled
                            dense
                            prepend-inner-icon="mdi-account"
                            v-model="edit_profile.name"
                            @keyup.enter="updateUser"
                        />
                        <v-text-field
                            :placeholder="get_user.email"
                            label="Email address"
                            filled
                            dense
                            prepend-inner-icon="mdi-email"
                            v-model="edit_profile.email"
                            @keyup.enter="updateUser"
                        />
                    </v-card-text>
                    <v-card-actions class="pa-4">
                        <v-spacer></v-spacer>
                        <v-btn outlined class="px-6 py-2" @click.stop="profile_edit = false;">Cancel</v-btn>
                        <v-btn color="red" dark elevation="2" class="px-6 py-2" @click.stop="updateUser">
                            Update Profile
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <!-- change password -->
        <v-row>
            <v-dialog max-width="450" v-model="change_password_modal" persistent>
                <v-card
                    elevation="0"
                >
                    <v-card-title>
                        <span style="font-weight: 700; font-size: 1rem;">Change your password</span>
                        <v-spacer/>
                        <v-icon @click="change_password_modal = false" small>mdi-close</v-icon>
                    </v-card-title>
                    <v-divider/>
                    <v-card-text
                        class="mt-5"
                    >
                        <v-text-field
                            label="New password"
                            filled
                            dense
                            append-icon="mdi-eye"
                            v-model="password"
                            @keyup.enter="changePasswordFn"
                            @click:append="togglePassword"
                            :type="showPassword ? 'text' : 'password'"
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn outlined class="text-none" @click.stop="changePasswordFn">Update</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>


        <TaskNavigationComponent 
            :task_details_dialog="task_details_dialog"
            :can_close_task_details_dialog="can_close_task_details_dialog" 
            :users="get_users" 
            @updateTask="updateTask"
            @closeTaskDetailsDialog="closeTaskDetailsDialog" 
        />

        <slot></slot>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TaskNavigationComponent from '../components/modules/TaskNavigationComponent.vue'

/**service layer */
import userService from '@/service/user.service'
import dashboardService from '@/service/dashboard.service'
import leaveService from '@/service/leave.service'

export default {
    components: {
        TaskNavigationComponent
    },
    data: () => ({
        drawer: true,
        select_company_menu: false,
        select_project_menu: false,
        search: null,
        search_project: null,
        projects: [],
        sort_by: 'name',
        sort_desc: false,
        headers: [
            { text: 'Company Name', value: 'name', sortable: false },
            { text: '', sortable: false }
        ],
        project_headers: [
            { text: 'Project Name', value: 'name', sortable: false }
        ],
        create_company_dialog: false,
        new_company: {
            name: null,
            email: null,
            number: null,
            website: null,
        },
        new_project: {
            name: null,
        },
        sidebars: [
            { id: 4, disabled: true, icon: "mdi-chart-bar", label: "Stats", route: "/stats" },
            { id: 6, disabled: true, icon: "mdi-email", label: "Email", route: "/email" },
            { id: 7, disabled: false, icon: "mdi-file-document", label: "Documents / SOPs", route: "/documents" },
            { id: 8, disabled: true, icon: "mdi-laptop", label: "Company Info", route: "/company" },
            { id: 9, disabled: true, icon: "mdi-list-box", label: "KPIs", route: "/kpi" },
        ],
        projects_navigation: false,
        create_project_dialog: false,
        is_project_page: false,
        notifications: [],
        task_details_dialog: false,
        selected_task: null,
        can_close_task_details_dialog: false,
        original_selected_task: {},
        // delete copany
        is_delete_company: false,
        to_delete_company: {},
        profile_edit: false,
        edit_profile: {
            name: "",
            email: "",
            role_id: 0
        },
        change_password_modal: false,
        showPassword: false,
        password: null
    }),
    async mounted() {
        try {
        await Promise.all([
            leaveService.getToApproveLeaveRequest(),
            this.getCompanies(),
            this.getProjects(),
            this.getUnreadMessages(),
            this.getNotifications(),
        ]);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        await this.$echo.private('notification.' + this.get_user.id)
            .listen('NotificationSent', () => {
                this.getNotifications()
            })
    },
    computed: {
        ...mapGetters({
            get_user:               'auth/get_user',
            get_users:              'tasks/get_users',
            get_selected_company:   'companies/get_selected_company',
            get_companies:          'companies/get_companies',
            get_unread_count:       'messages/get_unread_count',
            get_selected_task:      'tasks/get_selected_task',
            get_unread_tasks:       'tasks/get_unread_tasks',
            approveLeaveRequest:    'leave/getToApproveLeaveRequest'
        }),
    },
    methods: {
        changePassword(){
            this.change_password_modal = true
        },
        togglePassword() {
            this.showPassword = !this.showPassword;
        },
        async changePasswordFn(){
            if(this.password == null){
                this.$toast.error("Please put something first")
                return
            }
            this.$toast.info("please wait...")
            try{
                const tp = {
                    password: this.password
                }
                await userService.changePassword(this.get_user.id, tp)
                this.$toast.success("changing password successful!")
                this.password = null
                this.showPassword = false
                this.change_password_modal = false
            }catch(error){
                console.log('error on updating password - component level', error)
            }
        },
        viewProfile(){
            if (this.$route.path !== "/profile") {
                this.$router.push("/profile");
            }
        },
        leaveRequest(){
            if (this.$route.path !== "/leaveRequest") {
                this.$router.push("/leaveRequest");
            }
        },
        approveRequest(){
            // console.log('went here')
            if(this.$route.path !== '/leaveApproval'){
                this.$router.push("/leaveApproval")
            }
        },
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async updateUser(){
            try{
                await userService.updateUserName(this.edit_profile)
                this.profile_edit = false
            }catch(error){
                console.log('update user profile - template level', error)
            }
        },
        deleteCompany(company){
            this.to_delete_company = company
            this.is_delete_company = true
        },
        cancelDeleteCompany(){
            this.to_delete_company = {}
            this.is_delete_company = false
        },
        async deleteCompanyFn(){
            // console.log('meow')
            try{
                const response = await this.$axios.delete(`api/companies/delete_company/${this.to_delete_company.id}`)
                const {data} = response
                if(data.response){
                    this.cancelDeleteCompany()
                    this.showSnackBar(data.message)
                    this.getCompanies()
                    this.selectCompany("All")
                }
            }catch(error){
                console.log("company delete", error)
            }
        },
        async restoreCompany(company){
            try{
                const response = await this.$axios.patch(`api/companies/restore/${company.id}`)
                const {data} = response
                if(data){
                    this.showSnackBar("restoring company, successful!")
                    this.getCompanies()
                    this.selectCompany("All")
                }
            }catch(error){
                console.log("company restore", error)
            }
        },
        async getCompanies() {
            if (this.get_user.role_id == 1) {
                this.$axios.get('api/companies/get_companies')
                    .then(({ data }) => {
                        if (data.response) {
                            this.$store.dispatch('companies/set_companies', data.data)
                        }
                    })
            }
            else {
                this.$axios.get('api/companies/get_companies_by_access')
                    .then(({ data }) => {
                        if (data.response) {
                            this.$store.dispatch('companies/set_companies', data.data)
                        }
                    })
            }
        },
        async getProjects() {
            let payload = {
                company_id: this.get_selected_company.id,
            }
            if (this.get_user.role_id == 1) {
                this.$axios.get('api/projects/get_projects', payload)
                    .then(({ data }) => {
                        if (data.response) {
                            this.projects = data.data
                        }
                    })
            }
            else {
                this.$axios.get('api/projects/get_projects_by_access', payload)
                    .then(({ data }) => {
                        if (data.response) {
                            this.projects = data.data
                        }
                    })
            }
        },
        async getUnreadMessages() {
            let payload = {
                receiver_id: this.get_user.id,
                is_read: false,
            }
            this.$axios.get('api/messages/get_messages', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('messages/set_unread_count', data.data.length)
                    }
                })
        },
        async getNotifications() {
            let payload = {
                receiver_id: this.get_user.id,
            }
            const data = await dashboardService.getNotifications(payload)
            this.notifications = data
            // this.$axios.get('api/notifications/get_notifications', payload)
            //     .then(({ data }) => {
            //         if (data.response) {
            //             this.notifications = data.data
            //         }
            //     })
        },
        async openNotifications() {
            await this.$axios.post(`api/notifications/mark_notifications_as_read/${this.get_user.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.notifications.forEach(notification => {
                            notification.is_read = 1
                        })
                    }
                })
        },
        openTaskDetailsDialog(item) {
            this.task_details_dialog = true
            this.$store.dispatch('tasks/set_selected_task', item)
            this.original_selected_task = { ...item }
            setTimeout(() => {
                this.can_close_task_details_dialog = true
            }, 100)
        },
        closeTaskDetailsDialog() {
            this.task_details_dialog = false
            let selected_task = {
                name: this.get_selected_task.name,
                company_id: this.get_selected_task.company_id,
                project_id: this.get_selected_task.project_id,
                priority_id: this.get_selected_task.priority_id,
                status_id: this.get_selected_task.status_id,
                due_date: this.get_selected_task.due_date,
                // allocated_time: this.get_selected_task.allocated_time,
            }
            let original_task = {
                name: this.original_selected_task.name,
                company_id: this.original_selected_task.company_id,
                project_id: this.original_selected_task.project_id,
                priority_id: this.original_selected_task.priority_id,
                status_id: this.original_selected_task.status_id,
                due_date: this.original_selected_task.due_date,
                // allocated_time: this.original_selected_task.allocated_time,
            }
            if (JSON.stringify(selected_task) != JSON.stringify(original_task)) {
                this.updateTask(this.get_selected_task)
            }
            this.$store.dispatch('tasks/set_selected_task', {})
            this.original_selected_task = {}
        },
        async updateTask(item) {
            let payload = {
                company_id: item.company_id,
                assignee_id: item.assignee_id,
                project_id: item.project_id,
                name: item.name,
                status_id: item.status_id,
                description: item.description,
                priority_id: item.priority_id,
                due_date: item.due_date,
                allocated_time: item.allocated_time,
            }
            await this.$axios.post(`api/tasks/update_task/${item.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        getColor(priority_id) {
            switch (priority_id) {
                case 1:
                    return 'red'
                case 2:
                    return 'orange'
                case 3:
                    return '#FFC605'
                case 4:
                    return 'blue'
                default:
                    return 'grey'
            }
        },
        selectCompany(item) {
            if (item.name == 'All') {
                this.$store.dispatch('companies/set_selected_company', {})
            }
            else {
                this.$store.dispatch('companies/set_selected_company', item)
            }
            this.select_company_menu = false
            if (this.$route.name == 'Dashboard') {
                this.$router.go(0)
            }
            else {
                this.$router.push({ name: 'Dashboard' }).then(() => { this.$router.go(0) })
            }
        },
        openCreateCompanyDialog() {
            this.create_company_dialog = true
        },
        closeCreateCompanyDialog() {
            this.create_company_dialog = false
        },
        async createCompany() {
            await this.$axios.post('api/companies/create_company', this.new_company)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateCompanyDialog()
                        this.selectCompany(data.data)
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openCreateProjectDialog() {
            this.create_project_dialog = true
        },
        closeCreateProjectDialog() {
            this.create_project_dialog = false
        },
        async createProject() {
            let payload = {
                company_id: this.get_selected_company.id,
                ...this.new_project,
            }
            await this.$axios.post('api/projects/create_project', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateProjectDialog()
                        this.selectProject(data.data)
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        selectProject(item) {
            this.$router.push({ name: 'ViewProject', params: { id: item.id } })
        },
        closeDrawer() {
            this.drawer = false
        },
        onCloseConditional() {
            return this.drawer && this.$vuetify.breakpoint.mdAndDown
        },
        openProjectsNavigation() {
            this.projects_navigation = true
        },
        closeProjectsNavigation() {
            this.projects_navigation = false
        },
        async logout() {
            await this.$axios.post('api/logout')
            this.$store.dispatch('auth/set_user', {})
            this.$store.dispatch('auth/set_token', '')
            this.$store.dispatch('companies/set_selected_company', [])
            this.$store.dispatch('tasks/set_tasks', [])
            this.$store.dispatch('tasks/set_assigned_tasks', [])
            localStorage.clear()
            this.$router.push({ name: 'Login' })
        },
    },
    watch:{
        get_user: {
            handler(newUser){
                this.edit_profile.name = newUser?.name || this.get_user?.name;
                this.edit_profile.email = newUser?.email || this.get_user?.email;
                this.edit_profile.role_id = newUser?.role_id || this.get_user?.role_id;
            },
            immediate: true
        }
    }
}
</script>

<style scoped>
.title{
    font-weight: 700;
    font-size: 0.9rem;
}
.company-menu {
    border: 1px solid #CCC;
    border-radius: 10px;
    cursor: pointer;
}

.company-menu-title {
    font-family: 'Manrope';
}

.company-menu-subtitle {
    font-size: 12px;
    font-family: 'Manrope';
}

.v-app-bar-title__content {
    width: 50% !important;
}

.main-app-bar {
    background-color: #FFF !important;
}

.main-app-bar-title {
    font-family: 'Manrope';
    font-size: 24px;
    font-weight: bolder !important;
}

.main-app-bar-subtitle {
    font-size: 10px;
}

.v-menu__content {
    box-shadow: none;
}

.v-data-table__wrapper {
    tr {
        cursor: pointer;
    }
}

::-webkit-scrollbar {
    width: 7px;
    background: white;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    height: 50px;
}

:deep(.project-active::before) {
    opacity: 0.12 !important;
}

.notification-bubble {
    position: absolute;
    top: 35%;
    right: 10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px 5px;
    font-size: 0.75rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    height: 20px;
}

:deep(.v-btn span) {
    display: contents !important;
    white-space: initial;
}
</style>