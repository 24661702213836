<template>
    <v-container fluid class="px-0">
        <v-list flat>
            <v-list-item three-line>
                <v-list-item-avatar>
                    <v-icon style="background-color: gainsboro !important; color: black;">mdi-clock-time-two</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        <strong style="font-size: 1rem;">Invoice Log</strong>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <v-tabs
                            v-model="tab"
                            ref="tabs"
                            show-arrows
                        >
                            <v-tab
                                v-for="(item, index) in tabs"
                                :key="item.id || index"
                                class="text-none mr-2"
                                style="letter-spacing: 0.50px;"
                            >
                                <small style="font-weight: 900; font-size: 0.8rem;">{{ item.tab }}</small>
                            </v-tab>
                        </v-tabs>
                        <v-divider/>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-tabs-items
            v-model="tab"
        >
            <v-tab-item>
                <!-- personal -->
                <TreeViewTable
                    :tasks="personalTasks"
                    :header="header_personal"
                    :getStatuses="getStatuses"
                />
            </v-tab-item>
            <v-tab-item>
                <!-- assigned tasks -->
                <CompanyTreeView
                    :tasks="assignedTasks"
                    :header="header_assigned"
                    :getStatuses="getStatuses"
                />
            </v-tab-item>
            <v-tab-item>
                <!-- <GoalTreeView
                    :tasks="groupedGoals"
                    :header="header_goal"
                /> -->
            </v-tab-item>
        </v-tabs-items>
        
    </v-container>
</template>

<script>
/**service layer */
import dashboardService from '@/service/dashboard.service';
import miscService from '@/service/misc.service';
import goalService from '@/service/goal.service';

/**vuex */
import { mapGetters } from 'vuex';

/**components */
import TreeViewTable from '../modules/TreeViewTable.vue';
import CompanyTreeView from './component/CompanyTreeView.vue';
// import GoalTreeView from './component/GoalTreeView.vue';

export default {
    name: 'TimeTrackComponent',
    components: {
        TreeViewTable,
        CompanyTreeView,
        // GoalTreeView
    },
    data: () => ({
        header_personal: [
            { text: "Creator", value: "creator", width: '5%' },
            { text: "Task", value: "name", width: '30%' },
            { text: "Company", value: "company", width: '10%' },
            { text: "Allocated time", value: "allocated_time", width: '10%'},
            { text: 'Actual time', value: "actual_time", width: '10%' }
        ],
        header_assigned: [
            { text: "Creator", value: "creator", width: '5%' },
            { text: "Task", value: "name", width: '30%' },
            { text: "Status", value: "status", width: '10%' },
            { text: "Allocated time", value: "allocated_time", width: '10%'},
            { text: 'Actual time', value: "actual_time", width: '10%' }
        ],
        header_goal: [
            { text: "Goal Title", value: "title", width: '30%' },
            { text: "Members", value: "members", width: '10%' }
        ],
        tab: 0,
        tabs: [
            { tab: "Personal Tasks"},
            { tab: "Assigned Tasks" },
            // { tab: "Goals" }
        ],
    }),
    async mounted() {
        document.title = "DG Tasks - Work Logs";
        await Promise.all([
            dashboardService.getMyTasks(),
            miscService.getStatus(),
            goalService.getPersonalGoals()
        ]);
        const tabsElement = this.$refs.tabs?.$el;
        if (tabsElement) {
            tabsElement.addEventListener("touchstart", this.preventSwipe, { passive: false });
            tabsElement.addEventListener("touchmove", this.preventSwipe, { passive: false });
        }
    },
    beforeDestroy() {
        const tabsElement = this.$refs.tabs?.$el;
        if (tabsElement) {
            tabsElement.removeEventListener("touchstart", this.preventSwipe);
            tabsElement.removeEventListener("touchmove", this.preventSwipe);
        }
    },
    computed: {
        ...mapGetters({
            personalTasks:  'timetrack/getPersonalTasks',
            assignedTasks:  'timetrack/getAssignedTasks',
            getStatuses:    'tasks/get_statuses',
            groupedGoals:   "goal/getGroupedGoals"
        }),
    },
    methods: {
        preventSwipe(event) {
            event.preventDefault();
        }
    }
};
</script>